import React from 'react'
import FeatherIcon from 'feather-icons-react';
import { calender, treatmentPng, hospitalPng, doctor, enquiryPng, packagePng, patientPng, appoinmentPng, specialityPng } from '../imagepath';
import useAsync from '../../../hooks/useAsync';
import DoctorService from '../../../services/DoctorService';
import HospitalService from '../../../services/HospitalService';
import TreatmentService from '../../../services/TreatmentService';
import SpecialityServices from '../../../services/SpecialityServices';
import { Link } from 'react-router-dom';
import PackageService from '../../../services/PackageService';
import EnquiryService from '../../../services/EnquiryService';
import AdminServices from '../../../services/AdminServices';
import CountServices from '../../../services/CountServices';
import BookingService from '../../../services/BookingService';

function DashTopCards() {
    const allDoctors = useAsync(CountServices.allDoctors)
    const allHospitals = useAsync(CountServices.allHospitals)
    const allTreatments = useAsync(CountServices.allTreatments)
    const allSpeciality = useAsync(CountServices.allSpecialities)
    const allPackages = useAsync(PackageService.allAssignedPackage)
    const allEnquiry = useAsync(EnquiryService.allEnquiries)
    const allPatients = useAsync(CountServices.allUsers)
    const allBookings = useAsync(BookingService.getCountOfAllBookings)

    console.log("booking count", allBookings.data?.count)

    return (
        <div className="row">
            <Link to="/doctor-list" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-1">
                                <img src={doctor} alt="User Image" />

                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Doctors</h5>
                                <div className="dash-counts">
                                    <p>{allDoctors?.data?.count}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>
            <Link to="/patient-list" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-blue">
                                <img src={patientPng} alt="User Image" />

                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Patients</h5>
                                <div className="dash-counts">
                                    <p>{allPatients?.data?.count}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>
            <Link to="/appointment-list" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-3">
                                <img src={appoinmentPng} alt="User Image" />
                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Appointment</h5>
                                <div className="dash-counts">
                                    <p>{allBookings.data?.count}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>
            <Link to="/hopitals" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-4">
                                <img src={hospitalPng} alt="User Image" />
                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Hospitals</h5>
                                <div className="dash-counts">
                                    <p>{allHospitals?.data?.count}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>

            <Link to="/enquiry" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-8">
                                <img src={enquiryPng} alt="User Image" />
                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Enquiry</h5>
                                <div className="dash-counts">
                                    <p>{allEnquiry?.data?.length}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>

            <Link to="/treatment" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-5">
                                <img src={treatmentPng} alt="User Image" />
                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Treatments</h5>
                                <div className="dash-counts">
                                    <p>{allTreatments?.data?.count}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>

            <Link to="/specialities" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-6">
                                <img src={specialityPng} alt="User Image" />
                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Speciality</h5>
                                <div className="dash-counts">
                                    <p>{allSpeciality?.data?.count}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>

            <Link to="/packages" className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="dash-widget-header">
                            <span className="dash-widget-icon bg-7">
                                <img src={packagePng} alt="User Image" />
                            </span>
                            <div className="dash-count">
                                <h5 className="dash-title">Packages</h5>
                                <div className="dash-counts">
                                    <p>{allPackages?.data?.length}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Link>

        </div>
    )
}

export default DashTopCards