import requests from "./httpServices"

const userDetailServices = {
    getAllDischargeSummary() {
        return requests.get('/user_login/discharge/')
    },
    deleteDischargSummary(id) {
        return requests.delete(`/user_login/discharge/${id}/`)
    },

    getAllVisa() {
        return requests.get("/user_login/visa/")
    },
    deleteVisa(id) {
        return requests.delete(`/user_login/visa/${id}/`)
    },

    getAllHotel() {
        return requests.get('/user_login/hotel/')
    },
    deleteHotel(id) {
        return requests.delete(`/user_login/hotel/${id}/`)
    },

    getAllFlightDetails() {
        return requests.get('/user_login/flight/')
    },
    deleteFlightDetails(id) {
        return requests.delete(`/user_login/flight/${id}/`)
    }

}

export default userDetailServices