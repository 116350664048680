import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { UserContext } from '../../../Context/AllContext'
import useAsync from '../../../hooks/useAsync'
import FaqService from '../../../services/FaqService'
import HospitalService from '../../../services/HospitalService'

function AddHospitalFaq({ editId, setFormPage }) {
    console.log("edit id", editId)
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)

    const { setIsUpdate } = useContext(UserContext)
    const allHospitals = useAsync(HospitalService.allHospitalList)
    const [values, setValues] = useState({
        faq_qst: '',
        faq_ans: '',
        hospital_name: '',
    })

    useEffect(() => {
        console.log("id after useeffect", editId)
        if (editId) {
            FaqService.getHospitalFaqById(editId).then((res) => {
                console.log("edit data 1", res)
                setValues({
                    faq_qst: res.faq_qst,
                    faq_ans: res.faq_ans,
                    hospital_name: res.hospital_name
                })

            })
        }
    }, [])


    const valuesChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    console.log("values :", values)

    const resetFileInput = () => {
        ref1.current.value = null;
        ref2.current.value = null;
        ref3.current.value = null;
    };

    const valuesSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('faq_qst', values.faq_qst)
        formData.append('faq_ans', values.faq_ans)
        formData.append('hospital_name', values.hospital_name)

        {
            editId ?
                FaqService.updateHospitalFaq(editId, formData).then((res) => {
                    alert("Successfully Updated")
                    setIsUpdate(true)
                    setFormPage("hospFaq")
                    setValues({
                        faq_qst: '',
                        faq_ans: '',
                        hospital_name: '',
                    })
                    resetFileInput()
                })
                :
                FaqService.addHospitalFaq(formData).then((res) => {
                    console.log(res)
                    alert("Added successfully")
                    setIsUpdate(true)
                    setValues({
                        faq_qst: '',
                        faq_ans: '',
                        hospital_name: '',
                    })
                    resetFileInput()
                })
        }
    }

    return (
        <div className='adm-add-faq-main'>
            <div className="adm-add-faq-body-div">
                <h4>{editId ? "Edit" : "Add"} Hospital Faq</h4>
                <form action="" onSubmit={valuesSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Question?</label>
                                <input type="text"
                                    className="form-control"
                                    name='faq_qst'
                                    ref={ref1}
                                    defaultValue={values.faq_qst}
                                    onChange={valuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Answer</label>
                                <input type="text"
                                    className="form-control"
                                    name='faq_ans'
                                    ref={ref2}
                                    defaultValue={values.faq_ans}
                                    onChange={valuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Hospital</label>
                                <select className='form-control'
                                    name='hospital_name'
                                    ref={ref3}
                                    defaultValue={values.hospital_name}
                                    onChange={valuesChange}>
                                    <option disabled={true} value="">
                                        --Choose Hospital--
                                    </option>
                                    {
                                        allHospitals.data?.map((item) => {
                                            return (
                                                <option key={item?.id} value={item?.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="adm-testi-btn-div">
                            <button type="submit" className="btn btn-success">{editId ? "Edit" : "Add"}</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddHospitalFaq