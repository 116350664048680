import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { useContext } from 'react'
import { UserContext } from '../../../../../Context/AllContext'



const EducationalListModal = ({ eduArray, setEduArray }) => {
    const { setIsUpdate } = useContext(UserContext)

    console.log("edu array", eduArray)

    const deleteHandler = (name) => {
        let del = eduArray.splice(name, 1)
        setEduArray(eduArray)
        setIsUpdate(true)
    }

    return (
        <div className="edu-list-modal-div">
            {
                eduArray?.map((item, index) => {
                    return (
                        <div className="modal-list-card-div" key={index}>
                            <div className="modal-list-card-header">
                                {item?.collage_name && <h5>{`${index + 1}`}. {item.collage_name}</h5>}
                                {item?.course && <h6>{item.course}</h6>}

                            </div>
                            <div className="edu-modal-details-div">
                                {/* {item.Course && <h6>{item.Course}</h6>} */}
                                {item?.start_data || item?.end_date && <h6>{item.start_data} - {item.end_date}</h6>}
                            </div>
                            <div className="modal-icon-header-div">
                                {/* <i style={{ color: 'green' }}><AiOutlineEdit /></i> */}
                                {/* {
                                    item?.collage_name ? item?.course ? item?.start_data ? item?.end_date ? */}
                                <i onClick={() => deleteHandler(index)} style={{ color: 'red' }}><AiFillDelete /></i>
                                {/* //         : ""
                                // } */}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default EducationalListModal