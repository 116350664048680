import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import useAsync from '../../../hooks/useAsync'
import HospitalService from '../../../services/HospitalService'
import { AiFillDelete } from 'react-icons/ai'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'

const PromoteHospital = ({ setModal }) => {
    const ref1 = useRef(null)
    const allPromotedHosp = useAsync(HospitalService.getPromotedHospital)
    const allHospitals = useAsync(HospitalService.allHospitalList)

    const [selectedHosp, setSelectedHosp] = useState([])
    const [promotedHospList, setPromotedHospList] = useState([])
    const [filteredHospList, setFilteredHospList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState('')
    const [promotedHospCurrent, setPromoteHospCurrent] = useState([])
    const [deleteStatus, setDeleteStatus] = useState(false)
    const { setIsUpdate } = useContext(UserContext)

    useEffect(() => {
        if (allPromotedHosp?.data) {
            setPromotedHospList(allPromotedHosp?.data)
        }
        if (allHospitals?.data) {
            setFilteredHospList(allHospitals?.data?.filter(({ id }) => !promotedHospList[0]?.hospitals?.includes(id)))
        }
        if (allPromotedHosp?.data?.length != 0) {
            setIsEdit(true)
            setEditId(promotedHospList[0]?.id)
            setSelectedHosp(promotedHospList[0]?.hospitals)
        } else {
            setIsEdit(false)
        }
        if (promotedHospList) {
            setPromoteHospCurrent(promotedHospList[0]?.hospital_list)
        }
    }, [allPromotedHosp?.data, allHospitals?.data])


    const resetValue = () => {
        ref1.current.clearValue()
    }

    const handleChange = event => {
        event?.map((item) => {
            if (selectedHosp) {
                setSelectedHosp([...selectedHosp, item?.value])
            } else {
                setSelectedHosp([item?.value])
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault()

        let formData = new FormData()
        selectedHosp.forEach(item => { formData.append('hospitals', item) })

        {
            isEdit ?
                HospitalService.updatePromoteHospital(editId, formData).then((res) => {
                    console.log(res)
                    resetValue()
                    setModal(false)
                })
                :
                HospitalService.addPromotedHospital(formData).then((res) => {
                    console.log(res)
                    resetValue()
                    setModal(false)
                })
        }
    }

    const deleteHandler = (id) => {
        setDeleteStatus(true)

        // console.log("current id", id)
        setSelectedHosp(selectedHosp?.filter((current) => current != id))
        setPromoteHospCurrent(promotedHospCurrent?.filter((current1) => current1?.id != id))
    }

    console.log("selected hosp", selectedHosp)

    return (
        <div className='promote-main-div'>
            <div className="col-md-12">
                <div className="form-group">
                    <label className='form-title'>
                        Select Hospitals
                    </label>
                    <Select
                        options={filteredHospList?.map((item) => ({
                            label: item.name,
                            value: item.id
                        }))}
                        hideSelectedOptions
                        isMulti={true}
                        ref={ref1}
                        onChange={(event) => handleChange(event)}
                    />
                </div>
            </div>

            <div className="promoted-add-btn-div">
                <button type='submit'
                    className='btn btn-primary promote-btn btn-sm'
                    onClick={handleSubmit}>
                    Add
                </button>
            </div>

            <div className="col-md-12">
                <div className="promote-body-div">
                    <h3>Promoted Hospital List</h3>
                    <div className="promoted-items-list">
                        {
                            promotedHospCurrent?.map((item) => {
                                return (

                                    <div className="promoted-item-card mb-3" key={item?.id}>
                                        <h6 className="promoted-item-name">
                                            {item?.name}
                                        </h6>
                                        <i data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete"
                                            onClick={() => deleteHandler(item?.id)}>
                                            <AiFillDelete />
                                        </i>
                                    </div>
                                )
                            })
                        }

                    </div>
                    {
                        deleteStatus &&

                        <div className="delete-promot-div">
                            <button
                                onClick={handleSubmit}
                                className="btn btn-primary promote-btn btn-sm">Save changes</button>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default PromoteHospital