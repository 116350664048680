import React from 'react'
import FeatherIcon from 'feather-icons-react';
import { Modal } from "react-bootstrap";
import { useState } from 'react';
import { useRef } from 'react';
import GalleryService from '../../../services/GalleryService';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';


const AddImgModal = ({ imgModal, setImgModal }) => {
    const [images, setImages] = useState('')
    const [blob, setBlob] = useState(null)
    const imgRef = useRef(null)
    const { setIsUpdate } = useContext(UserContext)

    console.log("image", images)

    const handleChange = (e) => {
        setImages(e.target.files[0])
        setBlob(URL.createObjectURL(e.target.files[0]))

    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append('images', images)

        if (images) {
            GalleryService.addGallery(formData).then((res) => {
                setImgModal(false)
                imgRef.current.value = null
                setIsUpdate(true)
            })
        } else {
            alert("Please add any Image")
        }
    }

    return (
        <Modal show={imgModal} onHide={() =>
            setImgModal(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>Add Images</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="modal-image-main-cont">
                    <div className="modal-content doctor-profile">
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}
                            >
                                <div className="add-wrap">

                                    <div className="change-photo-btn">
                                        <div><i className=''><FeatherIcon icon="upload" /></i>
                                            <p>Upload File</p></div>
                                        <input type="file"
                                            className="upload"
                                            style={{ height: '100%', width: '100%' }}
                                            name='images'
                                            accept="image/png, image/jpeg"
                                            ref={imgRef}
                                            onChange={handleChange}
                                        />
                                        {
                                            (blob || images) && <img src={blob || images} width={50} height={50} className='ml-2' />
                                        }
                                    </div>

                                    <div className="submit-section">
                                        <button type="submit" className="btn btn-primary btn-save">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default AddImgModal