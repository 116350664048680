import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import EnquiryViewModal from '../Enquiry/EnquiryViewModal';
import { useState } from 'react';
import DeleteModal from '../Modal/DeleteModal';

const UserEnquiryModal = ({ modal, setModal, data }) => {
    const [viewId, setViewId] = useState()
    const [viewModal, setViewModal] = useState(false)
    console.log("enquiry data", data)

    const viewHandler = (id) => {
        setViewId(id)
        setViewModal(true)
    }

    const columns = [
        {
            name: 'Id',
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },

        {
            name: 'Email',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar"><Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#patientlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row.image_list[0]} alt="User Image" /></Link></h2><Link to="#"
                data-bs-target="#Patientlist"
                data-bs-toggle="modal"
            ><span className="user-name">{row.vchr_email}</span><br /><span className="d-block text-muted">{row.age}</span>
            </Link></Media.Body></Media>,
            width: "300px",

        },
        {
            name: 'Phone Number',
            selector: row => row.Date,
            cell: row => <Media ><Media.Body className="d-flex"><div>
                {/* <span className="user-name">{row.Date}</span>
                <br /> */}
                <span className="d-block">{row.int_mobile}</span></div></Media.Body></Media>,
            width: "300px",
        },

        {
            name: 'Account status',
            selector: row => row.status,
            sortable: true,
            cell: row => <div className="actions">

                <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => viewHandler(row.id)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link>

                {/* <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id, row.vchr_email)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link> */}
            </div>,
            width: "300px",
        },

    ];

    const tableData = {
        columns,
        data,
    };

    return (
        <>
            <Modal show={modal} onHide={() =>
                setModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Enquiries </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <DataTableExtensions
                            {...tableData}
                        >
                            <DataTable className="datatable table table-borderless hover-table"
                                id="data-table"

                                noHeader
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    </div>
                </Modal.Body>
            </Modal>


            {/* View modal */}
            <Modal show={viewModal} onHide={() =>
                setViewModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Enquiry </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <EnquiryViewModal enqId={viewId} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserEnquiryModal