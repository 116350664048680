import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import config from 'config';
import AppUniversal from './admin/app-universal.jsx';
import { UserContext } from './Context/AllContext.jsx';
import Cookies from 'js-cookie'

export const Appcontext = createContext()

const AppRouter = (props) => {

    const [isAuth, setIsAuth] = useState(false)
    const [isUpdate, setIsUpdate] = useState()
    return (
        <Router>

            <Appcontext.Provider value={{ isAuth, setIsAuth }}>
                <UserContext.Provider value={{ isUpdate, setIsUpdate }}>
                    <Route render={(props) => <AppUniversal {...props} />} />
                    {/* <Route path="/admin/login" exact component={AppUniversal} /> */}

                    {/* <Redirect to="/admin/login" /> */}
                </UserContext.Provider>
            </Appcontext.Provider>
        </Router>
    );

}


export default AppRouter;