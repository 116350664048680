import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SideBarNav from '../sidebar'
import TreatmentModal from './TreatmentModal'
import TreatmentTable from './TreatmentTable'
import { Modal } from "react-bootstrap";

const Treatment = () => {

    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="adm-dep-body-div">
                        <TreatmentTable />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Treatment