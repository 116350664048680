import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';

const SuccessNotification = ({ err, title, success, setSuccess }) => {
    useEffect(() => {
        setTimeout(() => {
            setSuccess(false)
        }, 3000);
    }, [])

    return (
        <>
            <Modal show={success} onHide={() =>
                setSuccess(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Success </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <div className='onfirm-delete-main-div'>
                            <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>{title}</span> submittion is Success </p>
                            {/* <p>{err}</p> */}
                            <div className="confirm-delete-btn-div">
                                {/* <button
                                type="button" className="btn btn-danger"
                                onClick={deleteHandler}>Delete</button> */}
                                <button
                                    type="button" className="btn btn-secondary ml-3"
                                    onClick={() => setSuccess(false)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default SuccessNotification