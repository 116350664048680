import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13 } from '../imagepath';
import useAsync from '../../../hooks/useAsync';
import EnquiryService from '../../../services/EnquiryService';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';
import DeleteModal from '../Modal/DeleteModal';
import EnquiryViewModal from './EnquiryViewModal';
import { Modal } from 'react-bootstrap';
import { FcPrint, FcDownload } from 'react-icons/fc'
import CheckModal from '../../assets/constant/CheckModal';
import { useReactToPrint } from 'react-to-print';
import { CSVLink, CSVDownload } from "react-csv";

const EnquiryTable = ({ pageId }) => {
    const allEnquiry = useAsync(EnquiryService.allEnquiries)
    const { setIsUpdate } = useContext(UserContext)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()
    const [viewId, setViewId] = useState()
    const [viewModal, setViewModal] = useState(false)
    const [data, setData] = useState([])
    const [checkModal, setCheckModal] = useState(false)
    const [isPrint, setIsPrint] = useState(false)
    const componentRef = useRef()

    console.log("page id", pageId)

    useEffect(() => {
        if (pageId == "all") {
            setData(allEnquiry?.data)
        } else if (pageId == "hospital") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == pageId))
        } else if (pageId == "doctor") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == pageId))
        } else if (pageId == "treatment") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == pageId))
        } else if (pageId == "package") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == pageId))
        } else if (pageId == "add-hospital") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == pageId))
        } else if (pageId == "common") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == "consult" || current?.filter_field == "" || current?.filter_field == null))
        }else if (pageId == "contact-us") {
            setData(allEnquiry?.data?.filter((current) => current?.filter_field == pageId || current?.filter_field == "" || current?.filter_field == null))
        }
         else {
            setData(allEnquiry?.data)
        }



    }, [pageId, allEnquiry?.data])

    const pageTitle = pageId == "common" ? "Common" :
    pageId == "contact-us" ? "Contact us" :
        pageId == "add-hospital" ? "Add Hospital" :
            pageId == "package" ? "Package" :
                pageId == "treatment" ? "Treatment" :
                    pageId == "doctor" ? "Doctor" :
                        pageId == "hospital" ? "Hospital" :
                            "All"


    const deleteModalShow = (id, name) => {
        setDeleteId(id)
        setDeleteName(name)
        setDeleteModal(true)
    }

    const deleteHandler = () => {
        EnquiryService.deleteEnquiry(deleteId).then((res) => {
            alert('Deleted Successfully')
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    const ListModalShow = (id) => {
        setViewId(id)
        setViewModal(true)
    }

    const checkHandler = () => {
        setIsPrint(true)
        setCheckModal(true)
    }

    const printData = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Faq Details",
        onAfterPrint: () => afterPrint(),
    })

    const afterPrint = () => {
        setIsPrint(false)
        setCheckModal(false)
    }

    const columns = [
        {
            name: 'Id',
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },

        {
            name: 'Name',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar"><Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#patientlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row.image_list[0]} alt="User Image" /></Link></h2><Link to="#"
                data-bs-target="#Patientlist"
                data-bs-toggle="modal"
            ><span className="user-name">{row.vchr_name}</span><br /><span className="d-block text-muted">{row.age}</span>
            </Link></Media.Body></Media>,
            width: "300px",

        },
        {
            name: 'Email',
            selector: row => row.Date,
            cell: row => <Media ><Media.Body className="d-flex"><div>
                {/* <span className="user-name">{row.Date}</span>
                <br /> */}
                <span className="d-block">{row.vchr_email}</span></div></Media.Body></Media>,
            width: "300px",
        },

        {
            name: 'Account status',
            selector: row => row.status,
            sortable: true,
            cell: row => <div className="actions">
                {
                    !isPrint &&

                    <Link
                        // className="text-danger"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        onClick={() => ListModalShow(row.id)}
                        style={{ color: '#219ebc' }}

                    >
                        <i className='me-1'><FeatherIcon icon="eye" /></i> View
                    </Link>
                }
                {/* <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    // onClick={() => updateHandler(row.id, row.name)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link> */}
                {
                    !isPrint &&

                    <Link
                        className="text-danger"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        onClick={() => deleteModalShow(row.id, row.vchr_email)}
                    >
                        <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                    </Link>
                }
            </div>,
            width: "300px",
        },




    ];





    const tableData = {
        columns,
        data,
    };
    return (
        <div className="card-body p-0" ref={componentRef}>

            {
                !isPrint &&
                <div className="table-btn-header-div mt-2">
                    <div className='enquiry-header-div'>
                        <h4>{pageTitle} Enquiries</h4>
                    </div>

                    <div className='print-div'>
                        <button className='print-btn'
                            data-toggle="tooltip" data-placement="top" title="Print"
                            onClick={checkHandler}><FcPrint /></button>

                        <CSVLink data={data}>
                            <button className='print-btn'
                                data-toggle="tooltip" data-placement="top" title="Print"
                            ><FcDownload /></button>
                        </CSVLink>
                    </div>
                </div>
            }
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable className="datatable table table-borderless hover-table"
                        id="data-table"

                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination={!isPrint ? true : false}
                        highlightOnHover
                        actions={<button className='btn btn-info'>Print</button>}
                    />
                </DataTableExtensions>
            </div>

            {/* delete modal */}
            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName} />

            {/* View modal */}
            <Modal show={viewModal} onHide={() =>
                setViewModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Enquiry </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <EnquiryViewModal enqId={viewId} />
                    </div>
                </Modal.Body>
            </Modal>

            {/* Print Modal */}

            <Modal show={checkModal} onHide={() =>
                setCheckModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Confirm Print </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CheckModal handler={printData} setModal={setCheckModal} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EnquiryTable;