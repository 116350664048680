import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
// import { SidebarContext } from '../context/SidebarContext';
import { UserContext } from '../Context/AllContext';

const useAsync = (asyncFunction,reload) => {
    const [data, setData] = useState([] || {});
    const [error, setError] = useState('');
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const { isUpdate, setIsUpdate } = useContext(UserContext);

    useEffect(() => {
        let unmounted = false;
        let source = axios.CancelToken.source();
        // console.log(source)

        asyncFunction({ cancelToken: source.token })
            .then((res) => {
                // console.log(res)
                if (!unmounted) {
                    setCount(res.count)
                    setData(res.results ? res.results : res);
                    setError('');
                    setLoading(false);
                }
            })
            .catch((err) => {
                // console.log(err)

                if (!unmounted) {
                    setError(err.message);
                    if (axios.isCancel(err)) {
                        setError(err.message);
                        setLoading(false);
                        setData([]);
                    } else {
                        setError(err.message);
                        setLoading(false);
                        setData([]);
                    }
                    setCount(0)
                }
            });

        setIsUpdate(false);

        return () => {
            unmounted = true;
            source.cancel('Cancelled in cleanup');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdate,reload]);

    return {
        data,
        error,
        loading,
        count
    };
};

export default useAsync;
