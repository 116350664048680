import React from 'react'
import { useEffect, useState } from 'react'
import EnquiryService from '../../../services/EnquiryService'


function EnquiryViewModal({ enqId }) {
    const [data, setData] = useState([])
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(() => {
        if (enqId) {
            EnquiryService.getEnquiryById(enqId).then((res) => {
                console.log(res.results)
                setData(res.results)
            })
        }
    }, [])
    console.log("enquiry data", data)
    console.log("image list", data.image_list)


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className='container'>
            <div className="row">
                <div className="row col-md-12">
                    <div className="col-md-3">
                        <h6>Email:</h6>
                    </div>
                    <div className="col-md-9">
                        <h6>{data?.vchr_email}</h6>
                    </div>
                </div>

                <div className="row col-md-12">
                    <div className="col-md-3">
                        <h6>Phone Number:</h6>
                    </div>
                    <div className="col-md-9">
                        <h6>{data?.int_mobile}</h6>
                    </div>
                </div>

                <div className="row col-md-12">
                    <div className="col-md-3">
                        <h6>Message:</h6>
                    </div>
                    <div className="col-md-9">
                        <h6>{data?.txt_message}</h6>
                    </div>
                </div>

                <div className="row col-md-12">
                    <div className="col-md-3">
                        <h6>Files:</h6>
                    </div>
                    <div className="col-md-9">
                        <div className='enq-view-img-div'>
                            {
                                data?.image_list?.map((item, index) => {
                                    return (
                                        <img key={index} src={item} alt="" />

                                    )
                                })
                            }
                            {/* <img src={image_list[0]} alt="" /> */}
                        </div>

                    </div>
                </div>

                <div className="row col-md-12">
                    <div className="col-md-3">
                        <h6>Documents:</h6>
                    </div>
                    <div>
                        {
                            data?.upload_file_list?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <a href={item} target="_blank">Download Pdf {index + 1}</a>
                                    </div>
                                )
                            })
                        }


                        {/* <p>
                            Page {pageNumber} of {numPages}
                        </p> */}
                    </div>
                </div>



            </div>

        </div>
    )
}

export default EnquiryViewModal