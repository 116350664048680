import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { UserContext } from '../../../Context/AllContext'
import FaqService from '../../../services/FaqService'

export const AddCommonFaq = ({ editId, setFormPage }) => {
    const ref1 = useRef(null)
    const ref2 = useRef(null)

    const [values, setValues] = useState({
        dct_faq_qst: '',
        dct_faq_ans: ''
    })
    const { setIsUpdate } = useContext(UserContext)

    useEffect(() => {
        if (editId) {
            FaqService.getCommonFaqById(editId).then((res) => {
                setValues({
                    dct_faq_qst: res?.dct_faq_qst,
                    dct_faq_ans: res?.dct_faq_ans
                })
            })
        }
    }, [])


    const resetFunction = () => {
        if (ref1.current.value != "") {
            ref1.current.value = null;
        }

        if (ref2.current.value != "") {
            ref2.current.value = null;
        }
    }

    const valuesChange = e => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const valuesSubmit = e => {
        e.preventDefault()

        let formData = new FormData()
        formData.append('dct_faq_qst', values.dct_faq_qst)
        formData.append('dct_faq_ans', values.dct_faq_ans)
        {
            editId ?
                FaqService.updateCommonFaq(editId, formData).then((res) => {
                    console.log(res)
                    alert("Updated Successfully")
                    setIsUpdate(true)
                    setFormPage("commonFaq")
                    resetFunction()
                })
                :

                FaqService.addCommonFaq(formData).then((res) => {
                    console.log(res)
                    alert("Updated Successfully")
                    setIsUpdate(true)
                    resetFunction()
                })
        }

    }

    return (
        <div className='adm-add-faq-main'>
            <div className="adm-add-faq-body-div">
                <h4> Common Faq</h4>
                <form action="" onSubmit={valuesSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Question?</label>
                                <input type="text"
                                    className="form-control"
                                    name='dct_faq_qst'
                                    ref={ref1}
                                    defaultValue={values.dct_faq_qst}
                                    onChange={valuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Answer</label>
                                <input type="text"
                                    className="form-control"
                                    name='dct_faq_ans'
                                    ref={ref2}
                                    defaultValue={values.dct_faq_ans}
                                    onChange={valuesChange} />
                            </div>
                        </div>

                        <div className="adm-testi-btn-div">
                            <button type="submit" className="btn btn-success">
                                {/* {editId ? "Save Changes" : "Add"} */}
                                Add
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}
