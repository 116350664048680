import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13 } from '../imagepath';
import useAsync from '../../../hooks/useAsync';
import HospitalService from '../../../services/HospitalService';
import { Modal } from 'react-bootstrap';
import DeleteModal from '../Modal/DeleteModal';
import { UserContext } from '../../../Context/AllContext';
import { AiFillEye } from 'react-icons/ai'
import ViewHospitalModal from './ViewHospitalModal';
import FormPage from '../addNewPage/resultpages/FormPage';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

const HospitalsTable = ({ setView, setViewId }) => {
    const history = useHistory()
    const hospitals = useAsync(() => HospitalService.getHospitalsByPage(pageNo))
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [deleteItemId, setDeleteItemId] = useState()
    const [deleteItemName, setDeleteItemName] = useState()
    const { isUpdate, setIsUpdate } = useContext(UserContext)
    const [viewHospitalName, setViewHospitalName] = useState()
    const [editId, setEditId] = useState()
    const [editModal, setEditModal] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        if (hospitals) {
            setData(hospitals.data)
        }
    }, [hospitals.data])


    const deleteModalShow = (id, name) => {
        setShowDeleteModal(true)
        setDeleteItemId(id)
        setDeleteItemName(name)
    }

    const deletItemHandler = () => {
        HospitalService.deleteHospital(deleteItemId).then((res) => {
            alert(`${res.name} Deleted Successfully`)
            setShowDeleteModal(false)
            setIsUpdate(true)
        })
    }

    const viewHandler = (id) => {
        setViewId(id)
        setView(true)
    }

    const updateHandler = (id) => {
        setEditModal(true)
        setEditId(id)
    }

    const pageUpHandler = () => {
        setPageNo(pageNo + 1)
        HospitalService.getHospitalsByPage(pageNo + 1).then((res) => {
            console.log(res)
            setData(res.results)
        })
    }

    const pageDownHandlder = () => {
        setPageNo(pageNo - 1)
        HospitalService.getHospitalsByPage(pageNo - 1).then((res) => {
            setData(res.results)
        })
    }

    const columns = [
        {
            name: 'Id',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "150px",
        },

        {
            name: 'Hospital',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar"><Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#doctorlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row.hospital_img} alt="" /></Link></h2><Link to="#"
                data-bs-target="#doctorlist"
                data-bs-toggle="modal"
            >
            </Link><span className="user-name">{row.name}</span></Media.Body></Media>,
            width: "auto",

        },

        {
            name: 'Location',
            selector: row => row.address,
            sortable: true,
            width: "250px",
        },
        // {
        //     name: 'Phone Number',
        //     selector: row => row.phone_num,
        //     sortable: true,
        //     width: "250px",
        // },


        // {
        //     name: 'Email',
        //     selector: row => row.email,
        //     sortable: true,
        //     width: "250px",
        // },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => viewHandler(row.id)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link>
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateHandler(row.id)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id, row.name)}

                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>

            </div>,
            width: "250px",

        },
        // {
        //     name: 'Account status',
        //     selector: row => row.status,
        //     sortable: true,
        //     cell: row => <label className="toggle-switch" htmlFor={row.status}>
        //         <input
        //             type="checkbox"
        //             className="toggle-switch-input"
        //             id={row.status}
        //             defaultChecked=""
        //         />
        //         <span className="toggle-switch-label">
        //             <span className="toggle-switch-indicator" />
        //         </span>
        //     </label>,
        //     width: "250px",
        // },




    ];





    const tableData = {
        columns,
        data,

    };
    return (
        <>
            <div className="card-body p-0">
                <div className="table-responsive">
                    <DataTableExtensions
                        {...tableData}
                    >
                        <DataTable

                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                        />
                    </DataTableExtensions>
                </div>
                {/* Delete modal */}

                <DeleteModal deleteModal={showDeleteModal}
                    setDeleteModal={setShowDeleteModal}
                    deleteHandler={deletItemHandler}
                    deleteName={deleteItemName} />


                {/* edit modal */}
                <Modal show={editModal} onHide={() =>
                    setEditModal(false)}>
                    <Modal.Header closeButton >
                        <Modal.Title className='profile-settings-modal-header'>
                            <h4>{viewHospitalName} </h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-image-main-cont">
                            <FormPage editId={editId} setEditModal={setEditModal} />
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
            <div className="pagination-hosp-btns-div">
                {
                    pageNo > 1 &&
                    <button type="button" className='btn btn-info pagination-left-btn'
                        onClick={() => pageDownHandlder()}
                    >
                        <i className='pagination-icon'><FiChevronLeft /></i>
                        Back
                    </button>
                }

                {
                    data?.length == 15 &&
                    <button type="button" className="btn btn-info pagination-right-btn"
                        onClick={() => pageUpHandler()}
                    >
                        Next
                        <i className='pagination-icon'><FiChevronRight /></i>
                    </button>
                }


            </div>
        </>
    )
}

export default HospitalsTable;