import React from 'react'
import Sidebar from '../sidebar'
import { DocImg01 } from '../imagepath'
import { BiArrowBack } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import AdminServices from '../../../services/AdminServices'
import useAsync from '../../../hooks/useAsync'
import userDetailServices from '../../../services/UserServices'
import EnquiryService from '../../../services/EnquiryService'
import BookingService from '../../../services/BookingService'
import UserBookingsModal from './UserBookingsModal'
import UserEnquiryModal from './UserEnquiryModal'

const UserProfile = () => {
    const location = useLocation()
    const userId = location?.state?.item
    const [userData, setUserData] = useState()
    const [dischargeData, setDischargeData] = useState()
    const [visaData, setVisaData] = useState()
    const [flightsData, setFlightsData] = useState()
    const [hotelsData, setHotelsData] = useState()
    const [bookData, setBookData] = useState()
    const [enquiryData, setEnquiryData] = useState()
    const [bookingModal, setBookingModal] = useState(false)
    const [enquiryModal, setEnquiryModal] = useState(false)

    const allDischargeSummary = useAsync(userDetailServices.getAllDischargeSummary)
    const allHotelStatus = useAsync(userDetailServices.getAllHotel)
    const allVisaStatus = useAsync(userDetailServices.getAllVisa)
    const allFlights = useAsync(userDetailServices.getAllFlightDetails)
    const allEnquiries = useAsync(EnquiryService.allEnquiries)
    const allBookings = useAsync(BookingService.allBookings)


    useEffect(() => {
        if (userId) {
            AdminServices.getUserDetailsById(userId).then((res) => {
                setUserData(res)
            })
            setDischargeData(allDischargeSummary?.data.filter((current) => current?.user_id == userId))
            setVisaData(allVisaStatus?.data?.filter((currentVisa) => currentVisa?.user_id == userId))
            setHotelsData(allHotelStatus?.data.filter((currentHotel) => currentHotel?.user_id == userId))
            setFlightsData(allFlights?.data?.filter((currentFlight) => currentFlight?.user_id == userId))
            setBookData(allBookings?.data?.filter((currentBook) => currentBook?.user == userId))
            setEnquiryData(allEnquiries?.data?.filter((currentEnquire) => currentEnquire?.int_code == userId))
        }
    }, [userId, allDischargeSummary?.data, allVisaStatus?.data, allHotelStatus?.data, allFlights?.data, allBookings?.data, allEnquiries?.data])



    return (
        <>
            <Sidebar />

            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="container-fluid background-card">
                        <div className="col-12 col-md-12 col-lg-12 p-2">
                            <Link to="/patient-list">
                                <button className='btn btn-primary btn-sm d-flex align-items-center justify-content-center'>
                                    <BiArrowBack />
                                    Back
                                </button>
                            </Link>
                        </div>

                        <div className="row p-5">
                            <div className="col-12 col-md-3  col-lg-4">
                                {userData?.patient_image && <img className='img-fluid rounded ' src={userData?.patient_image} alt="" />}
                            </div>
                            <div className="col-12 col-md-9 col-lg-8 d-flex flex-column justify-content-md-start 
                            align-items-md-start align-items-sm-center justify-content-sm-center">
                                {userData?.vchr_name && <h5>Name : {userData?.vchr_name}</h5>}
                                {/* <p>Designation : </p> */}
                                {userData?.email && <p>Email: {userData?.email}</p>}
                                {userData?.mobile && <p>Phone Number: {userData?.mobile}</p>}
                            </div>
                        </div>

                        <div className="row pl-5 pr-5 pt-2 pb-2">
                            <div className="row col-12 col-md-12 col-lg-12">
                                <h3 className='text-primary'>Details</h3>
                            </div>

                            <div className="row col-12 col-md-12 col-lg-12">
                                <div className="row col-12 col-md-12 col-lg-12 d-flex align-items-md-center justify-content-center mt-2 mb-2">
                                    <div className="row col-12 col-md-5 col-lg-5 border rounded bg-light"
                                        onClick={() => setEnquiryModal(true)}>
                                        <h6 className='text-center mb-0 p-2'>Enquiries: {enquiryData?.length}</h6>
                                    </div>
                                    <div className="row col-12 col-md-5 col-lg-5 border rounded ml-2 mx-auto sm-mt-2 bg-light"
                                        onClick={() => setBookingModal(true)}>
                                        <h6 className='text-center mb-0 p-2 '>Bookings: {bookData?.length}</h6>
                                    </div>
                                </div>

                                <div className="row col-12 col-md-12 col-lg-12">
                                    {userData?.age && <p>Age: {userData?.age} </p>}
                                    {userData?.gender && <p>Gender: {userData?.gender}</p>}
                                    {userData?.weight && <p>Weight: {userData?.weight} kg</p>}
                                    {userData?.height && <p>Height: {userData?.height} cm</p>}
                                    {userData?.blood_group && <p>Blood Group: {userData?.blood_group}</p>}
                                    {userData?.address && <p>Address: {userData?.address}</p>}
                                    {userData?.city && <p>City: {userData?.city}</p>}
                                    {userData?.state && <p>State: {userData?.state}</p>}
                                    {userData?.fk_country && <p>Country: {userData?.fk_country}</p>}
                                    {userData?.exist_condition && <p>Exist Condition : {userData?.exist_condition}</p>}
                                </div>

                            </div>

                        </div>

                        <div className="row pl-5 pr-5 pt-2 pb-2">
                            <div className="col-12 col-md-12">
                                <h6 className='text-primary h5'>Discharge Summary</h6>
                            </div>
                            {
                                dischargeData?.length != 0 ?
                                    dischargeData?.map((discharge) => {
                                        return (

                                            <div className="col-12 col-md-12">
                                                <div className="col">
                                                    <h6>{discharge?.body}</h6>
                                                    <div className="col-6 ol-md-6 col-lg-6">
                                                        {
                                                            discharge?.files.match(/\.(jpg|jpeg|png|gif)$/i) ?

                                                                <img className='img-fluid rounded' src={discharge?.files} alt="" />
                                                                :
                                                                <a href={discharge?.files} target="_blank">Download Pdf </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>

                        <div className="row pl-5 pr-5 pt-2 pb-2">
                            <div className="col-12 col-md-12">
                                <h6 className='text-primary h5'>Hotel Booking</h6>
                            </div>
                            {
                                hotelsData?.length != 0 ?
                                    hotelsData?.map((hotel) => {
                                        return (

                                            <div className="col-12 col-md-12">
                                                <div className="col">
                                                    <h6>{hotel?.body}</h6>
                                                    <div className="col-6 ol-md-6 col-lg-6">
                                                        {
                                                            hotel?.files.match(/\.(jpg|jpeg|png|gif)$/i) ?

                                                                <img className='img-fluid rounded' src={hotel?.files} alt="" />
                                                                :
                                                                <a href={hotel?.files} target="_blank">Download Pdf </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>

                        <div className="row pl-5 pr-5 pt-2 pb-2">
                            <div className="col-12 col-md-12">
                                <h6 className='text-primary h5'>Visa Status</h6>
                            </div>
                            {
                                visaData?.length != 0 ?
                                    visaData?.map((visa) => {
                                        return (

                                            <div className="col-12 col-md-12">
                                                <div className="col">
                                                    <h6>{visa?.body}</h6>
                                                    <div className="col-6 ol-md-6 col-lg-6">
                                                        {
                                                            visa?.files.match(/\.(jpg|jpeg|png|gif)$/i) ?

                                                                <img className='img-fluid rounded' src={visa?.files} alt="" />
                                                                :
                                                                <a href={visa?.files} target="_blank">Download Pdf </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>

                        <div className="row pl-5 pr-5 pt-2 pb-2">
                            <div className="col-12 col-md-12">
                                <h6 className='text-primary h5'>Flight Details</h6>
                            </div>
                            {
                                flightsData?.length != 0 ?
                                    flightsData?.map((flight) => {
                                        return (

                                            <div className="col-12 col-md-12">
                                                <div className="col">
                                                    <h6>{flight?.body}</h6>
                                                    <div className="col-6 ol-md-6 col-lg-6">
                                                        {
                                                            flight?.files.match(/\.(jpg|jpeg|png|gif)$/i) ?

                                                                <img className='img-fluid rounded' src={flight?.files} alt="" />
                                                                :
                                                                <a href={flight?.files} target="_blank">Download Pdf </a>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            }
                        </div>

                    </div>
                </div>
            </div>
            <UserBookingsModal modal={bookingModal} setModal={setBookingModal} data={bookData} />

            <UserEnquiryModal modal={enquiryModal} setModal={setEnquiryModal} data={enquiryData} />
        </>
    )
}

export default UserProfile