import requests from './httpServices'

const PasswordServices = {
    addPassword(body) {
        return requests.post('/user_login/reset_password/', body)
    },
    changePassword(body) {
        return requests.put('/user_login/change_password/', body)
    }
}

export default PasswordServices