import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { Media } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13 } from '../imagepath';
import useAsync from '../../../hooks/useAsync'
import PackageService from '../../../services/PackageService'
import DeleteModal from '../Modal/DeleteModal'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'
import { Modal } from 'react-bootstrap'
import ViewModal from './ViewModal'

const PackageTable = ({ editId, setEditId, packageModal, setPackageModal }) => {
    const { setIsUpdate } = useContext(UserContext)
    const { data, loading, error } = useAsync(PackageService.allAssignedPackage)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()
    const [viewModal, setViewModal] = useState(false)
    const [viewId, setViewId] = useState()
    console.log("assign pro", data)



    const deleteModalHandler = (id, name) => {
        setDeleteId(id)
        setDeleteModal(true)
        setDeleteName(name)
    }

    const deleteHandler = () => {
        PackageService.deletePackage(deleteId).then((res) => {
            alert("Deleted Successfully")
            setDeleteModal(false)
            setIsUpdate(true)
        })
    }

    const updateHandler = (id) => {
        setPackageModal(true)
        setEditId(id)
    }

    const ListModalShow = (id) => {
        setViewModal(true)
        setViewId(id)
    }

    const columns = [
        {
            name: 'Id',
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },

        {
            name: 'Package',
            sortable: true,
            cell: row => <Media className='auto-scroll-text-div' ><Media.Body className="d-flex"><h2 className="table-avatar"><Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#doctorlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row.fk_procedure?.offr_image} alt="" /></Link></h2><Link to="#"
                data-bs-target="#doctorlist"
                data-bs-toggle="modal"
            >
            </Link><span className="user-name auto-scroll-text">{row.fk_procedure?.name}</span></Media.Body></Media>,
            maxWidth: "300px",


        },

        {
            name: 'Hospital',
            selector: row => row?.fk_hospital?.name,
            sortable: true,
            width: "250px",
        },


        // {
        //     name: 'Address',
        //     selector: row => row.location,
        //     sortable: true,
        //     width: "250px",
        // },

        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => ListModalShow(row.id)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link>
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateHandler(row.id)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalHandler(row.fk_procedure.id, row?.fk_procedure.name)}

                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "250px",

        },

    ];

    const tableData = {
        columns,
        data,

    };
    return (
        <div className="card-body p-0">
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* Delete modal */}

            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName} />

            {/* view modal */}
            <Modal show={viewModal} onHide={() =>
                setViewModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4> Offer Details </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <ViewModal id={viewId} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PackageTable