import React, { useState, useEffect } from 'react'
import DoctorService from '../../../services/DoctorService'
import { blogBanner } from '../imagepath'
import parse from 'html-react-parser';
import HospitalService from '../../../services/HospitalService';

const HospitalProfile = ({ setView, viewId }) => {
    const [hospData, setHospData] = useState([])

    useEffect(() => {
        if (viewId) {
            HospitalService.getHospitalById(viewId).then((res) => {
                setHospData(res)
            })
        }
    }, [])

    console.log("hosp data", hospData)

    return (
        <div className='profile-container'>
            <div className="profile-view-header-div">
                <button className='btn btn-primary btn-sm'
                    onClick={() => setView(false)}>Back</button>
                <h5>Hospital Profile</h5>
            </div>
            <div className="profile-card">
                <div className="profile-card-left-div">
                    <img src={hospData?.hospital_img} alt="" />
                </div>
                <div className="profile-card-right-div">
                    {hospData?.name && <h5>{hospData?.name}</h5>}
                    {hospData?.multi_speciality && <p>{hospData?.multi_speciality}</p>}
                    {hospData?.address && <p>{hospData?.addres}</p>}
                    {hospData?.email && <p>{hospData?.email}</p>}
                    {/* {docData?.main_experience && <p>{docData?.main_experience} Years</p>}
                    {docData?.email && <h5>{docData?.email}</h5>}
                    {docData?.phone_num && <h5>{docData?.phone_num}</h5>} */}
                </div>
            </div>
            <div className="profile-body-div">
                <h5>About</h5>
                {hospData?.description && <p>{parse(`${hospData?.description}`) || hospData?.description}</p>}

                <div className="profile-body-second-div">
                    <h5>Doctors Worked</h5>
                    {
                        hospData?.doctors_worked_list?.map((item) => {
                            return (
                                <div className="profile-second-item-div" key={item?.id}>
                                    <h6>{item?.name}</h6>
                                    <p>{item?.doc_designation || item?.department}</p>
                                    <p>{item?.doc_experience}</p>
                                </div>
                            )
                        })
                    }

                </div>


                <div className="profile-body-fifth-div">
                    <h5>Facilities</h5>
                    <ul>
                        {
                            hospData?.facilities_list?.map((item) => {
                                return (
                                    <li key={item?.id}>{item?.facility_name}</li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="profile-body-sixth-div">
                    <h5>Specialities</h5>
                    <ul>
                        {
                            hospData?.department_list?.map((item) => {
                                return (
                                    <li key={item?.id}>{item?.vchr_name}</li>
                                )
                            })
                        }
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default HospitalProfile