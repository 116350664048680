import requests from "./httpServices";

const SpecialityServices = {
    addSpeciality(body) {
        return requests.filePost('/hospital/speciality/', body)
    },
    allSpeciality() {
        return requests.get('/hospital/speciality/')
    },
    getSpecialityById(id) {
        return requests.get(`/hospital/speciality/${id}/`)
    },
    updateSpeciality(id, body) {
        return requests.filePut(`/hospital/speciality/${id}/`, body)
    },
    deleteSpeciality(id, body) {
        return requests.delete(`/hospital/speciality/${id}/`, body)
    },
    specialityByPage(page) {
        return requests.get(`/hospital/speciality/?page=${page}`)
    }
}
export default SpecialityServices