import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import SidebarNav from "../sidebar/index";
import { Modal, Button } from 'react-bootstrap';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13, doctor09, sort } from '../imagepath';
import HospitalsTable from './HospitalTable';
import { AiOutlineFolderAdd } from 'react-icons/ai'
import { logo } from '../imagepath'
import AddNewHospitalPage from './AddNewHospitalPage';
import useAsync from '../../../hooks/useAsync';
import HospitalService from '../../../services/HospitalService';
import PromoteHospital from './PromoteHospital';
import HospitalProfile from './HospitalProfile';
import { useLocation } from 'react-router-dom';

const AdminHospitalList = () => {
    const { data } = useAsync(HospitalService.allHospitalList)
    const [show2, setShow2] = useState(false);
    const [promoteModal, setPromoteModal] = useState(false)
    const [profileView, setProfileView] = useState(false)
    const [viewId, setViewId] = useState('')
    const [addModalShow, setAddModalShow] = useState(false)
    const location = useLocation();
    const item = location.state

    console.log("item", location)

    useEffect(() => {
        if (item?.item) {
            setProfileView(true)
            setViewId(item?.item)
        }
    }, [item?.item])


    return (
        <>
            <SidebarNav />
            {/* Page Wrapper */}
            <div className="page-wrapper">
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="add-new-btn-div">
                            <Link to="/add-hospital">
                                <button className='add-lg-btn'>Add New <AiOutlineFolderAdd /></button>
                            </Link>

                            <button onClick={() => setPromoteModal(true)}
                                className='btn btn-primary ml-3 promote-btn' >
                                Promote Hospital
                            </button>
                        </div>
                        <div className="row align-items-center">

                            <div className="col-md-12 d-flex justify-content-end">
                                <div className="doc-badge me-3">
                                    Hospitals <span className="ms-1">{data.length}</span>
                                </div>
                                {/* <div className="SortBy">
                                    <div className="selectBoxes order-by">
                                        <p className="mb-0" onClick={(value) => toggleFilterMenu1()}>
                                            <img
                                                src={sort}
                                                className="me-2"
                                                alt="icon"
                                            />{" "}
                                            Order by{" "}
                                        </p>
                                        <span className="down-icon">
                                            <i className=''><FeatherIcon icon="chevron-down" /></i>

                                        </span>
                                    </div> */}



                                {/* <div id="checkBox" style={{ display: show1 ? "block" : "none" }}>
                                        <form action="doctor-list.html">
                                            <p className="lab-title">Specialities</p>
                                            <label className="custom_radio w-100">
                                                <input type="radio" name="year" />
                                                <span className="checkmark" /> Number of Appointment
                                            </label>
                                            <label className="custom_radio w-100">
                                                <input type="radio" name="year" />
                                                <span className="checkmark" /> Total Income
                                            </label>
                                            <label className="custom_radio w-100 mb-4">
                                                <input type="radio" name="year" />
                                                <span className="checkmark" /> Ratings
                                            </label>
                                            <p className="lab-title">Sort By</p>
                                            <label className="custom_radio w-100">
                                                <input type="radio" name="sort" />
                                                <span className="checkmark" /> Ascending
                                            </label>
                                            <label className="custom_radio w-100 mb-4">
                                                <input type="radio" name="sort" />
                                                <span className="checkmark" /> Descending
                                            </label>
                                            <button type="submit" className="btn w-100 btn-primary">
                                                Apply
                                            </button>
                                        </form>
                                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    {/* Doctor List */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="card-title">Hospital</h5>
                                        </div>

                                        {/* <div className="col-auto d-flex flex-wrap">
                                            <div className="form-custom me-2">
                                                <div id="tableSearch" className="dataTables_wrapper" />
                                            </div>
                                            <div className="multipleSelection">
                                                <div className="selectBox">
                                                    <p className="mb-0 me-2" onClick={(value) => toggleFilterMenu2()}>
                                                        <i className='me-1'><FeatherIcon icon="filter" /></i> Filter By
                                                        Speciality{" "}
                                                    </p>
                                                    <span className="down-icon">
                                                        <i className='me-1'><FeatherIcon icon="chevron-down" /></i>

                                                    </span>
                                                </div>
                                                <div id="checkBoxes" style={{ display: show2 ? "block" : "none" }}>
                                                    <form action="/admin/doctor-list">
                                                        <p className="lab-title">Location</p>
                                                        <div className="selectBox-cont">
                                                            <label className="custom_check w-100">
                                                                <input
                                                                    type="checkbox"
                                                                    name="year"
                                                                    defaultChecked=""
                                                                />
                                                                <span className="checkmark" /> Urology
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Neurology
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Orthopedic
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Cardiologist
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Dentist
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Gynacologist
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Pediatrist
                                                            </label>
                                                            <label className="custom_check w-100">
                                                                <input type="checkbox" name="year" />
                                                                <span className="checkmark" /> Orthopedic
                                                            </label>
                                                        </div>
                                                        <button type="submit" className="btn w-100 btn-primary">
                                                            Apply
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                                {
                                    profileView ?
                                        <HospitalProfile viewId={viewId} setView={setProfileView} />
                                        :
                                        <HospitalsTable setViewId={setViewId} setView={setProfileView} />
                                }

                            </div>
                            <div id="tablepagination" className="dataTables_wrapper" />
                        </div>
                    </div>
                    {/* /Doctor List */}
                </div>
            </div>

            {/* Add new modal section */}
            <Modal className='modal-main-cont' show={addModalShow} onHide={() =>
                setAddModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='profile-settings-modal-header'>
                        <img src={logo} alt="" />
                        <h5 className="modal-title">Add new Hospital Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddNewHospitalPage />
                </Modal.Body>
            </Modal>



            {/* Promote Modal */}

            <Modal show={promoteModal} onHide={() =>
                setPromoteModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Promote Hospitals </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <PromoteHospital setModal={setPromoteModal} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminHospitalList