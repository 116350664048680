import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideBarNav from '../sidebar'
import AddDoctorTestimonial from "./TestimonialForms/AddDoctorTestimonial";
import AddHospitalTestimonial from "./TestimonialForms/AddHospitalTestimonial";
import DoctorTestimonial from "./TestimonialForms/DoctorTestimonial";
import HospitalTestimonial from "./TestimonialForms/HospitalTestimonial";


const TestimonialPage = () => {
    const [isTestimonialPage, setIsTestimonialPage] = useState("hosp-testimonial")
    const [mainId, setMainId] = useState()

    const pageHandler = (e) => {
        setIsTestimonialPage(e.target.value)
    }


    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="adm-testi-header-div">

                        <button type="button"
                            className="btn btn-success"
                            value="hosp-testimonial"
                            disabled={isTestimonialPage === "hosp-testimonial" && "add-hosp-testimonial" ? true : false}
                            onClick={pageHandler}
                        >  Hospital Testimonial</button>

                        <button type="button"
                            disabled={isTestimonialPage === "doc-testimonial" && "add-doc-testimonial" ? true : false}
                            className="btn btn-success ml-3"
                            value="doc-testimonial"
                            onClick={pageHandler}>
                            Doctor Testimonial</button>


                    </div>
                    <div className="adm-testi-body-div">
                        <div className="adm-testi-header-div">
                            <h6>{isTestimonialPage === "hosp-testimonial" ? "Hospital Testimonial" : isTestimonialPage === "doc-testimonial" ? "Doctor Testimonial" : ''}</h6>
                            {
                                isTestimonialPage === "hosp-testimonial" ?
                                    <button className="btn btn-info ml-5"
                                        value="add-hosp-testimonial"
                                        onClick={pageHandler}
                                    >Add</button>
                                    :
                                    isTestimonialPage === "doc-testimonial" ?
                                        <button className="btn btn-info ml-5"
                                            value="add-doc-testimonial"
                                            onClick={pageHandler}>Add</button>
                                        :
                                        ""
                            }
                        </div>
                        {
                            isTestimonialPage === "hosp-testimonial" ?
                                <HospitalTestimonial mainId={mainId} setMainId={setMainId} setEditForm={setIsTestimonialPage} />
                                :
                                isTestimonialPage === "doc-testimonial" ?
                                    <DoctorTestimonial mainId={mainId} setMainId={setMainId} setEditForm={setIsTestimonialPage} />
                                    :
                                    isTestimonialPage === "add-hosp-testimonial" ?
                                        <AddHospitalTestimonial setMainId={setMainId} mainId={mainId} setEditForm={setIsTestimonialPage} />
                                        :
                                        <AddDoctorTestimonial setMainId={setMainId} mainId={mainId} setEditForm={setIsTestimonialPage} />
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default TestimonialPage