import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAsync from "../../../../hooks/useAsync";
import HospitalService from "../../../../services/HospitalService";
import LoadingSpinner from "../../Loading/LoadingSpinner";


const TopHospitals = ({ title }) => {

    const allHospitals = useAsync(HospitalService.getPromotedHospital)
    const [hospData, setHospData] = useState([])

    useEffect(() => {
        if (allHospitals.data) {
            setHospData(allHospitals?.data[0]?.hospital_list)
        }
    }, [allHospitals?.data])

    return (
        <div className="col-lg-4">
            <div className="card">
                <div className="card-header">
                    <Link to="/hopitals" className="row">
                        <div className="col">
                            <h5 className="card-title">Top {title}</h5>
                        </div>
                    </Link>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table doc-table">
                            <tbody>

                                {
                                    allHospitals.loading ?
                                        <LoadingSpinner />
                                        :
                                        hospData?.length != 0 ? hospData.slice(0, 6).map((item) => {
                                            return (
                                                <tr key={item?.id}>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <Link
                                                                className="avatar-pos avatar-online"
                                                                to={{
                                                                    pathname: "/hopitals",
                                                                    state: { item: item.id }
                                                                }}
                                                            >
                                                                <img
                                                                    className="avatar avatar-img"
                                                                    src={item?.image}
                                                                    alt="User Image"
                                                                />
                                                            </Link>
                                                            <Link to={{
                                                                pathname: "/hopitals",
                                                                state: { item: item.id }
                                                            }} className="user-name">
                                                                <span className="text-muted">{item?.name}</span>{" "}
                                                                <span className="tab-subtext">{item?.address}</span>
                                                            </Link>
                                                        </h2>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                            :
                                            <div className="dash-no-data-found-div">
                                                <h4>No Packages Found</h4>
                                            </div>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopHospitals;
