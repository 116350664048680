import React from 'react'
import { useEffect, useState } from 'react'
import PackageService from '../../../services/PackageService'

function ViewModal({ id }) {
    const [viewDetail, setViewDetail] = useState()
    useEffect(() => {
        if (id) {
            PackageService.getAssignedPackageById(id).then((res) => {
                console.log(res)
                setViewDetail(res.results)
            })
        }
    }, [id])
    console.log("state", viewDetail)

    return (
        <div className="col-md-12">
            <div className="col-md-12 mb-3">
                <img className='img-fluid' src={viewDetail?.fk_procedure?.offr_image} alt="" />
            </div>
            <div className="row">
                <div className="col-md-3">
                    <h6>Name :</h6>
                </div>
                <div className="col-md-9">
                    <h6 className='view-text-content'>{viewDetail?.fk_procedure?.name}</h6>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-3">
                    <h6>Hospital :</h6>
                </div>
                <div className="col-md-9">
                    <h6 className='view-text-content'>{viewDetail?.fk_hospital?.name}</h6>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-3">
                    <h6>Department :</h6>
                </div>
                <div className="col-md-9">
                    <h6 className='view-text-content'>{viewDetail?.fk_procedure?.department?.vchr_name}</h6>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-3">
                    <h6 >Offer Details :</h6>
                </div>
                <div className="col-md-9">
                    <h6 className='view-text-content'>{viewDetail?.fk_procedure?.description}</h6>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-3">
                    <h6>Hospital Amount :</h6>
                </div>
                <div className="col-md-9">
                    <h6 className='view-text-content'>{viewDetail?.flt_price}</h6>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-3">
                    <h6>Offer Amount :</h6>
                </div>
                <div className="col-md-9">
                    <h6 className='view-text-content'>{viewDetail?.flt_dis_amt}</h6>
                </div>
            </div>


        </div>
    )
}

export default ViewModal