import React, { useEffect, useState } from 'react';
import SidebarNav from "../sidebar";
import Select2 from 'react-select2-wrapper';
import FeatherIcon from 'feather-icons-react';
import SelectField from '../commoncomponent/selectfield';

const AddBlog = () => {

  const [blog, setBlog] = useState([
    { label: "Category", value: 'Category' },
    { label: "Dermotology", value: 'Dermotology' },
    { label: "Neurology", value: 'Neurology' },
  ]);
  const [blogtype, setBlogType] = useState([
    { label: "Blog Type", value: 'Blog Type', name: 'blog_type' },
    { label: "Standard Blog", value: 'Standard Blog', name: 'blog_type' },
    { label: "Video Blog", value: 'Video Blog', name: 'blog_type' },
  ]);

  const [blogValues, setBlogValues] = useState({
    title: "",
    blog_type: "",

    txt_content: "",
    image: null,
  })

  const blogValuesChange = (e) => {
    setBlogValues({ ...blogValues, [e.target.name]: e.target.value })
  }

  const blogImageChange = (e) => {

    setBlogValues({ ...blogValues, [e.target.name]: e.target.files[0] })
  }

  console.log("blog title :", blogValues.title)
  console.log("blog content :", blogValues.txt_content)
  console.log('blog type :', blogValues.blog_type);
  console.log("blog image :", blogValues.image)




  return (
    <>
      <SidebarNav />
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Add Blog */}
          <div className="row">
            <div className="col-md-12">
              <h5 className="mb-3">Add Blog</h5>
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group form-focus">
                      <input type="text"
                        className="form-control "
                        name="title"
                        defaultValue={blogValues.title}
                        onChange={blogValuesChange} />
                      <label className="focus-label">
                        Blog Title <span className="text-danger">*</span>
                      </label>
                    </div>

                    <div className="blogs form-focus">
                      <select className="form-select" aria-label="Default select example" defaultValue='yes'>
                        <option >Department</option>
                        {
                          blog.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>{item.label}</option>
                            )
                          })
                        }

                      </select>
                    </div>

                    <div className="blogs form-focus">
                      <label>Blog Type</label>
                      <select className="form-select"
                        name='blog_type'
                        aria-label="Default select example"
                        defaultValue={blogValues.blog_type} onChange={blogValuesChange}>

                        {
                          blogtype.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>{item.label}</option>
                            )
                          })
                        }


                      </select>
                    </div>

                    <div className="form-group form-focus">
                      <input
                        rows={4}
                        type="text"
                        className="form-control bg-grey"
                        defaultValue={blogValues.txt_content}
                        name="txt_content"
                        onChange={blogValuesChange}
                      />
                      <label className="focus-label">
                        Blog Content <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group">
                      <div className="change-photo-btn  bg-grey">
                        <div>
                          <FeatherIcon icon="upload" />
                          <p>Upload File</p>
                        </div>
                        <input type="file"
                          className="upload"
                          name="image"
                          accept='image/png,image/jpeg'
                          defaultValue={blogValues.image}
                          onChange={blogImageChange} />
                      </div>
                    </div>
                    {/* <div id="videoId" style={{ display: "none" }}>
                    <div className="form-group form-focus">
                      <input type="text" className="form-control floating" />
                      <label className="focus-label">
                        Video ID <span className="text-danger">*</span>
                      </label>
                    </div>
                  </div> */}
                    <button type="submit" className="btn btn-primary save-btn">
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* /Add Blog */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>

  )
}

export default AddBlog