import React, { useContext, useState } from 'react'
import FeatherIcon from 'feather-icons-react';
import { Modal } from "react-bootstrap";
import GalleryService from '../../../services/GalleryService';
import { UserContext } from '../../../Context/AllContext';

const AddGallery = ({ modal, setModal }) => {
    const [urlValue, setUrlValue] = useState('')
    const { setIsUpdate } = useContext(UserContext)

    function handleChange(e) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = e.match(regExp);
        return (match && match[2].length === 11)
            ? setUrlValue(`https://www.youtube.com/embed/${match[2]}`)
            : null;
    }

    console.log("url value", urlValue)



    // const videoId = handleChange('http://www.youtube.com/watch?v=zbYf5_S7oJo');
    // const iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/'
    //     + videoId + '" frameborder="0" allowfullscreen></iframe>';

    // console.log('Video ID:', videoId)

    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append('url', urlValue)

        if (urlValue) {
            GalleryService.addGallery(formData).then((res) => {
                console.log(res)
                setModal(false)
                setUrlValue('')
                setIsUpdate(true)
            })
        } else {
            alert("Please add any url")
        }
    }

    return (
        <Modal show={modal} onHide={() =>
            setModal(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>Add Url</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="modal-image-main-cont">
                    <div className="modal-content doctor-profile">
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}
                            >
                                <div className="add-wrap">
                                    <div className="form-group form-focus">
                                        <input type="text"
                                            name='url'
                                            required
                                            className="form-control"
                                            defaultValue={urlValue}
                                            onChange={(e) => handleChange(e.target.value)}
                                        />
                                        <label className="focus-label">Youtube URL<span className="text-danger">*</span></label>
                                    </div>

                                    <div className="submit-section">
                                        <button type="submit" className="btn btn-primary btn-save">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default AddGallery