import React from 'react'
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from '../../../hooks/useAsync';
import BookingService from '../../../services/BookingService';
import DeleteModal from '../Modal/DeleteModal';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';
import Modal from 'react-bootstrap';
import BookingModal from './BookingModal';
import { TbRuler2 } from 'react-icons/tb';
import PackageService from '../../../services/PackageService';

const PackageBookings = () => {

    const [data, setData] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const { setIsUpdate } = useContext(UserContext)
    const [viewModal, setViewModal] = useState(false)
    const [viewId, setViewId] = useState('')
    const [page,setPage] = useState(1)
    
    const packageBookings = useAsync(()=>PackageService.getAllPackageBookings(page),page)
    // useEffect(() => {
    //     if (packageBookings?.data) {
    //         setData(packageBookings?.data)
    //     }
    // }, [packageBookings?.data])


    const columns = [
        {
            name: 'Id',
            cell: (row, index) => index + 1,
            sortable: true,
            width: "50px",
        },

        {
            name: 'Package',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar">
                {/* <Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#patientlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row?.upload_document} alt="User Image" /></Link> */}
            </h2><Link to="#"
                data-bs-target="#Patientlist"
                data-bs-toggle="modal"
            ><span className="user-name">{row.HospitalProcedure_fk?.package__name}</span><br /><span className="d-block text-muted">{row.age}</span>
                </Link></Media.Body></Media>,
            width: "auto",

        },
        {
            name: 'Hospital',
            selector: row => row?.HospitalProcedure_fk?.hospital__name,
            cell: row => <Media ><Media.Body className="d-flex"><div>
                {/* <span className="user-name">{row.Date}</span>
                <br /> */}
                <span className="d-block">{row?.HospitalProcedure_fk?.hospital__name}</span></div></Media.Body></Media>,
            width: "auto",
        },

        // {
        //     name: 'Email',
        //     selector: row => row?.email,
        //     cell: row => <Media ><Media.Body className="d-flex"><div>
        //         {/* <span className="user-name">{row.Date}</span>
        //         <br /> */}
        //         <span className="d-block">{row?.email}</span></div></Media.Body></Media>,
        //     width: "150px",
        // },

        // {
        //     name: 'Appoinment Date',
        //     selector: row => row?.appointment_date,
        //     cell: row => <Media ><Media.Body className="d-flex"><div>
        //         {/* <span className="user-name">{row.Date}</span>
        //         <br /> */}
        //         <span className="d-block">{row?.appointment_date}</span></div></Media.Body></Media>,
        //     width: "150px",
        // },

        {
            name: 'Account status',
            selector: row => row.status,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => viewModalShow(row.id)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link>

                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "300px",
        },
    ];

    const tableData = {
        columns,
        data:packageBookings.data,

    };

    const deleteModalShow = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }

    const deleteHandler = () => {
        PackageService.deletePackageBooking(deleteId).then((res) => {
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    const viewModalShow = (id) => {
        setViewId(id)
        setViewModal(true)
    }
    const handlePageChange = (page) => {
        setPage(page)
    }

    return (
        <div className="card-body p-0">
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable className="datatable table table-borderless hover-table"
                        id="data-table"

                        noHeader

                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        progressPending={packageBookings.loading}
			            paginationServer
			            paginationTotalRows={packageBookings.count}
			            // onChangeRowsPerPage={handlePerRowsChange}
			            onChangePage={handlePageChange}
                    />
                </DataTableExtensions>
            </div>

            {/* delete modal */}
            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
            />

            {/* View modal */}
            <BookingModal viewId={viewId} viewBook={viewModal} setViewBook={setViewModal} isPackage/>
        </div>
    )
}

export default PackageBookings