import React, { useState, useEffect } from 'react'
import DoctorService from '../../../services/DoctorService'
import { blogBanner } from '../imagepath'
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';

const DoctorProfile = ({ view, setView, viewId }) => {
    const [docData, setDocData] = useState([])
    const location = useLocation();
    // const item = location.state

    console.log("item", location)
    useEffect(() => {
        if (viewId) {
            DoctorService.getDoctorById(viewId).then((res) => {
                setDocData(res)
            })
        }
    }, [])

    console.log("docs data", docData)

    return (
        <div className='profile-container'>
            <div className="profile-view-header-div">
                <button className='btn btn-primary btn-sm'
                    onClick={() => setView(false)}>Back</button>
                <h5>DoctorProfile</h5>
            </div>
            <div className="profile-card">
                <div className="profile-card-left-div">
                    <img src={docData?.doctor_img} alt="" />
                </div>
                <div className="profile-card-right-div">
                    {docData?.name && <h5>{docData?.name}</h5>}
                    {docData?.department || docData?.designation && <p>{docData?.department || docData?.designation}</p>}
                    {docData?.address && <p>{docData?.addres}</p>}
                    {docData?.main_education && <p>{docData?.main_education}</p>}
                    {docData?.main_experience && <p>{docData?.main_experience} Years</p>}
                    {docData?.email && <h5>{docData?.email}</h5>}
                    {docData?.phone_num && <h5>{docData?.phone_num}</h5>}
                </div>
            </div>
            <div className="profile-body-div">
                <h5>About</h5>
                {docData?.biography && <p>{parse(`${docData?.biography}`) || docData?.biography}</p>}

                <div className="profile-body-second-div">
                    <h5>Education</h5>
                    {
                        docData?.education?.map((item) => {
                            return (
                                <div className="profile-second-item-div" key={item?.id}>
                                    <h6>{item?.collage_name}</h6>
                                    <p>{item?.course}</p>
                                    <p>{item?.start_data}</p>
                                </div>
                            )
                        })
                    }

                </div>

                <div className="profile-body-third-div">
                    <h5>Experience</h5>
                    {
                        docData?.experience?.map((item) => {
                            return (
                                <div key={item?.id} className="profile-second-item-div">
                                    <h6>{item?.wrked_hosp}</h6>
                                    <p>{item?.designation}</p>
                                    <p>{item?.start_date}</p>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="profile-body-fourth-div">
                    <h5>Awards</h5>
                    {
                        docData?.doctor_awards?.map((item) => {
                            return (
                                <div key={item?.id} className="profile-second-item-div">
                                    <h6>{item?.award_title}</h6>
                                    <p>{item?.award_detail}</p>
                                    <p>{item?.year}</p>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="profile-body-fifth-div">
                    <h5>Specialities</h5>
                    <ul>
                        {
                            docData?.specialisation?.map((item) => {
                                return (
                                    <li key={item?.id}>{item?.name || item?.label}</li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="profile-body-sixth-div">
                    <h5>Services</h5>
                    <ul>
                        {
                            docData?.doctor_services?.map((item) => {
                                return (
                                    <li key={item?.id}>{item?.facility_name || item?.label}</li>
                                )
                            })
                        }
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default DoctorProfile