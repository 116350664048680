import requests from "./httpServices";

const TestimonialService = {
    addDoctorTestimonial(body) {
        return requests.filePost('/doctor/testimonial/', body)
    },
    allDoctorTestimonialList() {
        return requests.get('/doctor/testimonial/')
    },
    getDoctorTestimonialById(id, body) {
        return requests.get(`/doctor/testimonial/${id}/`, body)
    },
    updateDoctorTestimonial(id, body) {
        return requests.filePut(`/doctor/testimonial/${id}/`, body)
    },
    deleteDoctorTestimonial(id) {
        return requests.delete(`/doctor/testimonial/${id}/`)
    },
    addHospitalTestimonial(body) {
        return requests.filePost('/hospital/testimonials/', body)
    },
    allHospitalTestimonial() {
        return requests.get('/hospital/testimonials/')
    },
    getHospitalTestimonialById(id) {
        return requests.get(`/hospital/testimonials/${id}/`)
    },
    updateHospitalTestimonial(id, body) {
        return requests.filePut(`/hospital/testimonials/${id}/`, body)
    },
    deleteHospitalTestimonial(id) {
        return requests.delete(`/hospital/testimonials/${id}/`)
    }
}

export default TestimonialService