import React, { useEffect, useState } from "react";
import useAsync from "../../../hooks/useAsync";
import HospitalService from "../../../services/HospitalService";

const ViewHospitalModal = ({ hospId }) => {
    const [hospitalData, setHospitalData] = useState([])
    useEffect(() => {
        HospitalService.getHospitalById(hospId).then((res) => {
            console.log('view hospital details', res)
            setHospitalData(res)
        })
    }, [])


    return (
        <div className="view-detail-main-div">
            <div className="view-detail-form-body">

                <div className="view-detail-header-div">
                    <div className="view-detail-header-left-div">
                        <img src={hospitalData.hospital_img} alt="" />
                    </div>
                    {/* <div className="view-detail-header-right-div">
                        <h3>{hospitalData.name}</h3>
                        <h6>{hospitalData.address}</h6>
                        <p>{hospitalData.phone_num}</p>
                        <p>{hospitalData.email}</p>
                        <p>{hospitalData.multi_speciality ? "Multi Speciality" : "General Hospital"}</p>
                    </div> */}
                </div>

                <div className="view-detail-body-div">

                    <div className="hosp-field-div">
                        <div className="hosp-field-title-div">
                            <h5>Name :</h5>
                        </div>
                        <div className="hosp-field-content-div">
                            <h6>{hospitalData.name}</h6>
                        </div>
                    </div>

                    <div className="hosp-field-div">
                        <div className="hosp-field-title-div">
                            <h5>Mobile :</h5>
                        </div>
                        <div className="hosp-field-content-div">
                            <h6>{hospitalData.phone_num}</h6>
                        </div>
                    </div>

                    <div className="hosp-field-div">
                        <div className="hosp-field-title-div">
                            <h5>Email :</h5>
                        </div>
                        <div className="hosp-field-content-div">
                            <h6>{hospitalData.email}</h6>
                        </div>
                    </div>

                    <div className="hosp-field-div">
                        <div className="hosp-field-title-div">
                            <h5>Location :</h5>
                        </div>
                        <div className="hosp-field-content-div">
                            <h6>{hospitalData.address}</h6>
                        </div>
                    </div>

                    <div className="hosp-field-div">
                        <div className="hosp-field-title-div">
                            <h5>Description :</h5>
                        </div>
                        <div className="hosp-field-content-div">
                            <h6>{hospitalData.biography}</h6>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ViewHospitalModal