import React from 'react'
import { useContext } from 'react'
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { UserContext } from '../../../../../Context/AllContext'



const ExperienceModal = ({ experienceArray, setExperienceArray }) => {
    const { setIsUpdate } = useContext(UserContext)

    console.log('delbef', experienceArray)
    const deleteHandler = (name) => {
        let del = experienceArray.splice(name, 1)
        console.log('delname', name)
        console.log('deldata', experienceArray)
        console.log('delitem', del)
        setExperienceArray(experienceArray)
        setIsUpdate(true)
    }


    return (
        <div className="edu-list-modal-div">
            {
                experienceArray?.map((item, index) => {
                    return (
                        <div className="modal-list-card-div" key={index}>
                            <div className="modal-list-card-header">
                                {item?.wrked_hosp && <h5>{`${index + 1}`}. {item.wrked_hosp}</h5>}
                                {item?.designation && <h6>{item.designation}</h6>}

                            </div>
                            <div className="edu-modal-details-div">
                                {item?.start_date || item?.end_date && <h6>{item.start_date} - {item.end_date}</h6>}
                            </div>
                            <div className="modal-icon-header-div">
                                {/* <i style={{ color: 'green' }}><AiOutlineEdit /></i> */}
                                <i onClick={() => deleteHandler(index)} style={{ color: 'red' }}><AiFillDelete /></i>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ExperienceModal