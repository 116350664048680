import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import useAsync from "../../../../hooks/useAsync";
import HospitalService from "../../../../services/HospitalService";
import TestimonialService from "../../../../services/TestimonialService";
import SideBarNav from '../../sidebar'
import { UserContext } from "../../../../Context/AllContext";
import { useContext } from "react";
import { useRef } from "react";
import JoditEditor from 'jodit-react';
import Config from "../../../assets/constant/WritingPadConfig";
import Select from 'react-select'

const AddHospitalTestimonial = ({ mainId, setEditForm, setMainId }) => {
    const history = useHistory()
    const inputRef = useRef(null)
    const inputRef1 = useRef(null)
    const inputRef2 = useRef(null)
    const editor = useRef(null)

    const [editData, setEditData] = useState([])
    const { setIsUpdate } = useContext(UserContext)
    const AllHospitals = useAsync(HospitalService.allHospitalList)
    const [hospTestimonialValues, setHospTestimonialValues] = useState({
        user_name: '',
        title: '',
        user_image: null,
        hospital_name: '',
    })

    console.log("main id", mainId)


    useEffect(() => {
        if (mainId) {
            TestimonialService.getHospitalTestimonialById(mainId).then((res) => {
                console.log("response", res.results)
                setHospTestimonialValues({
                    hospital_name: res.results?.hospital_name?.id,
                    title: res?.results?.title,
                    user_image: res?.results?.user_image,
                    user_name: res?.results?.user_name
                })
            })
        }

    }, [mainId])
    console.log("hospital testimonial data", editData)



    const hospValuesChange = (e) => {
        setHospTestimonialValues({ ...hospTestimonialValues, [e.target.name]: e.target.value })
    }

    const hospTestimonialImageChange = (e) => {
        setHospTestimonialValues({ ...hospTestimonialValues, [e.target.name]: e.target.files[0] })
    }


    const resetFileInput = () => {
        inputRef.current.value = null
        inputRef1.current.value = null
        inputRef2.current.value = null
    }

    const getDefaultHosp = (data) => {
        const defaultHosp = data?.filter((current) => current?.id == hospTestimonialValues?.hospital_name)
        const docOption = defaultHosp?.map((opt) => ({ label: opt?.name, value: opt?.id }))
        return docOption
    }

    const hospTestimonialSubmit = (e) => {
        console.log('hosData', hospTestimonialValues)
        e.preventDefault()
        let fd = new FormData()
        if (hospTestimonialValues.user_name != "") {
            fd.append('user_name', hospTestimonialValues.user_name)
        } else {
            alert("Please fill Your Name")
        }
        if (hospTestimonialValues.title != "") {
            fd.append('title', hospTestimonialValues.title)
        } else {
            alert("Please add any Message")
        }
        if (hospTestimonialValues.user_image) {
            fd.append('user_image', hospTestimonialValues.user_image)
        } else {
            alert("Please add any image")
        }
        if (hospTestimonialValues.hospital_name) {
            fd.append('hospital_name', hospTestimonialValues.hospital_name)
        } else {
            alert("Please select any Hospital")
        }
        {
            mainId ?
                TestimonialService.updateHospitalTestimonial(mainId, fd).then((res) => {
                    console.log(res)
                    alert("Updated successfully")
                    setIsUpdate(true)
                    resetFileInput()
                    setHospTestimonialValues({
                        title: ''
                    })
                    history.goBack()
                })
                :
                TestimonialService.addHospitalTestimonial(fd)
                    .then(res => {
                        console.log("hospital testimonial", res)
                        alert('Success')
                        resetFileInput()
                        setHospTestimonialValues({
                            title: ''
                        })
                    })
        }

    }

    return (

        <div className="adm-testi-form-main">
            <div className="adm-testi-form-body">
                <h4>{mainId ? "Edit" : "Add"} Hospital Testimonial</h4>
                {/* <Link className="btn btn-info right-side-btn" to="/testimonial" >View All</Link> */}
                <form action="" onSubmit={hospTestimonialSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Name</label>
                                <input type="text"
                                    className="form-control"
                                    name="user_name"
                                    ref={inputRef}
                                    defaultValue={hospTestimonialValues.user_name}
                                    onChange={hospValuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Content</label>
                                {/* <input type="text"
                                    className="form-control"
                                    name="title"
                                    ref={inputRef1}
                                    defaultValue={mainId ? editData?.results?.title : hospTestimonialValues.title}
                                    onChange={hospValuesChange} /> */}
                                <JoditEditor
                                    ref={editor}
                                    value={hospTestimonialValues.title}
                                    config={Config}
                                    tabIndex={4}
                                    onChange={(e) => setHospTestimonialValues({ ...hospTestimonialValues, title: e })}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Select Hospital</label>
                                {/* <select className="form-control"
                                    name="hospital_name"
                                    defaultValue={hospTestimonialValues.hospital_name}
                                    onChange={relatedHospChange}
                                    ref={inputRef1}>
                                    <option disabled={true} value="">
                                        --Choose Hospital--
                                    </option>
                                    {
                                        AllHospitals.data?.map((hospName) => {
                                            return (
                                                <option key={hospName.id}
                                                    value={hospName.id}
                                                >{hospName.name}</option>
                                            )
                                        })
                                    }
                                </select> */}

                                <Select
                                    options={AllHospitals?.data?.map((hosp) => ({
                                        label: hosp?.name,
                                        value: hosp?.id
                                    }))}
                                    value={getDefaultHosp(AllHospitals?.data)}
                                    ref={inputRef1}
                                    onChange={(event) => setHospTestimonialValues({ ...hospTestimonialValues, hospital_name: event?.id })}
                                />

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Image</label>
                                <input type="file"
                                    className="form-control"
                                    name="user_image"
                                    accept="image/png, image/jpeg"
                                    ref={inputRef2}
                                    onChange={hospTestimonialImageChange} />
                            </div>
                        </div>
                        <div className="adm-testi-btn-div">
                            <button type="submit" className="btn btn-success">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}
export default AddHospitalTestimonial