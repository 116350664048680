import requests from "./httpServices";

const BlogServices = {
    addBlog(body) {
        return requests.filePost('/blogs/blogs/', body)
    },
    allBlog() {
        return requests.get('/blogs/blogs/')
    },
    updateBlog(id, body) {
        return requests.filePut(`/blogs/blogs/${id}/`, body)
    },
    deleteBlog(id, body) {
        return requests.delete(`/blogs/blogs/${id}/`, body)
    },
    getBlogById(id) {
        return requests.get(`/blogs/blogs/${id}/`)
    }
}

export default BlogServices