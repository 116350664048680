import React, { useEffect, useRef } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { UserContext } from '../../../Context/AllContext'
import useAsync from '../../../hooks/useAsync'
import DoctorService from '../../../services/DoctorService'
import FaqService from '../../../services/FaqService'

function AddDoctorFaq({ editId, setFormPage }) {
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const allDoctor = useAsync(DoctorService.allDoctorList)
    const { setIsUpdate } = useContext(UserContext)
    const [docValues, setDocValues] = useState({
        dct_faq_qst: '',
        dct_faq_ans: '',
        dct_faq_name: '',
    })

    useEffect(() => {
        if (editId) {
            FaqService.getDoctorFaqById(editId).then((res) => {
                setDocValues({
                    dct_faq_qst: res.dct_faq_qst,
                    dct_faq_ans: res.dct_faq_ans,
                    dct_faq_name: res.dct_faq_name
                })

            })
        }
    }, [])

    console.log("edit doc data", docValues)

    const resetFileInput = () => {
        ref1.current.value = null;
        ref2.current.value = null;
        ref3.current.value = null;
    };

    const valuesChange = (e) => {
        setDocValues({ ...docValues, [e.target.name]: e.target.value })
    }

    const valuesSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('dct_faq_qst', docValues.dct_faq_qst)
        formData.append('dct_faq_ans', docValues.dct_faq_ans)
        formData.append('dct_faq_name', docValues.dct_faq_name)

        {
            editId ?
                FaqService.updateDoctorFaq(editId, formData).then((res) => {
                    console.log(res)
                    alert("Updated Successfully")
                    setIsUpdate(true)
                    setFormPage("docFaq")
                    resetFileInput()
                })
                :
                FaqService.addDoctorFaq(formData).then((res) => {
                    console.log(res)
                    alert("Added Successfully")
                    resetFileInput()
                    setIsUpdate(true)
                })
        }
    }

    return (
        <div className='adm-add-faq-main'>
            <div className="adm-add-faq-body-div">
                <h4>{editId ? 'Edit' : "Add"} Doctor Faq</h4>
                <form action="" onSubmit={valuesSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Question?</label>
                                <input type="text"
                                    className="form-control"
                                    name='dct_faq_qst'
                                    ref={ref1}
                                    defaultValue={docValues.dct_faq_qst}
                                    onChange={valuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Answer</label>
                                <input type="text"
                                    className="form-control"
                                    name='dct_faq_ans'
                                    ref={ref2}
                                    defaultValue={docValues.dct_faq_ans}
                                    onChange={valuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Doctor</label>
                                <select className='form-control'
                                    name='dct_faq_name'
                                    ref={ref3}
                                    defaultValue={docValues.dct_faq_name}
                                    onChange={valuesChange}>
                                    <option disabled={true} value="">
                                        --Choose Doctor--
                                    </option>
                                    {
                                        allDoctor.data?.map((item) => {
                                            return (
                                                <option key={item?.id} value={item?.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="adm-testi-btn-div">
                            <button type="submit" className="btn btn-success">{editId ? "Edit" : "Add"}</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddDoctorFaq