import React, { useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import FeatherIcon from 'feather-icons-react'
import { Media } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13 } from '../../imagepath';
import useAsync from "../../../../hooks/useAsync";
import TestimonialService from "../../../../services/TestimonialService";
import ViewModal from "../modals/ViewModal";
import { UserContext } from "../../../../Context/AllContext";
import DeleteModal from "../../Modal/DeleteModal";

const DoctorTestimonial = ({ setMainId, setEditForm }) => {

    const { data, error, loading } = useAsync(TestimonialService.allDoctorTestimonialList)
    const { setIsUpdate } = useContext(UserContext)
    const [testimonialId, setTestimonialId] = useState()
    const [viewModalShow, setViewModalShow] = useState(false)
    const [viewName, setViewName] = useState()
    const [viewId, setViewId] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()

    const updateModal = (id, name) => {
        setMainId(id)
        setEditForm("add-doc-testimonial")
    }

    const ListModalShow = (id, name) => {
        setViewModalShow(true)
        setViewName(name)
        setViewId(id)
        setTestimonialId('Doc-Testimonial')
    }

    const deleteModalShow = (id, name) => {
        setDeleteModal(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const deleteHandler = () => {
        TestimonialService.deleteDoctorTestimonial(deleteId).then((res) => {
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    const columns = [
        {
            name: 'Id',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "150px",
        },

        {
            name: 'User Name',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar"><Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#doctorlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row.user_image} alt="" /></Link></h2><NavLink to="#"
                data-bs-target="#doctorlist"
                data-bs-toggle="modal"
            >
            </NavLink><span className="user-name">{row.user_name}</span></Media.Body></Media>,
            width: "250px",

        },

        // {
        //     name: 'User Name',
        //     selector: row => row.hospital,
        //     sortable: true,
        //     width: "250px",
        // },


        {
            name: 'Doctor',
            selector: row => row.doctor_name.name,
            sortable: true,
            width: "250px",
        },

        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                {/* <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => ListModalShow(row.id, row.user_name)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link> */}
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateModal(row.id, row.user_name)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id, row.user_name)}

                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "250px",

        },

    ];

    const tableData = {
        columns,
        data,

    };

    return (
        <div className="card-body p-0" style={{ width: '100%' }}>
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable

                        noHeader

                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* Delete modal */}

            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName} />

            {/* view modal */}
            {/* <Modal show={docModalShow} onHide={() =>
                setDocModalShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>{docName} </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <ViewDoctorModal docId={docId} />
                    </div>
                </Modal.Body>
            </Modal> */}

            {/* View Modal */}
            {/* <ViewModal state={viewModalShow}
                setState={setViewModalShow}
                title={viewName} id={viewId}
                testimonialId={testimonialId} /> */}
        </div>
    )
}

export default DoctorTestimonial