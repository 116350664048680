import requests from "./httpServices";

const HospitalService = {
    addHospital(body) {
        return requests.filePost('/hospital/hospital/', body)
    },
    allHospitalList() {
        return requests.get('/hospital/hospital/')
    },
    getHospitalById(id, body) {
        return requests.get(`/hospital/hospital/${id}/`, body)
    },
    updateHospital(id, body) {
        return requests.filePut(`/hospital/hospital/${id}/`, body)
    },
    deleteHospital(id, body) {
        return requests.delete(`/hospital/hospital/${id}/`, body)
    },
    getHospitalsByPage(page) {
        return requests.get(`/hospital/hospital/?page=${page}`)
    },
    addHospitalBySlug(body) {
        return requests.filePost('/hospital/details/', body)
    },
    addPromotedHospital(body) {
        return requests.post('/hospital/promote/', body)
    },
    getPromotedHospital() {
        return requests.get('/hospital/promote/')
    },
    getPromotedHospById(id) {
        return requests.get(`/hospital/promote/${id}/`)
    },
    updatePromoteHospital(id, body) {
        return requests.filePut(`/hospital/promote/${id}/`, body)
    },
    deletePromoteHospital(id) {
        return requests.delete(`/hospital/promote/${id}/`)
    }

}

export default HospitalService