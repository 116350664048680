import requests from "./httpServices";

const DoctorService = {
    addDoctor(body) {
        return requests.filePost('/doctor/doctor/', body)
    },
    getDoctorById(id) {
        return requests.get(`/doctor/doctor/${id}/`)
    },
    allDoctorList() {
        return requests.get('/doctor/doctor/')
    },
    updateDoctor(id, body) {
        return requests.filePut(`/doctor/doctor/${id}/`, body)
    },
    deleteDoctor(id) {
        return requests.delete(`/doctor/doctor/${id}/`)
    },
    getDoctorsByPage(page) {
        return requests.get(`/doctor/doctor/?page=${page}`)
    },
    addDoctorBySlug(body) {
        return requests.filePost('/doctor/details/', body)
    },
    addPromotedDoctor(body) {
        return requests.post('/doctor/promote/', body)
    },
    getPromotedDoctors() {
        return requests.get('/doctor/promote/')
    },
    getPromotedDocsById(id) {
        return requests.get(`/doctor/promote/${id}/`)
    },
    updatePromoteDoctors(id, body) {
        return requests.filePut(`/doctor/promote/${id}/`, body)
    },
    deletePromoteDoctor(id) {
        return requests.delete(`/doctor/promote/${id}/`)
    },
    addOnlineDoctor(body) {
        return requests.filePost('/doctor/online/', body)
    },
    updateOnlineDoctor(id, body) {
        return requests.filePut(`/doctor/online/${id}/`, body)
    },
    getAllOnlineDoctor() {
        return requests.get('/doctor/online/')
    },
    deleteOnlineDoctor(id) {
        return requests.delete(`/doctor/online/${id}/`)
    }
}

export default DoctorService