import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideBarNav from '../sidebar'
import PackageModal from "./PackageModal";
import PackageTable from "./PackageTable";
import { Modal } from "react-bootstrap";
import AssignMModal from "./AssignMModal";
import PromotePackageModal from "./PromotePackageModal";

const Packages = () => {
    const [packageModal, setPackageModal] = useState(false)
    const [editId, setEditId] = useState()
    const [assgnPackageModal, setAssignPackageModal] = useState(false)
    const [packageId, setPackageId] = useState()
    const [promoteModal, setPromoteModal] = useState(false)

    const modalHandler = () => {
        setPackageModal(true)
    }

    const assignPackageHandler = (id) => {
        console.log("package id in function", id)
        setAssignPackageModal(true)
        setPackageId(id)
    }

    console.log("package id", packageId)

    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="package-header-div">
                        <button onClick={() => setPromoteModal(true)}
                            className='btn btn-primary ml-3 promote-btn' >
                            Promote Package
                        </button>
                        <button type="button"
                            className="btn btn-info"
                            onClick={modalHandler}>Add Package</button>
                    </div>
                    <div className="adm-package-body-div">
                        <PackageTable editId={editId} setEditId={setEditId} setPackageModal={setPackageModal} packageModal={packageModal} />
                    </div>
                </div>
            </div>
            <PackageModal editId={editId} setEditId={setEditId} setPackageModal={setPackageModal}
                packageModal={packageModal} assignPackageHandler={assignPackageHandler} />

            {/* assign package */}
            <Modal
                show={assgnPackageModal} onHide={() =>{
                    setAssignPackageModal(false)
                    setEditId(null)
                }}
            >
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Assign Package </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <AssignMModal setEditId={setEditId} editId={editId} packageId={packageId} setAssignPackageModal={setAssignPackageModal} setPackageId={setPackageId} />
                    </div>
                </Modal.Body>
            </Modal>

            {/* Promote Modal */}

            <Modal show={promoteModal} onHide={() =>
                setPromoteModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Promote Package </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <PromotePackageModal setModal={setPromoteModal} />
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Packages