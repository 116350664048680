import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar11, avatar12, avatar13 } from '../imagepath';
import useAsync from '../../../hooks/useAsync'
import BookingService from '../../../services/BookingService';

const AppointmentsTable = () => {
	const { data } = useAsync(BookingService.allBookings)
	// const data = [
	// 	{
	//         ID:"#2323",
	// 		Patient: "Gobbs Siauw",
	//         age:"Male,40 Years Old",	
	// 		img_url: avatar07,
	//   		Doctor:"Dr.John",
	//         img_url2:avatar05,
	//         Department:"Gyanoclogiest",
	//         Disease:"Allergies & Asthma", 
	//         Consultationtype:"Scheduled Appointment",
	//         call:<i className="me-2"><FeatherIcon icon="video"/> {"Video call"}</i>,
	// 		Date: "26 November 2022",
	// 		time:"12/20/2022",
	//   		Amount:"$300.00",

	// 	},
	// 	{
	// 		ID:"#4546",
	// 		Patient: "Gobbs Siauw",
	//   		age:"Male,40 Years Old",	
	// 		img_url: avatar04,
	// 		Doctor:"Dr.Rayan",
	//         img_url2:avatar05,
	//         Department:"Gyanoclogiest",
	//         Disease:"Allergies & Asthma",
	//         Consultationtype:"Scheduled Appointment",
	//         call:<i className="me-2" ><FeatherIcon icon="video"/> {"Video call"}</i>,
	// 		Date: "26 November 2022",
	// 		time:"12/20/2022",
	//   		Amount:"$300.00",
	// 	},
	// 	{
	// 		ID:"#4546",
	// 		Patient: "Gobbs Siauw",
	//   		age:"Male,25 Years Old",	
	// 		img_url: avatar02,
	// 		Doctor:"Dr.Lester",
	//         img_url2:avatar05,
	//         Department:"Orthopaedics",
	//         Disease:"Tooth pain",
	//         Consultationtype:"Scheduled Appointment",
	//         call:<i className="me-2" ><FeatherIcon icon="message-square"/> {"chat"}</i>,
	// 		Date: "26 November 2022",
	// 		time:"12/20/2022",
	//   		Amount:"$300.00",
	// 	},
	// 	{
	// 		ID:"#4546",
	// 		Patient: "Gobbs Siauw",
	//  		age:"Male,29 Years Old",	
	// 		 img_url:avatar05,
	// 		Doctor:"Dr.Clint",
	//         img_url2:avatar12,
	//         Department:"Neurology",
	//         Disease:"pain on Knee",
	//         Consultationtype:"Scheduled Appointment",
	//         call:<i className="me-2" ><FeatherIcon icon="mic"/>{"Audio call"}</i>,
	// 		Date: "26 November 2022",
	// 		time:"12/20/2022",
	//   		Amount:"$300.00",
	// 	},
	// 	{
	// 		ID:"#8774",
	// 		Patient: "Gobbs Siauw",
	//   		age:"Male,30 Years Old",	
	// 		img_url: avatar13,
	// 		Doctor:"Dr.Monroe",
	//         img_url2:avatar12,
	//         Department:"Hair Specialities",
	//         Disease:"Sleep problem",
	//         Consultationtype:"Scheduled Appointment",
	//         call:<i className="me-2" > <FeatherIcon icon="mic"/>{"Audio call"}</i>,
	// 		Date: "26 November 2022",
	// 		time:"12/20/2022",
	//   		Amount:"$300.00",
	// 	},
	// 	{
	// 		ID:"#8774",
	// 		Patient: "Gobbs Siauw",
	//   		age:"Male,30 Years Old",	
	// 		img_url: avatar11,
	// 		Doctor:"Dr.Linda",
	//         img_url2:avatar06,
	//         Department:"Hair Specialities",
	//         Disease:"Sleep problem",
	//         Consultationtype:"Scheduled Appointment",
	//         call:<i className="me-2" ><FeatherIcon icon="mic"/>{"Audio call"}</i>,
	// 		Date: "26 November 2022",
	// 		time:"12/20/2022",
	//   		Amount:"$300.00",
	// 	},

	// ]
	const columns = [
		{
			name: 'Id',
			selector: row => row.ID,
			sortable: true,
			width: "100px",
		},
		{
			name: 'Patient',
			sortable: true,
			cell: row => <Media ><Media.Body className="d-flex"><Link to="/admin/profile" className="avatar avatar-sm me-2 user-dt" ><img src={row.upload_document} className="avatar avatar-img" /></Link><div className="text-secondary"><span className='user-name'>{row.Patient}</span><br />
				<span className="d-block text-muted">{row.patient_name}</span></div></Media.Body></Media>,
			width: "250px",

		},
		//     {
		// 		name: 'Doctor',			
		// 		sortable: true,
		// 		cell: row => <Media ><Media.Body className="d-flex"><Link to="/admin/profile" className="avatar avatar-sm me-2 user-dt user-name" ><img src={row.img_url2} className="avatar avatar-img" /></Link><div className="text-secondary"><span className='text-muted'>{row.Doctor}</span><br/>
		//   <span className="d-block tab-subtext">{row.Department}</span></div></Media.Body></Media>,
		// 		width:"250px",

		// 	},																					

		{
			name: 'Email',
			selector: row => <Media><span className='disease-name'>{row.email}</span></Media>,
			sortable: true,
			width: "250px",
		},
		// {
		// 	name: 'Consultation Type',
		// 	selector: row => <Media ><Media.Body className="d-flex"><a href="#" onClick={() => onClik()}></a><div className="text-secondary">{row.email}<br/>
		//     <Link to="#" className="d-block text-primary mt-2">
		//                   <span className="d-flex align-items-center">
		//                     {row.call}
		//                   </span>
		//                 </Link></div></Media.Body></Media>,
		//         sortable: true, 
		//          width:"250px",

		// },

		{
			name: 'Date',
			selector: row => row.Date,
			cell: row => <Media ><Media.Body className="d-flex"><div className="text-secondary"><span className='user-name'>{row.appointment_date}</span><br />
				{/* <span className="d-block text-muted">{row.time}</span> */}
			</div></Media.Body></Media>,
			width: "250px",
		},
		{
			name: 'Account status',
			selector: row => row.status,
			sortable: true,
			cell: row => <div className="actions">
				{/* <Link
					// className="text-danger"
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					onClick={() => ListModalShow(row.id)}
					style={{ color: '#219ebc' }}

				>
					<i className='me-1'><FeatherIcon icon="eye" /></i> View
				</Link> */}
				{/* <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    // onClick={() => updateHandler(row.id, row.name)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link> */}
				<Link
					className="text-danger"
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					onClick={() => deleteModalShow(row.id, row.vchr_email)}
				>
					<i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
				</Link>
			</div>,
			width: "300px",
		},

		// {
		// 		name: 'Amount',
		// 		selector: row=>row.Amount,
		// 		sortable: true,
		// 		width:"250px",
		// 	},




	];




	const tableData = {
		columns,
		data,

	};
	return (
		<div className="card-body p-0">
			<div className="table-responsive">
				<DataTableExtensions
					{...tableData}
				>
					<DataTable

						noHeader

						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
					/>
				</DataTableExtensions>
			</div>
		</div>
	)
}

export default AppointmentsTable