import requests from "./httpServices";

const TreatmentService = {
    addTreatment(body) {
        return requests.filePost('/doctor/treatment/', body)
    },
    allTreatments() {
        return requests.get('/doctor/treatment/')
    },
    getTreatmentById(id, body) {
        return requests.get(`/doctor/treatment/${id}/`, body)
    },
    updateTreatment(id, body) {
        return requests.filePut(`/doctor/treatment/${id}/`, body)
    },
    deleteTreatment(id) {
        return requests.delete(`/doctor/treatment/${id}/`)
    }
}
export default TreatmentService