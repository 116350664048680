import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { formatDuration } from 'react-dropzone-uploader'
import { UserContext } from '../../../Context/AllContext'
import useAsync from '../../../hooks/useAsync'
import HospitalService from '../../../services/HospitalService'
import PackageService from '../../../services/PackageService'

function AssignMModal({ packageId, setAssignPackageModal, setPackageId ,editId,setEditId}) {
    // console.log('Assign ',editId)
    const allHospital = useAsync(HospitalService.allHospitalList)
    const { setIsUpdate } = useContext(UserContext)
    const [packageData, setPackageData] = useState()
    const [assignValues, setAssignValues] = useState({
        flt_price: '',
        flt_dis_amt: '',
        fk_hospital: ''
    })
    useEffect(() => {
        if (packageId) {
            PackageService.getPackageById(packageId).then((res) => {
                setPackageData(res.results)
            })
        }
    }, [packageId])
    useEffect(() => {
        if (editId && editId != '') {
            PackageService.getAssignedPackageById(editId).then((res) => {
                // console.log('pck res',res)
                setAssignValues({
                    flt_price:res.results.flt_price,
                    flt_dis_amt:res.results.flt_dis_amt,
                    fk_hospital:res.results.fk_hospital.id,
                })
                // setPackageData(res.results)
            })
        }
    }, [editId])

    console.log("package data", packageData)

    const assignValuesChange = (e) => {
        setAssignValues({ ...assignValues, [e.target.name]: e.target.value })
    }

    const valuesSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('fk_hospital', assignValues.fk_hospital)
        formData.append('flt_price', assignValues.flt_price)
        formData.append('flt_dis_amt', assignValues.flt_dis_amt)
        formData.append('fk_procedure', packageId)
        if(editId && editId != ''){
            PackageService.updateAssignProcedure(editId,formData).then((res) => {
                // console.log("final package data", res)
                alert("Succesfully updated")
                setAssignPackageModal(false)
                setPackageId('')
                setEditId(null)
                setIsUpdate(true)
            })
        }else{
            PackageService.assignProcedure(formData).then((res) => {
                // console.log("final package data", res)
                alert("Succesfully added")
                setAssignPackageModal(false)
                setPackageId('')
                setIsUpdate(true)
            })
        }
    }

    return (
        <div className="add-package-main-div">
            <form action="" onSubmit={valuesSubmit}>
                <div className="row">
                    <h6 className='assign-pro-title'>{packageData?.name}</h6>

                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>Hospital</label>
                            <Multiselect
                                options={allHospital.data}
                                displayValue="name"
                                placeholder='Select Hospital...'
                                onSelect={(event) => setAssignValues({ ...assignValues, fk_hospital: event[0].id })} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>Hospital Price</label>
                            <div className="col-md-12">
                                <div className="form-group ">
                                    <input type="text"
                                        className="form-control"
                                        name="flt_price"
                                        defaultValue={assignValues.flt_price}
                                        onChange={assignValuesChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>Offer Price</label>
                            <div className="col-md-12">
                                <div className="form-group ">
                                    <input type="text"
                                        className="form-control"
                                        name="flt_dis_amt"
                                        defaultValue={assignValues.flt_dis_amt}
                                        onChange={assignValuesChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="adm-testi-btn-div">
                        <button type="submit" className="btn btn-success">Save</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AssignMModal