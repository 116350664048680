import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SidebarNav from "../sidebar";
import { Modal, Button } from 'react-bootstrap';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13, doctor09, sort } from '../imagepath';
import DoctorsTable from './DoctorsTable';
import { AiOutlineFolderAdd } from 'react-icons/ai'
import useAsync from '../../../hooks/useAsync';
import DoctorService from '../../../services/DoctorService';
import PromotDoc from './PromotDoc';
import DoctorProfile from './DoctorProfile';
import { useLocation } from 'react-router-dom';
import OnlineDoc from './OnlineDoc';

const Doctors = () => {
	const { data } = useAsync(DoctorService.allDoctorList)
	const [promoteModal, setPromoteModal] = useState(false)
	const [profileView, setProfileView] = useState(false)
	const [viewId, setViewId] = useState('')
	const [onlineModal, setOnlineModal] = useState(false)

	const location = useLocation();
	const item = location.state

	console.log("item", item?.item)

	useEffect(() => {
		if (item?.item) {
			setProfileView(true)
			setViewId(item.item)
		}
	}, [item?.item])



	return (
		<>
			<SidebarNav />
			{/* Page Wrapper */}
			<div className="page-wrapper">
				<div className="content container-fluid">
					{/* Page Header */}
					<div className="page-header">
						<div className="add-new-btn-div">
							<Link to="/add-doctor">
								<button className='add-lg-btn'>Add New <AiOutlineFolderAdd /></button>
							</Link>

							<button onClick={() => setPromoteModal(true)}
								className='btn btn-primary ml-3 promote-btn' to='/'>
								Promote Doctor
							</button>

							<button onClick={() => setOnlineModal(true)}
								className='btn btn-success ml-3 promote-btn' to='/'>
								Online Doctor
							</button>

						</div>
						<div className="row align-items-center">
							<div className="col-md-12 d-flex justify-content-end">
								<div className="doc-badge me-3">
									Doctors <span className="ms-1">{data.length}</span>
								</div>

							</div>
						</div>
					</div>
					{/* /Page Header */}
					{/* Doctor List */}
					<div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-header">
									<div className="row align-items-center">
										<div className="col">
											<h5 className="card-title">Doctors</h5>
										</div>
										<div className="col-auto d-flex flex-wrap">
											<div className="form-custom me-2">
												<div id="tableSearch" className="dataTables_wrapper" />
											</div>
											<div className="multipleSelection">

											</div>
										</div>
									</div>
								</div>
								{
									profileView ?
										<DoctorProfile viewId={viewId} setView={setProfileView} />
										:
										<DoctorsTable setViewId={setViewId} setView={setProfileView} />
								}

							</div>
							<div id="tablepagination" className="dataTables_wrapper" />
						</div>
					</div>
					{/* /Doctor List */}
				</div>
			</div>

			{/* Promote Modal */}

			<Modal show={promoteModal} onHide={() =>
				setPromoteModal(false)}>
				<Modal.Header closeButton >
					<Modal.Title className='profile-settings-modal-header'>
						<h4>Promote Doctors </h4>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-image-main-cont">
						<PromotDoc setModal={setPromoteModal} />
					</div>
				</Modal.Body>
			</Modal>

			{/* Online Modal */}

			<Modal show={onlineModal} onHide={() =>
				setOnlineModal(false)}>
				<Modal.Header closeButton >
					<Modal.Title className='profile-settings-modal-header'>
						<h4>Online Doctors </h4>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-image-main-cont">
						<OnlineDoc setModal={setOnlineModal} />
					</div>
				</Modal.Body>
			</Modal>
		</>

	)
}

export default Doctors