import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAsync from "../../../../hooks/useAsync";
import PackageService from "../../../../services/PackageService";
import LoadingSpinner from "../../Loading/LoadingSpinner";


const TopPackages = ({ title }) => {

    const allPackages = useAsync(PackageService.getPromotedPackage)
    console.log("all packages", allPackages.data[0]?.procedure_list)
    const [packData, setPackData] = useState([])

    useEffect(() => {
        if (allPackages.data) {
            setPackData(allPackages.data[0]?.procedure_list)
        }
    }, [allPackages?.data])

    console.log("promoted packages", packData)


    return (
        <div className="col-lg-4">
            <Link to="/packages" className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h5 className="card-title">Top {title}</h5>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table doc-table">
                            <tbody>

                                {
                                    allPackages.loading ?
                                        <LoadingSpinner />
                                        :
                                        packData?.length != 0 ? packData.slice(0, 6).map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        <h2 className="table-avatar">
                                                            <Link
                                                                className="avatar-pos avatar-online"
                                                                to="/packages"
                                                            >
                                                                <img
                                                                    className="avatar avatar-img"
                                                                    src={item?.fk_procedure?.offr_image}
                                                                    alt="Packages"
                                                                />
                                                            </Link>
                                                            <Link to="/packages" className="user-name">
                                                                <span className="text-muted">{item?.package__name}</span>{" "}
                                                                <span className="tab-subtext">{item?.hospital__name?.vchr_name}</span>
                                                            </Link>
                                                        </h2>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                            :
                                            <div className="dash-no-data-found-div">
                                                <h4>No Packages Found</h4>
                                            </div>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default TopPackages;
