import axios from 'axios';
import Cookies from 'js-cookie';

const backend_url = 'https://api.alshifahealthcareservices.com/'
// const backend_url = 'http://192.168.1.61:8001/'
//

const customAxios = (contentType = 'application/json') => {

    const instance = axios.create({
        // baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
        baseURL: backend_url,
        timeout: 500000,
        headers: {
            Accept: 'application/json',
            'Content-Type': contentType,
        },
    });
    console.log('base', process.env.REACT_APP_API_BASE_URL)
    // Add a request interceptor
    // instance.interceptors.request.use(function (config) {
    //     // Do something before request is sent
    //     let userInfo;
    //     if (Cookies.get('userInfo')) {
    //         userInfo = JSON.parse(Cookies.get('userInfo'));
    //         // console.log('service',userInfo)
    //     }

    //     return {
    //         ...config,
    //         headers: {
    //             authorization: userInfo ? `Bearer ${userInfo.access_token}` : null,
    //         },
    //     };
    // });
    //
    return instance
}

const responseBody = (response) => response.data;

const requests = {
    get: (url, body, headers) =>
        customAxios().get(url, body, headers).then(responseBody),

    post: (url, body) => customAxios().post(url, body).then(responseBody),

    put: (url, body, headers) =>
        customAxios().put(url, body, headers).then(responseBody),
    filePost: (url, body) => customAxios('multipart/form-data').post(url, body).then(responseBody),

    filePut: (url, body, headers) =>
        customAxios('multipart/form-data').put(url, body, headers).then(responseBody),

    patch: (url, body) => customAxios().patch(url, body).then(responseBody),

    delete: (url) => customAxios().delete(url).then(responseBody),
};

export default requests;