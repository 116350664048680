import React, { useState } from 'react'
import Sidebar from '../sidebar'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import PasswordServices from '../../../services/PasswordService'
import { useHistory } from 'react-router-dom'

const ChangePassword = () => {
    const history = useHistory()
    const [passwordType1, setPasswordType1] = useState(false)
    const [passwordType2, setPasswordType2] = useState(false)
    const [passwordType3, setPasswordType3] = useState(false)

    const [values, setValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const handleChangeInput = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handlePasswordCheck = () => {
        if (values?.oldPassword === values?.newPassword) {
            alert("Old password and new password will not be same")
        }
        if (values?.newPassword !== "" && values?.confirmPassword !== "") {
            if (values.newPassword != values.confirmPassword) {
                alert("Password must be same as New Password")
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('old_password', values?.oldPassword)
        formData.append('new_password', values?.newPassword)

        PasswordServices.changePassword(formData).then((res) => {
            console.log(res)
            alert("password changed Successfully")
            setValues({
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            })
            history.push('/login')
        })
            .catch((err) => {
                alert("Something went Wrong")
            })
    }

    return (
        <>
            <Sidebar />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className='change-pass-container'>
                        <div className="change-pass-body">

                            <div className="col-md-7 col-lg-8 col-xl-9" style={{ height: "100%" }}>
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} >
                                            <div className="form-group">
                                                <div className='change-password-label-div'>
                                                    <label>Old Password</label>
                                                    <i onClick={() => setPasswordType1((prev) => !prev)} className='mr-2'>{passwordType1 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />} </i>
                                                </div>
                                                <input
                                                    type={!passwordType1 ? "password" : "text"}
                                                    className="form-control"
                                                    name='oldPassword'
                                                    value={values.oldPassword}
                                                    onBlur={handlePasswordCheck}
                                                    onChange={handleChangeInput}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <div className='change-password-label-div'>
                                                    <label>New Password</label>
                                                    <i onClick={() => setPasswordType2((prev) => !prev)} className='mr-2' >{passwordType2 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}</i>
                                                </div>
                                                <input
                                                    type={!passwordType2 ? "password" : "text"}
                                                    className="form-control"
                                                    name='newPassword'
                                                    value={values.newPassword}
                                                    onBlur={handlePasswordCheck}
                                                    onChange={handleChangeInput}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <div className='change-password-label-div'>
                                                    <label>Old Password</label>
                                                    <i onClick={() => setPasswordType3((prev) => !prev)} className='mr-2'>{passwordType3 ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}</i>
                                                </div>
                                                <input
                                                    type={!passwordType3 ? "password" : "text"}
                                                    className="form-control"
                                                    name='confirmPassword'
                                                    value={values.confirmPassword}
                                                    onBlur={handlePasswordCheck}
                                                    onChange={handleChangeInput}
                                                />
                                            </div>

                                            <div className="submit-section">
                                                <button type="submit" className="btn btn-primary btn-sm">Save Changes</button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword