import React from 'react'
import { Link } from 'react-router-dom'
import { Doc1, Doc2, Doc3, Doc4, Doc5 } from '../../img'
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'

const docData = [
    {
        id: 9794,
        name: 'Ram',
        speciality: 'Physician',
        img: Doc1
    },
    {
        id: 7348,
        name: 'David',
        speciality: 'Nephrologists',
        img: Doc2
    },
    {
        id: 68141,
        name: "john david",
        speciality: 'Cardiologists',
        img: Doc3
    },
    {
        id: 2452,
        name: "Joseph King",
        speciality: 'Dermatologists',
        img: Doc4
    },
    {
        id: 8275,
        name: "Raj Kumar",
        speciality: 'Hematologists',
        img: Doc5
    },
]

const DoctorsListModal = () => {
    return (
        <div className="doc-list-modal-main">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Designation</th>
                        <th scope="col">Image</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>{item.speciality}</td>
                                    <td className='modal-img-div'>
                                        < img className='modal-img' src={item.img} alt="" />
                                    </td>
                                    <td style={{ color: 'green' }}><AiOutlineEdit /></td>
                                    <td style={{ color: 'red' }}><AiFillDelete /></td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    )

}

export default DoctorsListModal