import React from 'react'
import { useState } from 'react'
import SideBarNav from '../sidebar'
import DoctorBookings from './DoctorBookings'
import HospitalBookings from './HospitalBookings'
import PackageBookings from './PackageBookings'

const BookingPage = () => {

    const [isTable, setIsTable] = useState("doctor")

    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="adm-enq-main-div">
                    <div className="container-fluid">
                        <div className="booking-btns">
                            <button className='btn btn-info'
                                value="doctor"
                                onClick={(e) => setIsTable(e.target.value)}
                                disabled={isTable == "doctor" ? true : false}>Doctor Bookings</button>
                            <button className='btn btn-info ml-2'
                                value="hospital"
                                onClick={(e) => setIsTable(e.target.value)}
                                disabled={isTable == "hospital" ? true : false}>Hospital Bookings</button>
                            <button className='btn btn-info ml-2'
                                value="package"
                                onClick={(e) => setIsTable(e.target.value)}
                                disabled={isTable == "package" ? true : false}>Package Bookings</button>
                        </div>
                        {
                            isTable == "doctor" ?
                                <DoctorBookings />
                                :
                                isTable == "hospital" ?
                                    <HospitalBookings />
                                    :
                                    <PackageBookings />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingPage