import React from 'react'
import { Link } from 'react-router-dom'
import { FaUpload } from 'react-icons/fa'
// import sidebar from '../sidebar'
import Sidebar from '../sidebar'

const AddNewHospitalPage = () => {
    return (
        <>
            <Sidebar />
            <div className="add-new-page-body">

                <div className="add-new-main-sec">
                    <div className="add-new-header-div mb-3">
                        <h4>Add new Hospital</h4>
                    </div>
                    <form action="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>
                                        Hospital Name <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        Email <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input type="email" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        Mobile<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input type="number" className="form-control" />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>
                                        Describe your Medical condition <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <textarea
                                        className="form-control enq-text-area"
                                        defaultValue={"\n\t\t\t\t\t\t\t\t\t\t\t"}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group date-input-div">
                                    <label>
                                        Appoinment Date <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input type="date" />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group upload-input-div">
                                    <label >
                                        Speciality<span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input type="number" className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="enq-note-div">
                            <p><span><input type="checkbox" /></span>NOTE: With this information I as a user give Al Shifa the permission to access my healthcare related data and
                                information to help me get the expert opinion. Read our privacy policy for more information.
                            </p>
                        </div>
                        <div className='enq-bottom-main-cont'>
                            <div className='enq-bottom-div'>
                                <button type="file" className="blue-btn enq-btns"><FaUpload /> Upload Medical Reports</button>
                                <button
                                    // type="button"
                                    className="blue-btn enq-btns enq-btn"
                                >Submit</button>
                                <button
                                    type="button"
                                    className="red-btn enq-btns enq-btn"
                                >Cancel</button>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddNewHospitalPage