import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import useAsync from '../../../hooks/useAsync'
import DoctorService from '../../../services/DoctorService'
import { AiFillDelete } from 'react-icons/ai'

const OnlineDoc = ({ setModal }) => {
    const ref1 = useRef(null)
    const allPromotedDocs = useAsync(DoctorService.getAllOnlineDoctor)
    const allDoctors = useAsync(DoctorService.allDoctorList)

    const [selectedDoc, setSelectedDoc] = useState([])
    const [promotedDocList, setPromoterDocList] = useState([])
    const [filteredDocList, setFilteredDocList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState('')
    const [promoteDocCurrent, setPromoteDocCurrent] = useState([])
    const [deleteStatus, setDeleteStatus] = useState(false)

    useEffect(() => {
        if (allPromotedDocs?.data) {
            setPromoterDocList(allPromotedDocs?.data)
        }
        if (allDoctors.data) {
            setFilteredDocList(allDoctors?.data?.filter(({ id }) => !promotedDocList[0]?.doctors?.includes(id)))
        }
        if (allPromotedDocs?.data?.length != 0) {
            setIsEdit(true)
            setEditId(promotedDocList[0]?.id)
            setSelectedDoc(promotedDocList[0]?.doctors)
        } else {
            setIsEdit(false)
        }
        if (promotedDocList) {
            setPromoteDocCurrent(promotedDocList[0]?.doctor_list)
        }
    }, [allPromotedDocs?.data, allDoctors?.data])

    console.log("length", promoteDocCurrent?.length)


    // console.log("selected doc", selectedDoc)
    // console.log("promoted doc list,", promotedDocList[0]?.doctor_list)
    // console.log("filtered doc list", filteredDocList)
    // console.log("is EDIT", isEdit)
    // console.log("edit idd", editId)

    const handleChange = (event) => {
        console.log(event)
        // setSelectedDoc([...selectedDoc, id])
        event?.map((item) => {
            if (selectedDoc) {
                setSelectedDoc([...selectedDoc, item?.value])
            } else {
                setSelectedDoc([item?.value])
            }
        })
    }

    const resetValue = () => {
        ref1.current.clearValue()
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData()
        // formData.append('doctors', JSON.stringify(selectedDoc))
        // console.log("after submit", JSON.stringify(selectedDoc))
        selectedDoc.forEach(item => { formData.append('doctors', item) })

        {
            isEdit ?
                DoctorService.updateOnlineDoctor(editId, formData).then((res) => {
                    console.log(res)
                    resetValue()
                    setModal(false)
                })
                :

                DoctorService.addOnlineDoctor(formData).then((res) => {
                    console.log(res)
                    resetValue()
                    setModal(false)
                })
        }
    }

    const deleteHandler = (id) => {
        setDeleteStatus(true)
        console.log("doc current id", id)
        setSelectedDoc(selectedDoc?.filter((current) => current != id))
        setPromoteDocCurrent(promoteDocCurrent?.filter((current1) => current1?.id != id))
    }

    console.log("selected doc", selectedDoc)

    return (
        <div className='promote-main-div'>
            <div className="col-md-12">
                <div className="form-group">
                    <label className='form-title'>
                        Select Doctors
                    </label>
                    <Select
                        options={filteredDocList?.map((item) => ({
                            label: item.name,
                            value: item.id
                        }))}
                        hideSelectedOptions
                        isMulti={true}
                        ref={ref1}
                        onChange={(event) => handleChange(event)}
                    />
                </div>
            </div>

            <div className="promoted-add-btn-div">
                <button type='submit'
                    className='btn btn-primary promote-btn btn-sm'
                    onClick={handleSubmit}>
                    Add
                </button>
            </div>

            <div className="col-md-12">
                <div className="promote-body-div">
                    <h3>Online Doctors List</h3>
                    <div className="promoted-items-list">
                        {
                            promoteDocCurrent?.map((item) => {
                                return (

                                    <div className="promoted-item-card mb-3" key={item?.id}>
                                        <h6 className="promoted-item-name">
                                            {item?.name}
                                        </h6>
                                        <i data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete"
                                            onClick={() => deleteHandler(item?.id)}>
                                            <AiFillDelete />
                                        </i>
                                    </div>
                                )
                            })
                        }

                    </div>
                    {
                        deleteStatus &&

                        <div className="delete-promot-div">
                            <button
                                onClick={handleSubmit}
                                className="btn btn-primary promote-btn btn-sm">Save changes</button>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default OnlineDoc