import React from 'react'
import { useEffect, useState } from 'react'
import useAsync from '../../../hooks/useAsync'
import BannerService from '../../../services/BannerServices'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'

const BannerTable = ({ setSliderEditId, setSliderModal, setBannerValue, setBannerModal }) => {
    const { data, loading } = useAsync(BannerService.getBanner)
    console.log('ban',data)
    const [banners, setBanners] = useState([])
    const { setIsUpdate } = useContext(UserContext)

    const slider = useAsync(BannerService.getHomeSliders)

    useEffect(() => {
        if (data) {
            setBanners(data[0])
        }

    }, [data])

    const updateModal = (id) => {
        setSliderEditId(id)
        setSliderModal(true)
    }

    const deleteHandler = (id) => {
        BannerService.deleteHomeSlider(id).then((res) => {
            alert("Deleted Successfully")
            setIsUpdate(true)
        })
    }

    const updateBanner = () => {
        setBannerValue(banners)
        setBannerModal(true)
    }
    const deleteBanner = (id,key) => {
        BannerService.deleteBanner(id,key).then((res)=>{
            alert("Deleted Successfully")
            setIsUpdate(true)
        })
    }

    console.log("sliders", slider.data)

    return (
        <div className='banner-table-main'>
            <div className="banner-list-body-div">

                <div className="banner-table-list-item">
                    <div className="banner-table-header-div">
                        <h5>Home Sliders</h5>
                    </div>
                    <div className="banner-table-img-div">
                        <div className="banner-table-home-slider">
                            {
                                slider.data?.map((item) => {
                                    return (
                                        <div key={item?.id} className="banner-table-home-image-div">
                                            <img src={item?.homepage_banner} alt="" />
                                            <div className="slider-btns-div">
                                                <button className='btn btn-success btn-sm'
                                                    onClick={() => updateModal(item?.id)}>
                                                    <AiFillEdit /> Edit</button>
                                                <button className='btn btn-danger btn-sm'
                                                    onClick={() => deleteHandler(item?.id)}>
                                                    <AiFillDelete />Delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="banner-table-list-item">
                    <div className="banner-table-header-div">
                        <h5>Package Banner</h5>
                    </div>
                    <div className="banner-table-img-div">
                        <img src={banners?.package_banner} alt="" className='banner-img' />
                    </div>
                    <div className="banner-btns-div">
                        <button className='btn btn-success btn-sm'
                            onClick={() => updateBanner(banners)}>
                            <AiFillEdit /> Edit</button>
                        <button className='btn btn-danger btn-sm'
                            onClick={() => deleteBanner(2,'package_banner')}>
                            <AiFillDelete />Delete</button>
                    </div>
                </div>

                <div className="banner-table-list-item">
                    <div className="banner-table-header-div">
                        <h5>Treatment Banner</h5>
                    </div>
                    <div className="banner-table-img-div">
                        <img src={banners?.treatment_banner} alt="" className='banner-img'/>
                    </div>
                    <div className="banner-btns-div">
                        <button className='btn btn-success btn-sm'
                            onClick={() => updateBanner()}>
                            <AiFillEdit /> Edit</button>
                        <button className='btn btn-danger btn-sm'
                            onClick={() => deleteBanner(2,'treatment_banner')}>
                            <AiFillDelete />Delete</button>
                    </div>
                </div>

                <div className="banner-table-list-item">
                    <div className="banner-table-header-div">
                        <h5>Blog Banner</h5>
                    </div>
                    <div className="banner-table-img-div">
                        <img src={banners?.blog_banner} alt="" className='banner-img'/>
                    </div>
                    <div className="banner-btns-div">
                        <button className='btn btn-success btn-sm'
                            onClick={() => updateBanner()}>
                            <AiFillEdit /> Edit</button>
                        <button className='btn btn-danger btn-sm'
                            onClick={() => deleteBanner(2,'blog_banner')}>
                            <AiFillDelete />Delete</button>
                    </div>
                </div>

                <div className="banner-table-list-item">
                    <div className="banner-table-header-div">
                        <h5>Add Hospital Banner</h5>
                    </div>
                    <div className="banner-table-img-div">
                        <img src={banners?.add_hospital_banner} alt="" className='banner-img'/>
                    </div>
                    <div className="banner-btns-div">
                        <button className='btn btn-success btn-sm'
                            onClick={() => updateBanner()}>
                            <AiFillEdit /> Edit</button>
                        <button className='btn btn-danger btn-sm'
                            onClick={() => deleteBanner(2,'add_hospital_banner')}>
                            <AiFillDelete />Delete</button>
                    </div>
                </div>

                <div className="banner-table-list-item">
                    <div className="banner-table-header-div">
                        <h5>Consult Online Banner</h5>
                    </div>
                    <div className="banner-table-img-div">
                        <img src={banners?.consult_online_banner} alt="" className='banner-img'/>
                    </div>
                    <div className="banner-btns-div">
                        <button className='btn btn-success btn-sm'
                            onClick={() => updateBanner()}>
                            <AiFillEdit /> Edit</button>
                        <button className='btn btn-danger btn-sm'
                            onClick={() => deleteBanner(2, 'consult_online_banner')}>
                            <AiFillDelete />Delete</button>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default BannerTable