import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import {
	DocImg01, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13,
	DocImg02, DocImg03, DocImg04, DocImg05
} from '../imagepath';
import useAsync from '../../../hooks/useAsync';
import DoctorService from '../../../services/DoctorService';
import { UserContext } from '../../../Context/AllContext';
import DeleteModal from '../Modal/DeleteModal';
import ViewDoctorModal from './ViewDoctorModal';
import { Modal } from 'react-bootstrap';
import DoctorFormPage from '../addNewPage/resultpages/DoctorFormPage';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'


const DoctorsTable = ({ view, setView, setViewId }) => {
	const allDoctors = useAsync(() => DoctorService.getDoctorsByPage(pageNo))
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [deleteItemId, setDeleteItemId] = useState()
	const [deleteItemName, setDeleteItemName] = useState()
	const { setIsUpdate } = useContext(UserContext)
	const [docModalShow, setDocModalShow] = useState(false)
	const [docId, setDocId] = useState()
	const [docName, setDocName] = useState()
	const [editId, setEditId] = useState()
	const [editModal, setEditModal] = useState(false)
	const [data, setData] = useState()
	const [pageNo, setPageNo] = useState(1)

	useEffect(() => {
		if (allDoctors) {
			setData(allDoctors.data)
		}
	}, [allDoctors.data])


	const deleteModalShow = (id, name) => {
		setShowDeleteModal(true)
		setDeleteItemId(id)
		setDeleteItemName(name)
	}

	const deleteItemHandler = () => {
		DoctorService.deleteDoctor(deleteItemId).then((res) => {
			// alert(`${res.name} Deleted successfully`)
			setIsUpdate(true)
			setShowDeleteModal(false)
		})
	}

	const viewHandler = (id) => {
		setViewId(id)
		setView(true)
	}

	const updateHandler = (id) => {
		setEditId(id)
		setEditModal(true)
	}

	const pageUpHandler = () => {
		setPageNo(pageNo + 1)
		DoctorService.getDoctorsByPage(pageNo + 1).then((res) => {
			setData(res.results)
		})
	}

	const pageDownHandler = () => {
		setPageNo(pageNo - 1)
		DoctorService.getDoctorsByPage(pageNo - 1).then((res) => {
			setData(res.results)
		})
	}



	const columns = [
		{
			name: 'Id',
			// selector: row => row.id,
			cell: (row, index) => index + 1,
			sortable: true,
			width: "100px",
		},

		{
			name: 'Doctor',
			sortable: true,
			cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar"><Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#doctorlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row.doctor_img} alt="" /></Link></h2><Link to="#"
				data-bs-target="#doctorlist"
				data-bs-toggle="modal"
			>
			</Link><span className="user-name">{row.name}</span></Media.Body></Media>,
			width: "250px",

		},

		{
			name: 'Specialities',
			selector: row => row?.department ? row?.department : row?.designation,
			sortable: true,
			width: "250px",
		},


		// {
		// 	name: 'Address',
		// 	selector: row => row.address,
		// 	sortable: true,
		// 	width: "250px",
		// },

		{
			name: 'Action',
			selector: row => row.action,
			sortable: true,
			cell: row => <div className="actions">
				<Link
					// className="text-danger"
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					onClick={() => viewHandler(row?.id)}
					style={{ color: '#219ebc' }}

				>
					<i className='me-1'><FeatherIcon icon="eye" /></i> View
				</Link>
				<Link
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#editModal"
					style={{ color: 'green' }}
					onClick={() => updateHandler(row.id)}
				>
					<i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
				</Link>
				<Link
					className="text-danger"
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					onClick={() => deleteModalShow(row.id, row.name)}

				>
					<i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
				</Link>
			</div>,
			width: "250px",

		},

	];





	const tableData = {
		columns,
		data,

	};
	return (
		<>
			<div className="card-body p-0">
				<div className="table-responsive">
					<DataTableExtensions
						{...tableData}
					>
						<DataTable

							noHeader

							defaultSortField="id"
							defaultSortAsc={false}
							pagination
							highlightOnHover
						/>
					</DataTableExtensions>
				</div>

				{/* Delete modal */}

				<DeleteModal deleteModal={showDeleteModal}
					setDeleteModal={setShowDeleteModal}
					deleteHandler={deleteItemHandler}
					deleteName={deleteItemName} />

				{/* view modal */}
				<Modal show={docModalShow} onHide={() =>
					setDocModalShow(false)}>
					<Modal.Header closeButton >
						<Modal.Title className='profile-settings-modal-header'>
							<h4>{docName} </h4>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-image-main-cont">
							<ViewDoctorModal docId={docId} />
						</div>
					</Modal.Body>
				</Modal>

				{/* Edit modal */}
				<Modal show={editModal} onHide={() =>
					setEditModal(false)}>
					<Modal.Header closeButton >
						<Modal.Title className='profile-settings-modal-header'>
							<h4>{docName} </h4>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-image-main-cont">
							<DoctorFormPage editId={editId} setEditModal={setEditModal} />
						</div>
					</Modal.Body>
				</Modal>
			</div>

			<div className="pagination-hosp-btns-div">
				{
					pageNo > 1 &&
					<button type="button" className='btn btn-info pagination-left-btn'
						onClick={() => pageDownHandler()}
					>
						<i className='pagination-icon'><FiChevronLeft /></i>
						Back
					</button>
				}

				{
					data?.length == 15 &&
					<button type="button" className="btn btn-info pagination-right-btn"
						onClick={() => pageUpHandler()}
					>
						Next
						<i className='pagination-icon'><FiChevronRight /></i>
					</button>
				}


			</div>
		</>
	)
}

export default DoctorsTable;