import requests from "./httpServices";

const GalleryService = {
    addGallery(body) {
        return requests.filePost('/user_login/stories/', body)
    },
    allGallery() {
        return requests.get('/user_login/stories/')
    },
    updateGallery(id, body) {
        return requests.filePut(`/user_login/stories/${id}/`, body)
    },
    deleteGallery(id, body) {
        return requests.delete(`/user_login/stories/${id}/`, body)
    },
    getGalleryById(id) {
        return requests.get(`/user_login/stories/${id}/`)
    }
}

export default GalleryService