import React, { useState } from 'react'
import FeatherIcon from 'feather-icons-react';
import BannerService from '../../../services/BannerServices';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';
import { useEffect } from 'react';

const AddSliders = ({ setModal, sliderEditId, setSliderEditId }) => {
    const [sliders, SetSliders] = useState([])
    const [blob, setBlob] = useState(null)
    const { setIsUpdate } = useContext(UserContext)

    useEffect(() => {
        if (sliderEditId) {
            BannerService.getHomeSliderById(sliderEditId).then((res) => {
                console.log("edit slider", res)
                SetSliders([...sliders, res])
            })
        }
    }, [sliderEditId])


    const handleChange = (e) => {
        let queryImage = e.target.files[0]
        // console.log("query image", queryImage)
        SetSliders([...sliders, queryImage])
    }

    const editChange = (e) => {
        SetSliders([e.target.files[0]])
    }

    console.log("slider", sliders)

    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData()

        for (const image of sliders) {
            // console.log("image after submit", image)
            formData.append('homepage_banner', image)
        }

        sliderEditId ?
            BannerService.updateHomeSlider(sliderEditId, formData).then((res) => {
                console.log(res)
                setIsUpdate(true)
                setModal(false)
            })
            :

            BannerService.addHomeSliders(formData).then((res) => {
                console.log(res)
                setIsUpdate(true)
                setModal(false)
            })
    }

    return (
        <div className='add-banner-main'>

            <div className="add-banner-body">

                <div className="add-banner-list-body">
                    <div className="add-banner-header-div">
                        <h5>Home Sliders</h5>
                    </div>
                    <div className="add-banner-img-input">
                        <div className="col-md-6">
                            <div className="change-photo-btn">
                                <div><i className=''><FeatherIcon icon="upload" /></i>
                                    <p>Upload File</p></div>
                                <input type="file"
                                    className="upload"
                                    style={{ height: '100%', width: '100%' }}
                                    name='sliders'
                                    accept="image/png, image/jpeg"
                                    defaultValue={sliders}
                                    onChange={sliderEditId ? editChange : handleChange}
                                />
                            </div>

                        </div>
                        {
                            sliderEditId && sliders[0]?.homepage_banner ?
                                <div className="home-sliders-img-div">
                                    <img src={sliders[0]?.homepage_banner} alt="..." />
                                </div>
                                :
                                <div className="home-sliders-img-div">
                                    {(sliders || []).map((url, index) => (
                                        <img key={index} src={url ? URL.createObjectURL(url) : null} alt="..." />

                                    ))}
                                </div>
                        }

                    </div>
                </div>

                <div className="submit-section">
                    <button type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-save mt-5">
                        Add
                    </button>
                </div>

            </div>

        </div >
    )
}

export default AddSliders