import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import DataTable from 'react-data-table-component';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from '../../../hooks/useAsync'
import { UserContext } from '../../../Context/AllContext';
import DeleteModal from '../Modal/DeleteModal';
import DepartmentService from '../../../services/DepartmentService';
import FormDepartment from './FormDepartment';
import { useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useRef } from 'react';


function DepartmentTable({ mainId, setMainId }) {
    const allDepartments = useAsync(DepartmentService.allDepartment)
    const { isUpdate, setIsUpdate } = useContext(UserContext)
    const [modalShow, setModalShow] = useState(false)
    const [editName, setEditName] = useState()
    const [deleteItemId, setDeleteItemId] = useState()
    const [deleteItemName, setDeleteItemName] = useState()
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [data, setData] = useState()

    useEffect(() => {
        if (allDepartments) {
            setData(allDepartments.data)
        }
    }, [allDepartments.data])


    const modalHandler = () => {
        setMainId("")
        setModalShow(true)
    }
    const updateDep = (id) => {
        setMainId(id)
        setModalShow(true)
        // setEditName(name)
    }

    const deleteModalShowHandler = (id, name) => {
        setDeleteModalShow(true)
        setDeleteItemId(id)
        setDeleteItemName(name)
    }

    const deleteItemHandler = () => {
        DepartmentService.deleteDepartment(deleteItemId).then((res) => {
            setIsUpdate(true)
            setDeleteModalShow(false)
        })
    }

    const columns = [
        {
            name: 'SId',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Department',
            sortable: true,
            grow: 2,
            cell: row => <Media ><Media.Body className="d-flex"><Link to="#" className="spl-img" >
                <img src={row?.department_image} className="img-fluid" />
            </Link><span>{row?.vchr_name}</span>
            </Media.Body></Media>,
            width: "450px",

        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateDep(row.id)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShowHandler(row.id, row.vchr_name)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "250px",

        },
    ];

    const tableData = {
        columns,
        data,
    };

    return (
        <div className="card-body p-0">
            <div className="page-header">
                <div className="row align-items-center">
                    <div className="col-md-12 d-flex justify-content-end">
                        <div className="doc-badge me-3">
                            Departments <span className="ms-1">{allDepartments.data.length}</span>
                        </div>
                        <button
                            className="btn btn-primary btn-add"
                            onClick={modalHandler}
                        >
                            <i className='me-1'><FeatherIcon icon="plus-square" /></i>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className="adm-dep-header-div">
                <button type="button"
                    className="btn btn-info"
                    onClick={modalHandler}
                >Add</button>
            </div> */}
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* <!-- Modal --> */}
            <FormDepartment
                formModal={modalShow}
                setFormModal={setModalShow}
                title="Facility"
                mainId={mainId}
                editName={editName}
            />

            {/* Delete modal */}
            <DeleteModal deleteModal={deleteModalShow}
                setDeleteModal={setDeleteModalShow}
                deleteHandler={deleteItemHandler}
                deleteName={deleteItemName} />
        </div>
    )
}

export default DepartmentTable