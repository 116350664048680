import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import useAsync from '../../../hooks/useAsync'
import HospitalService from '../../../services/HospitalService'
import { AiFillDelete } from 'react-icons/ai'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'
import PackageService from '../../../services/PackageService'

const PromotePackageModal = ({ setModal }) => {
    const ref1 = useRef(null)
    const allPromotedPackage = useAsync(PackageService.getPromotedPackage)
    const allPackages = useAsync(PackageService.allAssignedPackage)

    const [filteredPackageList, setFilteredPackageList] = useState([])
    const [promotedPackList, setPromotedPackList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState('')
    const [selectedPack, setSelectedPack] = useState([])
    const [promotedPackCurrent, setPromotedPackCurrent] = useState([])
    const [deleteStatus, setDeleteStatus] = useState(false)

    useEffect(() => {
        if (allPromotedPackage?.data) {
            setPromotedPackList(allPromotedPackage?.data)
        }
        console.log("promotedpacklist in useEffect", promotedPackList)
        if (allPackages?.data) {
            setFilteredPackageList(allPackages?.data?.filter(({ id }) => !allPromotedPackage?.data[0]?.procedures?.includes(id)))
        }
        if (allPromotedPackage?.data?.length != 0) {
            setIsEdit(true)
            setEditId(allPromotedPackage?.data[0]?.id)
            if (allPromotedPackage?.data != []) {
                setSelectedPack(allPromotedPackage?.data[0]?.procedures)
            } else {
                setSelectedPack([])
            }
        } else {
            setIsEdit(false)
        }
        if (allPromotedPackage?.data) {
            setPromotedPackCurrent(allPromotedPackage?.data[0]?.procedure_list)
        }
    }, [allPromotedPackage?.data, allPackages?.data])

    // console.log("all promoted package", allPromotedPackage)
    // console.log("promotedpacklist", promotedPackList)
    // console.log("filtered pack", filteredPackageList)
    // console.log('isEdit', isEdit)
    // console.log("editId", editId)
    // console.log("selectedPack", selectedPack)

    const resetValue = () => {
        // ref1.current.clearValue()
    }

    const handleChange = (event) => {
        event?.map((item) => {
            if (selectedPack) {
                setSelectedPack([...selectedPack, item?.value])
            } else {
                setSelectedPack([item?.value])
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault()

        let formData = new FormData()
        if (selectedPack != []) {
            selectedPack.forEach(item => { formData.append('procedures', item) })
        }

        {
            isEdit ?
                PackageService.updatePromotePackage(editId, formData).then((res) => {
                    console.log(res)
                    resetValue()
                    setModal(false)
                })
                :
                PackageService.addPromotePackage(formData).then((res) => {
                    console.log(res)
                    resetValue()
                    setModal(false)
                })
        }
    }

    const deleteIdHandler = () => {
        PackageService.deletePromotePackage(editId).then((res) => {
            console.log(res)
            resetValue()
            setModal(false)
        })
    }

    const deleteHandler = (id) => {
        setDeleteStatus(true)
        setSelectedPack(selectedPack?.filter((current) => current != id))
        setPromotedPackCurrent(promotedPackCurrent?.filter((current1) => current1?.id != id))
    }

    return (
        <div className='promote-main-div'>
            <div className="col-md-12">
                <div className="form-group">
                    <label className='form-title'>
                        Select Package
                    </label>
                    <Select
                        options={filteredPackageList?.map((item) => ({
                            label: item.fk_procedure.name,
                            value: item.id
                        }))}
                        hideSelectedOptions
                        isMulti={true}
                        ref={ref1}
                        onChange={(event) => handleChange(event)}
                    />
                </div>
            </div>

            <div className="promoted-add-btn-div">
                {
                    filteredPackageList?.length != 0 &&

                    <button type='submit'
                        className='btn btn-primary promote-btn btn-sm'
                        onClick={handleSubmit}>
                        Add
                    </button>
                }
            </div>

            <div className="col-md-12">
                <div className="promote-body-div">
                    <h3>Promoted Package List</h3>
                    <div className="promoted-items-list">
                        {
                            promotedPackCurrent?.map((item) => {
                                return (

                                    <div className="promoted-item-card mb-3" key={item?.id}>
                                        <h6 className="promoted-item-name">
                                            {item?.package__name}
                                        </h6>
                                        <i data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete"
                                            onClick={() => deleteHandler(item?.id)}>
                                            <AiFillDelete />
                                        </i>
                                    </div>
                                )
                            })
                        }

                    </div>
                    {
                        deleteStatus &&

                        <div className="delete-promot-div">
                            {
                                selectedPack?.length != 0 ?
                                    <button
                                        onClick={handleSubmit}
                                        className="btn btn-primary promote-btn btn-sm">Save changes</button>
                                    :
                                    <button
                                        onClick={deleteIdHandler}
                                        className="btn btn-primary promote-btn btn-sm">Delete All</button>
                            }

                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default PromotePackageModal