import React, { useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import FormPage from './FormPage'
import SideBarNav from '../../sidebar'
import useAsync from '../../../../hooks/useAsync'
import HospitalService from '../../../../services/HospitalService'

const AddHospitalPage = () => {
    const { data } = useAsync(HospitalService.allHospitalList)

    return (
        <>
            <SideBarNav />
            <div className='add-new-result-page-div'>
                <div className="add-Page-main-div">
                    <div className="add-page-header">
                        <h6>Current Hospitals : <span style={{ color: '98181e' }}>{data.length}</span></h6>
                        <Link to="/hopitals" className='View-btn'>View Hospitals</Link>
                    </div>

                    <div className="add-hosp-form-body">
                        <div className="add-hosp-form-header-div">
                            <h3>Add new Hospital</h3>

                            <div className="add-new-form-div">
                                <FormPage />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddHospitalPage