import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAsync from "../../../../hooks/useAsync";
import DoctorService from "../../../../services/DoctorService";
import LoadingSpinner from "../../Loading/LoadingSpinner";

const TopDoctors = ({ title }) => {

  const allDoctors = useAsync(DoctorService.getPromotedDoctors)
  const [docData, setDocData] = useState([])

  useEffect(() => {
    if (allDoctors.data) {
      setDocData(allDoctors?.data[0]?.doctor_list)
    }
  }, [allDoctors?.data])


  return (
    <div className="col-lg-4">
      <div className="card">
        <div className="card-header">
          <Link to="/doctor-list" className="row">
            <div className="col">
              <h5 className="card-title">Top {title}</h5>
            </div>
          </Link>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table doc-table">
              <tbody>

                {
                  allDoctors.loading ?
                    <LoadingSpinner />
                    :
                    docData?.length != 0 ? docData?.slice(0, 6).map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <h2 className="table-avatar">
                              <Link
                                className="avatar-pos avatar-online"
                                to={{
                                  pathname: "/doctor-list",
                                  state: { item: item.id }
                                }}
                              >
                                <img
                                  className="avatar avatar-img"
                                  src={item?.doctor_img}
                                  alt="User Image"
                                />
                              </Link>
                              <Link to={{
                                pathname: "/doctor-list",
                                state: { item: item.id }
                              }} className="user-name">
                                <span className="text-muted">{item?.name}</span>{" "}
                                <span className="tab-subtext">{item?.department ? item?.department : item?.designation}</span>
                              </Link>
                            </h2>
                          </td>
                          {/* <td className="text-end">
                          <span className="text-muted">{item.patients} Patients</span>
                        </td> */}
                        </tr>
                      )
                    })
                      :
                      <div className="dash-no-data-found-div">
                        <h4>No Packages Found</h4>
                      </div>
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopDoctors;
