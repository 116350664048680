import requests from './httpServices'

const BookingService = {
    allBookings(query) {
        return requests.get(`/doctor/booking/${query}`)
    },
    deleteBooking(id) {
        return requests.delete(`/doctor/booking/${id}/`)
    },
    getBookingById(id) {
        return requests.get(`/doctor/booking/${id}/`)
    },
    getCountOfAllBookings() {
        return requests.get('/doctor/count/booking/')
    }
}

export default BookingService