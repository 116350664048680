import React from 'react'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AdminServices from '../../../services/AdminServices'

function PatientViewModal({ viewModal, setViewModal, patientId }) {
    const [patientDetail, setPatientDetail] = useState()

    useEffect(() => {
        if (patientId) {
            AdminServices.getUserDetailsById(patientId).then((res) => {
                setPatientDetail(res)
            })
        }
    }, [patientId])

    console.log("patient detail", patientDetail)


    return (
        <Modal show={viewModal} onHide={() =>
            setViewModal(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>Patient Detail </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <div className='container'>
                        <div className="row">

                            <div className="row col-md-12">

                                <div className="col-md-9">
                                    <img src={patientDetail?.patient_image} />
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>UserName:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{patientDetail?.username}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Email:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{patientDetail?.email}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Phone Number:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{patientDetail?.mobile}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Age:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{patientDetail?.age}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Gender:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{patientDetail?.gender}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Country:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{patientDetail?.fk_country}</h6>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PatientViewModal