import React, { useState } from "react";
import useAsync from "../../../../hooks/useAsync";
import DoctorService from "../../../../services/DoctorService";
import axios from "axios";
import TestimonialService from "../../../../services/TestimonialService";
import SideBarNav from '../../sidebar'
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../../../Context/AllContext";
import { useRef } from "react";
import JoditEditor from 'jodit-react';
import Config from "../../../assets/constant/WritingPadConfig";
import Select from 'react-select'

const AddDoctorTestimonial = ({ mainId }) => {
    const inputRef = useRef(null)
    const inputRef1 = useRef(null)
    const inputRef2 = useRef(null)
    const inputRef3 = useRef(null)
    const editor = useRef(null)
    const [editData, setEditData] = useState([])
    const { setIsUpdate } = useContext(UserContext)
    const AllDoctors = useAsync(DoctorService.allDoctorList)
    const [docTestimonialValues, setDocTestimonialValues] = useState({
        user_name: '',
        testimonial_desc: '',
        user_image: '',
        doctor_name: '',
    })

    console.log("main id", mainId)

    useEffect(() => {
        if (mainId) {
            TestimonialService.getDoctorTestimonialById(mainId).then((res) => {
                console.log("res", res)
                setDocTestimonialValues({
                    doctor_name: res?.doctor_name?.id,
                    testimonial_desc: res?.testimonial_desc,
                    user_name: res?.user_name,
                    user_image: res?.user_image
                })
            })
        }
    }, [mainId])
    console.log("doctor testimonial", docTestimonialValues)


    const docValuesChange = (e) => {
        setDocTestimonialValues({ ...docTestimonialValues, [e.target.name]: e.target.value })
    }

    const docImgChange = (e) => {
        setDocTestimonialValues({ ...docTestimonialValues, [e.target.name]: e.target.files[0] })
    }

    const docRelatedChange = (e) => {
        console.log("doc id ", e.target.value)
        let docId = parseInt(e.target.value)
        setDocTestimonialValues({ ...docTestimonialValues, [e.target.name]: docId })
    }

    // console.log("doc testimonial values", docTestimonialValues)
    const resetFileInput = () => {
        inputRef.current.value = null
        inputRef2.current.value = null
        inputRef3.current.value = null

    }

    const getDefaultDoc = (data) => {
        const defaultDoc = data?.filter((current) => current?.id == docTestimonialValues?.doctor_name)
        const docOption = defaultDoc?.map((opt) => ({ label: opt?.name, value: opt?.id }))

        return docOption
    }

    const docTestimonialSubmit = (e) => {
        console.log('values doc', docTestimonialValues)
        e.preventDefault()
        let formData = new FormData()
        formData.append('user_name', docTestimonialValues.user_name)
        formData.append('testimonial_desc', docTestimonialValues.testimonial_desc)
        formData.append('user_image', docTestimonialValues.user_image)
        formData.append('doctor_name', docTestimonialValues.doctor_name)

        {
            mainId ?
                TestimonialService.updateDoctorTestimonial(mainId, formData).then((res) => {
                    alert("Updated Successfully")
                    setIsUpdate(true)
                    resetFileInput()
                    setDocTestimonialValues({
                        testimonial_desc: ''
                    })
                })
                :
                TestimonialService.addDoctorTestimonial(formData)
                    .then(res => {
                        console.log("Doctor testimonial", res)
                        alert('Success')
                        resetFileInput()
                        setDocTestimonialValues({
                            testimonial_desc: '',
                            doctor_name: ''
                        })
                    })
        }


    }

    return (

        <div className="adm-testi-form-main">
            <div className="adm-testi-form-body">
                <h4>{mainId ? " Edit" : "Add"} Doctor Testimonial</h4>
                {/* <Link className="btn btn-info right-side-btn" to="/testimonial" >View All</Link> */}
                <form action="" onSubmit={docTestimonialSubmit}>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Name</label>
                                <input type="text"
                                    className="form-control"
                                    name="user_name"
                                    ref={inputRef}
                                    defaultValue={docTestimonialValues.user_name}
                                    onChange={docValuesChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Content</label>
                                {/* <input type="text"
                                    className="form-control"
                                    name="testimonial_desc"
                                    ref={inputRef1}
                                    defaultValue={mainId ? editData?.testimonial_desc : docTestimonialValues.testimonial_desc}
                                    onChange={docValuesChange} /> */}
                                <JoditEditor
                                    ref={editor}
                                    value={docTestimonialValues.testimonial_desc}
                                    config={Config}
                                    tabIndex={4}
                                    onChange={(e) => setDocTestimonialValues({ ...docTestimonialValues, testimonial_desc: e })}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Select Doctor</label>

                                <Select
                                    options={AllDoctors?.data?.map((doc) => ({
                                        label: doc?.name,
                                        value: doc?.id
                                    }))}
                                    value={getDefaultDoc(AllDoctors?.data)}
                                    ref={inputRef2}
                                    onChange={(event) => setDocTestimonialValues({ ...docTestimonialValues, doctor_name: event?.value })}
                                />

                            </div>
                        </div>


                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>Image</label>
                                <input type="file"
                                    className="form-control"
                                    accept="image/png, image/jpeg"
                                    name="user_image"
                                    ref={inputRef3}
                                    onChange={docImgChange}
                                />
                            </div>
                        </div>
                        <div className="adm-testi-btn-div">
                            <button type="submit" className="btn btn-success">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default AddDoctorTestimonial