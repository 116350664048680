import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useAsync from "../../../hooks/useAsync";
import HospitalService from "../../../services/HospitalService";
import SpecialityServices from "../../../services/SpecialityServices";
import Multiselect from 'multiselect-react-dropdown';
import PackageService from "../../../services/PackageService";
import { useContext } from "react";
import { UserContext } from "../../../Context/AllContext";
import { useEffect } from "react";
import DepartmentService from "../../../services/DepartmentService";
import Select from 'react-select'
import JoditEditor from "jodit-react";
import Config from "../../assets/constant/WritingPadConfig";

const AddPackageForm = ({ setPackageModal, editId, assignPackageHandler }) => {
    console.log("edit id", editId)
    const { setIsUpdate } = useContext(UserContext)

    const allDepartment = useAsync(DepartmentService.allDepartment)
    const [blob, setBlob] = useState(null)
    const [values, setValues] = useState({
        name: '',
        description: '',
        department_id: '',
        offr_image: null,
    })
    const depRef = useRef(null)
    const editor = useRef(null)


    useEffect(() => {
        if (editId) {
            PackageService.getAssignedPackageById(editId).then((res) => {
                // console.log(res)
                setValues({
                    name: res.results.fk_procedure.name,
                    description: res.results.fk_procedure.description,
                    offr_image: res.results.fk_procedure.offr_image,
                    department_id: res.results.fk_procedure.department.id
                })
            })
        }
    }, [editId])


    const valuesChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const imageChangeHandler = (e) => {
        setValues({ ...values, [e.target.name]: e.target.files[0] })
    }

    console.log("department value", values.department_id)

    const getDefaultDep = (data) => {
        const defaultDep = data?.filter((current) => current?.id == values?.department_id)
        console.log("package selected", defaultDep)
        const depOption = defaultDep?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return depOption
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('name', values.name)
        formData.append('description', values.description)
        formData.append('department_id', values.department_id)
        if(blob){
            formData.append('offr_image', values.offr_image)
        }

        {
            editId ?
                PackageService.updatePackage(editId, formData).then((res) => {
                    console.log(res)
                    alert("Updated successfully")
                    setPackageModal(false)
                    setIsUpdate(true)
                    assignPackageHandler(editId)
                }).catch((err)=>console.log('err:',err))
                :
                PackageService.addPackage(formData).then((res) => {
                    console.log("package response", res)
                    alert(`${res.name} Submitted Successfully`)
                    setValues({
                        name: '',
                        description: '',
                        department_id: '',
                        offr_image: null,
                    })
                    setPackageModal(false)
                    setIsUpdate(true)
                    assignPackageHandler(res.id)
                })
        }
    }

    return (
        <div className="add-package-main-div">
            <form action="" onSubmit={SubmitHandler}>
                <div className="row">

                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>Offer Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                defaultValue={values.name}
                                onChange={valuesChange} />
                        </div>
                    </div>

                    {/* <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>Offer Details</label>
                            <input type="text"
                                className="form-control"
                                name="description"
                                defaultValue={values.description}
                                onChange={valuesChange} />
                        </div>
                    </div> */}
                    <div className="col-md-12">
                <div className="form-group ">
                  <label className='form-title'>
                  Offer Details
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={values.description}
                    config={Config}
                    tabIndex={4}
                    onChange={(e) => setValues({ ...values, description: e })}
                  />
                </div>
              </div>


                    <div className="col-md-6">
                        <div className="form-group ">
                            <label className='form-title'>Department</label>
                            {/* <Multiselect
                                options={allDepartment.data}
                                displayValue="vchr_name"
                                placeholder="Select Department..."
                                onSelect={(event) => setValues({ ...values, department_id: event[0].id })} /> */}

                            <Select
                                options={allDepartment?.data?.map((dep) => ({
                                    label: dep?.vchr_name,
                                    value: dep?.id
                                }))}
                                value={getDefaultDep(allDepartment?.data)}
                                ref={depRef}
                                onChange={(event) => setValues({ ...values, department_id: event?.value })}

                            />

                        </div>
                    </div>



                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>Image</label>
                            <input type="file"
                                className="form-control"
                                accept="image/png, image/jpeg"
                                name="offr_image"
                                defaultValue={values.offr_image}
                                onChange={(e) => {
                                    setValues({ ...values, [e.target.name]: e.target.files[0] })
                                    setBlob(URL.createObjectURL(e.target.files[0]))
                                }} />
                            {(blob || values.offr_image) && <img src={blob || values.offr_image} width={200} height={200} />}
                        </div>
                    </div>
                    <div className="adm-testi-btn-div">
                        <button type="submit" className="btn btn-success">Assign</button>
                    </div>
                </div>
            </form>
        </div>


    )
}

export default AddPackageForm