import React from "react";
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from "../../../hooks/useAsync";
import BlogServices from "../../../services/BlogServices";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../../Context/AllContext";
import DeleteModal from "../Modal/DeleteModal";

const BlogTable = ({ setEditId, setBlogForm }) => {
    const { data } = useAsync(BlogServices.allBlog)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteName, setDeleteName] = useState('')
    const { setIsUpdate } = useContext(UserContext)

    const deleteModalShow = (id, name) => {
        setDeleteId(id)
        setDeleteName(name)
        setDeleteModal(true)
    }

    const updateHandler = (id) => {
        setEditId(id)
        setBlogForm(true)
    }

    const deleteHandler = () => {
        BlogServices.deleteBlog(deleteId).then((res) => {
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    const columns = [
        {
            name: 'SId',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Blog',
            sortable: true,
            grow: 2,
            cell: row => <Media ><Media.Body className="d-flex"><Link to="#" className="spl-img" >
                <img src={row.image} className="img-fluid" />
            </Link><span>{row.title}</span>
            </Media.Body></Media>,
            width: "450px",

        },
        {
            name: 'Department',
            selector: row => row.fk_dept_name,
            sortable: true,
            width: "250px",
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,

            cell: row => <div className="actions">
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateHandler(row.id)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger ml-3"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id, row.title)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "auto",

        },
    ];

    const tableData = {
        columns,
        data,
    };
    return (
        <div className="card-body p-0">
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable

                        noHeader

                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>
            {/* <!-- Modal --> */}
            {/* <MainModal title="Speciality" show1={editModalShow} setShow1={setEditModalShow} id={id} >
                <AddSpecialityModal setShow1={setEditModalShow} id={id} />
            </MainModal> */}

            {/* Delete modal */}
            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName}
            />

            {/* <Modal show={deleteModal} onHide={() =>
				setDeleteModal(false)}>
				<Modal.Header closeButton >
					<Modal.Title className='profile-settings-modal-header'>
						<h4>Confirm Delete </h4>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-image-main-cont">
						<div className='onfirm-delete-main-div'>
							<p>Do you want to delete <span style={{ color: '#98181e', fontWeight: 'bold' }}>{deleteName}</span> ? </p>
							<div className="confirm-delete-btn-div">
								<button
									type="button" className="btn btn-danger"
									onClick={deleteHandler}>Delete</button>
								<button
									type="button" className="btn btn-secondary ml-3"
									onClick={() => setDeleteModal(false)}>Cancel</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal> */}

        </div>
    )
}

export default BlogTable