import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';

const ErrorNotification = ({ err, title, errorModal, setErrorModal }) => {
    // useEffect(() => {
    //     const timeOut = setTimeout(() => {
    //         setErrorModal(false)
    //     }, 3000);
    //     return () => clearTimeout(timeOut);

    // }, [])

    console.log(err)

    return (
        <>
            <Modal show={errorModal} onHide={() =>
                setErrorModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Something error happened!!! </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <div className='onfirm-delete-main-div'>
                            <p style={{ color: '#98181e', fontWeight: 'bold' }}>{title} submittion is Failed </p>
                            {err?.name && <p> <span style={{ color: '#98181e', fontWeight: 'bold' }}>Name</span> field may not be blank</p>}
                            {err?.department && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>Department</span> field may not be blank</p>}
                            {err?.doctors_worked && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>Doctors worked</span> field may not be blank</p>}
                            {err?.speciality && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>Speciality</span> field may not be blank</p>}
                            {err?.phone_num && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>Phone Number</span> field has no more than 12 characters</p>}
                            {err?.email && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>Email</span> field must be valid</p>}
                            {err?.department && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>Department</span> field may not be empty</p>}
                            {err?.message && <p><span style={{ color: '#98181e', fontWeight: 'bold' }}>{err?.message}</span> field may not be empty</p>}
                            <div className="confirm-delete-btn-div">
                                {/* <button
                                type="button" className="btn btn-danger"
                                onClick={deleteHandler}>Delete</button> */}
                                <button
                                    type="button" className="btn btn-secondary ml-3"
                                    onClick={() => setErrorModal(false)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ErrorNotification