import React from 'react'
import { Modal } from 'react-bootstrap'
import SubFacilityModal from './SubFacilityModal'

const FacilityMainModal = ({ setShow1, show1, title, id, editName }) => {
    return (
        <Modal show={show1} onHide={() =>
            setShow1(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>{id ? 'Edit' : 'Add'} {title}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <SubFacilityModal
                        setShow1={setShow1}
                        id={id}
                        editName={editName}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FacilityMainModal