import React, { useState, useContext, useMemo, useEffect } from 'react'
import config from 'config';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import Header from './components/header/index';
import Dashboard from './components/dashboard';
import Appointments from './components/appointments';
import PastAppointments from './components/appointments/PastAppointments';
import Specialities from './components/specialities';
import Doctors from './components/doctors';
import Patients from './components/patients';
import Reviews from './components/reviews';
import Transaction from './components/transaction';
import Settings from './components/settings';
import LocalizationDetails from './components/settings/LocalizationDetails';
import PaymentSettings from './components/settings/PaymentSettings';
import EmailSettings from './components/settings/EmailSettings';
import SocialSettings from './components/settings/SocialSettings';
import SocialLinks from './components/settings/SocialLinks';
import SeoSettings from './components/settings/SeoSettings';
import OthersSettings from './components/settings/OthersSettings';
import AppointmentReport from './components/Reports/AppointmentReport/AppointmentReport';
import IncomeReport from './components/Reports/IncomeReport/IncomeReport';
import UserReports from './components/Reports/UserReports/UserReports';
import InvoiceReport from './components/Reports/InvoiceReport/InvoiceReport';
// import InvoiceReport from './components/invoicereport';
// import Invoice from './components/invoicereport/invoice';
import ProductList from './components/productlist';
import PharmacyList from './components/pharmacylist';
import Categories from "./components/pharmacylist/Categories"
import Blog from './components/Blog/blog'
import BlogDetails from './components/Blog/blogdetails'
import AddBlog from './components/Blog/addblog'
import EditBlog from './components/Blog/editblog'
import PendingBlog from './components/Blog/pendingblog'
import Profile from './components/profile';
import AddProfile from './components/profile/AddProfile';
import Login from './components/login';
import Register from './components/register';
import ForgotPassword from './components/forgotpassword';
import Lockscreen from './components/lockscreen';
import ConformEmail from './components/forgotpassword/ConformEmail';
import Error from './components/error404';
import ErrorPage from './components/error500';
import BasicInput from './components/forms/baiscinput';
import FormInput from './components/forminput';
import FormHorizontal from './components/formhorizontal';
import FormVertical from './components/formvertical';
import FormMask from './components/formask';
import FormValidation from './components/formvalidation';
import BlankPage from './components/blankpage';
import Components from './components/component';
import DataTables from './components/datatables';
import BasicTables from './components/basictables';
import ProductCategories from './components/productlist/ProductCategories';
import Pastappointments from './components/appointments/PastAppointments'

import { Appcontext } from '../approuter';
import AdminHospitalList from './components/hospitals';
import AddNewPage from './components/addNewPage';
import AddNewHospitalPage from './components/hospitals/AddNewHospitalPage';
import BlogBannerEdit from './components/Blog/BlogBannerEdit';
import Enquiry from './components/Enquiry/index';
import Facilities from './components/Facilities';
import AdminPage from './components/adminPage';
import AddDoctorPage from './components/addNewPage/resultpages/AddDoctorPage';
import AddHospitalPage from './components/addNewPage/resultpages/AddHospitalPage';
import TestimonialPage from './components/testimonial';
import Packages from './components/packages';



import './assets/css/feathericon.min.css'
import './assets/js/feather.min.js'
import './assets/plugins/fontawesome/css/fontawesome.min.css'
import './assets/plugins/fontawesome/css/all.min.css'
import './assets/plugins/fontawesome/js/fontawesome.min.js'
import './assets/css/font-awesome.min.css'
import './assets/css/style.css'
import PrivateRoute from './components/privateRoute/PrivateRoute';
import Cookies from 'js-cookie'
import AddDoctorTestimonial from './components/testimonial/TestimonialForms/AddDoctorTestimonial';
import AddHospitalTestimonial from './components/testimonial/TestimonialForms/AddHospitalTestimonial';
import DoctorTestimonial from './components/testimonial/TestimonialForms/DoctorTestimonial';
import HospitalTestimonial from './components/testimonial/TestimonialForms/HospitalTestimonial';
import Department from './components/department';
import Treatment from './components/treatments';
import FaqPage from './components/faq';
import NewDashBoard from './components/newDashboard';
import BookingPage from './components/bookings';
import BannerPage from './components/Banners/BannerPage';
import DoctorProfile from './components/doctors/DoctorProfile';
import HospitalProfile from './components/hospitals/HospitalProfile';
import Gallery from './components/Gallery';
import UserProfile from './components/userProfile/UserProfile';
import ChangePassword from './components/password/ChangePassword';
import SeoPage from './components/SEO';


const AppUniversal = function (props) {
	let cookie
	if (Cookies.get('adminInfo')) {
		cookie = JSON.parse(Cookies.get('adminInfo'))
	}
	const [menu, setMenu] = useState(false);
	const [adminInfo, setAdminInfo] = useState(cookie);
	const toggleMobileMenu = () => {
		setMenu(!menu)
	}
	// const { isAuth, setIsAuth } = useContext(Appcontext);
	// useEffect(() => {
	// // console.log('useffect')
	// setAuth(Cookies.get('isAuth'))
	// // setAuth('true')
	// }, [isAuth])


	console.log("isAuth", adminInfo)
	// const location = useLocation()
	// console.log("setIsAuth", location)


	// useMemo(() => { 
	//      if(location?.pathname == "/login" || location?.pathname == "/register" || location?.pathname == "/forgotPassword" || location?.pathname == "/lockscreen" || location?.pathname == "/conform-email" || location?.pathname == "/404" || location?.pathname == "/500") {
	// 			setIsAuth("admin")
	// 		} else {
	// 			setIsAuth("user")
	// 		}
	// }, [location])


	return (

		<Router basename={`${config.publicPath}`}>

			<Switch>
				<Route path="/login" exact component={Login} />
				<Route path="/register" exact component={Register} />
				<Route path="/forgotPassword" exact component={ForgotPassword} />
				<Route path="/lockscreen" exact component={Lockscreen} />
				<Route path="/conform-email" exact component={ConformEmail} />
			</Switch>
			<div className={`main-wrapper ${menu ? 'slide-nav' : ''}`}>
				{
					adminInfo?.is_admin && (
						<Route render={(props) => <Header {...props} onMenuClick={(value) => toggleMobileMenu()} />} />
					)
				}
				<Switch>
					<PrivateRoute path="/" isAuth={adminInfo?.is_admin}>
						<Route path="/" exact component={NewDashBoard} />
						<Route path="/appointment-list" exact component={Appointments} />
						<Route path="/admin-page" exact component={AdminPage} />
						<Route path="/pastappointments" exact component={PastAppointments} />
						<Route path="/specialities" exact component={Specialities} />
						<Route path="/doctor-list" exact component={Doctors} />
						<Route path="/patient-list" exact component={Patients} />
						<Route path="/reviews" exact component={Reviews} />
						<Route path="/hopitals" exact component={AdminHospitalList} />
						<Route path="/transactions-list" exact component={Transaction} />
						<Route path="/settings" exact component={Settings} />
						<Route path="/localization-details" exact component={LocalizationDetails} />
						<Route path="/payment-settings" exact component={PaymentSettings} />
						<Route path="/email-settings" exact component={EmailSettings} />
						<Route path="/social-settings" exact component={SocialSettings} />
						<Route path="/social-links" exact component={SocialLinks} />
						<Route path="/seo-settings" exact component={SeoSettings} />
						<Route path="/others-settings" exact component={OthersSettings} />
						<Route path="/appointmentrepot" exact component={AppointmentReport} />
						<Route path="/incomerepot" exact component={IncomeReport} />
						<Route path="/invoicerepot" exact component={InvoiceReport} />
						<Route path="/userrepots" exact component={UserReports} />
						<Route path="/blog" exact component={Blog} />
						<Route path="/blog-details" exact component={BlogDetails} />
						<Route path="/add-blog" exact component={AddBlog} />
						<Route path="/edit-blog" exact component={EditBlog} />
						<Route path="/pending-blog" exact component={PendingBlog} />
						<Route path="/profile" exact component={Profile} />
						<Route path="/addprofile" exact component={AddProfile} />
						<Route path="/product-list" exact component={ProductList} />
						<Route path="/pharmacy-list" exact component={PharmacyList} />
						<Route path="/pharmacy-category" exact component={Categories} />
						{/* <Route path="/invoice" exact component={Invoice} /> */}
						<Route path="/facilities" exact component={Facilities} />
						<Route path="/404" exact component={Error} />
						<Route path="/500" exact component={ErrorPage} />
						<Route path="/blank-page" exact component={BlankPage} />
						<Route path="/components" exact component={Components} />
						<Route path="/basic-input" exact component={BasicInput} />
						<Route path="/form-input-group" exact component={FormInput} />
						<Route path="/form-horizontal" exact component={FormHorizontal} />
						<Route path="/form-vertical" exact component={FormVertical} />
						<Route path="/form-mask" exact component={FormMask} />
						<Route path="/form-validation" exact component={FormValidation} />
						<Route path="/tables-basic" exact component={BasicTables} />
						<Route path="/data-tables" exact component={DataTables} />
						<Route path="/product-category" exact component={ProductCategories} />
						<Route path="/pastappointments" exact component={Pastappointments} />
						<Route path='/testimonial' exact component={TestimonialPage} />
						<Route path="/packages" exact component={Packages} />
						<Route path="/add-hospital" exact component={AddHospitalPage} />
						<Route path="/add-doctor" exact component={AddDoctorPage} />
						<Route path="/add-doctor-testimonial" exact component={AddDoctorTestimonial} />
						<Route path="/add-hospital-testimonial" exact component={AddHospitalTestimonial} />
						<Route path="/doctorTestimonial" exact component={DoctorTestimonial} />
						<Route path="/hospital-testimonial" exact component={HospitalTestimonial} />
						<Route path="/department" exact component={Department} />
						<Route path="/enquiry" exact component={Enquiry} />
						<Route path="/treatment" exact component={Treatment} />
						<Route path="/faqPage" exact component={FaqPage} />
						<Route path="/bookings" exact component={BookingPage} />
						<Route path="/banners" exact component={BannerPage} />
						<Route path="/doctor-profile" exact component={DoctorProfile} />
						<Route path="/hospital-profile" exact component={HospitalProfile} />
						<Route path="/gallery" exact component={Gallery} />
						<Route path="/profile-user" exact component={UserProfile} />
						<Route path="/admin/changepassword" exact component={ChangePassword} />
						<Route path="/seo" exact component={SeoPage} />
					</PrivateRoute>
				</Switch>
			</div>
			{/* </UserContext.Provider> */}
		</Router>
	);
}

export default AppUniversal;

