import React from 'react'
import { Link } from 'react-router-dom'
import DoctorFormPage from './DoctorFormPage'
import SideBarNav from '../../sidebar'
import useAsync from '../../../../hooks/useAsync'
import DoctorService from '../../../../services/DoctorService'

const AddDoctorPage = () => {
    const { data } = useAsync(DoctorService.allDoctorList)
    return (
        <>
            <SideBarNav />
            <div className='add-new-result-page-div'>
                <div className="add-Page-main-div">
                    <div className="add-page-header">
                        <h6>Current Doctors : <span style={{ color: '98181e' }}>{data.length}</span></h6>
                        <Link to="/doctor-list" className='View-btn'>View Doctors</Link>
                    </div>

                    <div className="add-hosp-form-body">
                        <div className="add-hosp-form-header-div">
                            <h3>Add new Doctor</h3>
                            <div className="add-new-form-div">
                                <DoctorFormPage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddDoctorPage