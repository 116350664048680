import requests from "./httpServices";

const PackageService = {
    addPackage(body) {
        return requests.filePost('/procedure/procedure/', body)
    },
    allPackages() {
        return requests.get('/procedure/procedure/')
    },
    getPackageById(id) {
        return requests.get(`/procedure/procedure/${id}/`)
    },
    updatePackage(id, body) {
        return requests.filePut(`/procedure/procedure/${id}/`, body)
    },
    deletePackage(id) {
        return requests.delete(`/procedure/procedure/${id}/`)
    }, 
    assignProcedure(body) {
        return requests.filePost('/procedure/assign_procedure/', body)
    },
    updateAssignProcedure(id,body) {
        return requests.filePut(`/procedure/assign_procedure/${id}/`, body)
    },
    allAssignedPackage() {
        return requests.get('/procedure/assign_procedure/')
    },
    getAssignedPackageById(id) {
        return requests.get(`/procedure/assign_procedure/${id}/`)
    },
    getAllPackageBookings(page) {
        return requests.get(`/procedure/offer/?page=${page}`)
    },
    getPackageBookingById(id) {
        return requests.get(`/procedure/offer/${id}/`)
    },
    deletePackageBooking(id) {
        return requests.delete(`/procedure/offer/${id}/`)
    },
    getPromotedPackage() {
        return requests.get('/procedure/promote/')
    },
    addPromotePackage(body) {
        return requests.filePost('/procedure/promote/', body)
    },
    updatePromotePackage(id, body) {
        return requests.filePut(`/procedure/promote/${id}/`, body)
    },
    deletePromotePackage(id) {
        return requests.delete(`/procedure/promote/${id}/`)
    },
    getPromotedPackageById(id) {
        return requests.get(`/procedure/promote/${id}/`)
    },
    addPackageWithSlug(body) {
        return requests.filePost('procedure/slug/', body)
    }

}

export default PackageService