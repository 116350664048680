import React from 'react'
import SiderBarNav from '../sidebar/index'
import GalleryTable from './GalleryTable'

const Gallery = () => {
    return (
        <>
            <SiderBarNav />

            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="adm-dep-body-div">
                        <h1>Gallery</h1>
                        <GalleryTable />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery