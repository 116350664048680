import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from '../../../hooks/useAsync'
import FacilityService from '../../../services/FacilityServices';
import FacilityMainModal from './FacilityMainModal';
import { UserContext } from '../../../Context/AllContext';
import DeleteModal from '../Modal/DeleteModal';
import { useEffect } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

function FacilitiesTable({ id, setId }) {
    const allFacility = useAsync(FacilityService.allFacility)
    const { isUpdate, setIsUpdate } = useContext(UserContext)
    const [editModalShow, setEditModalShow] = useState(false)
    const [editName, setEditName] = useState()
    const [deleteItemId, setDeleteItemId] = useState()
    const [deleteItemName, setDeleteItemName] = useState()
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [data, setData] = useState()

    useEffect(() => {
        if (allFacility) {
            setData(allFacility.data)
        }
    }, [allFacility.data])


    const updateFacility = (id, name) => {
        setId(id)
        setEditModalShow(true)
        setEditName(name)
    }

    const deleteModalShowHandler = (id, name) => {
        setDeleteModalShow(true)
        setDeleteItemId(id)
        setDeleteItemName(name)
    }

    const deleteItemHandler = () => {
        FacilityService.deleteFacility(deleteItemId).then((res) => {
            setIsUpdate(true)
            setDeleteModalShow(false)
        })
    }

    const columns = [
        {
            name: 'SId',
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Facility',
            sortable: true,
            cell: row => row.facility_name,
            width: "300px",

        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateFacility(row.id, row.facility_name)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShowHandler(row.id, row.facility_name)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "250px",

        },
    ];

    const tableData = {
        columns,
        data,
    };

    return (
        <div className="card-body p-0">
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* <!-- Modal --> */}
            <FacilityMainModal show1={editModalShow}
                setShow1={setEditModalShow}
                title="Facility"
                id={id}
                editName={editName}
            />

            {/* Delete modal */}
            <DeleteModal deleteModal={deleteModalShow}
                setDeleteModal={setDeleteModalShow}
                deleteHandler={deleteItemHandler}
                deleteName={deleteItemName} />
        </div>
    )
}

export default FacilitiesTable