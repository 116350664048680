import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import BookingModal from '../bookings/BookingModal';

const UserBookingsModal = ({ modal, setModal, data }) => {
    const [viewModal, setViewModal] = useState(false)
    const [viewId, setViewId] = useState()
    console.log("book data", data)

    const viewHandler = (id) => {
        setViewId(id)
        setViewModal(true)
    }

    const columns = [
        {
            name: 'Id',
            cell: (row, index) => index + 1,
            sortable: true,
            width: "50px",
        },

        {
            name: 'Name',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar">
                {/* <Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#patientlist" data-bs-toggle="modal"><img className="avatar avatar-img" src={row?.upload_document} alt="User Image" /></Link> */}
            </h2><Link to="#"
                data-bs-target="#Patientlist"
                data-bs-toggle="modal"
            ><span className="user-name">{row.patient_name}</span><br /><span className="d-block text-muted">{row.age}</span>
                </Link></Media.Body></Media>,
            width: "150px",

        },
        {
            name: 'Hospital/Doctor',
            selector: row => row?.hospital?.name,
            cell: row => <Media ><Media.Body className="d-flex"><div>
                {/* <span className="user-name">{row.Date}</span>
                <br /> */}
                <span className="d-block">{row?.hospital?.name || row?.doctor_name}</span></div></Media.Body></Media>,
            width: "250px",
        },

        {
            name: 'Email',
            selector: row => row?.email,
            cell: row => <Media ><Media.Body className="d-flex"><div>
                {/* <span className="user-name">{row.Date}</span>
                <br /> */}
                <span className="d-block">{row?.email}</span></div></Media.Body></Media>,
            width: "150px",
        },

        {
            name: 'Appoinment Date',
            selector: row => row?.appointment_date,
            cell: row => <Media ><Media.Body className="d-flex"><div>
                {/* <span className="user-name">{row.Date}</span>
                <br /> */}
                <span className="d-block">{row?.appointment_date}</span></div></Media.Body></Media>,
            width: "150px",
        },

        {
            name: 'Account status',
            selector: row => row.status,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => viewHandler(row.id)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link>

                {/* <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link> */}
            </div>,
            width: "300px",
        },
    ];

    const tableData = {
        columns,
        data,

    };

    return (
        <>
            <Modal show={modal} onHide={() =>
                setModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Bookings </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <DataTableExtensions
                            {...tableData}
                        >
                            <DataTable className="datatable table table-borderless hover-table"
                                id="data-table"

                                noHeader

                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination
                                highlightOnHover
                            />
                        </DataTableExtensions>
                    </div>
                </Modal.Body>
            </Modal>

            {/* View modal */}
            <BookingModal viewId={viewId}
                viewBook={viewModal}
                setViewBook={setViewModal} />
        </>
    )
}

export default UserBookingsModal