import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FcPlus, FcGlobe, FcBullish, FcAssistant, FcBusinessman, FcOnlineSupport } from "react-icons/fc"
import { DesignationData } from './FormData'
import { Modal } from 'react-bootstrap'
import DoctorsListModal from './ListModalPages/DoctorsListModal'
import useAsync from '../../../../hooks/useAsync'
import HospitalService from '../../../../services/HospitalService'
import FacilityService from '../../../../services/FacilityServices'
import DoctorService from '../../../../services/DoctorService'
import SpecialityServices from '../../../../services/SpecialityServices'
import axios from 'axios'
import Multiselect from 'multiselect-react-dropdown';
import { useRef } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { UserContext } from '../../../../Context/AllContext'
import CountryData from '../../../../data/CountryData.json'
import DepartmentService from '../../../../services/DepartmentService'
import LocationService from '../../../../services/LocationService'
import ErrorNotification from '../../Notifications/ErrorNotification'
import SuccessNotification from '../../Notifications/SuccessNotification'
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import Config from '../../../assets/constant/WritingPadConfig'

const FormPage = ({ editId, setEditModal }) => {
    // console.log("update id", editId)
    const inputRef = useRef(null)
    const multiselectRef1 = useRef(null)
    const multiselectRef2 = useRef(null)

    const allDpartments = useAsync(DepartmentService.allDepartment)
    const { setIsUpdate } = useContext(UserContext)
    const allCountry = useAsync(LocationService.allCountry)
    const allState = useAsync(LocationService.allState)
    const [modalShow, setModalShow] = useState(false)
    const allFacility = useAsync(FacilityService.allFacility)
    const allDoctors = useAsync(DoctorService.allDoctorList)
    const allSpeciality = useAsync(SpecialityServices.allSpeciality)
    const allFacilities = useAsync(FacilityService.allFacility)
    const [removeSelection, setRemoveSelection] = useState(false)
    const [hospitalValues, setHospitalValues] = useState({
        name: '',
        address: '',
        phone_num: '',
        description: '',
        email: '',
        fk_location: '',
        multi_speciality: false,
        hospital_img: null,
    })
    const [locationValue, setLocationValue] = useState({ fk_country: '', fk_state: '' })
    const [speciality, setspeciality] = useState([])
    const [department, setDepartment] = useState([])
    const [doctors_worked, setdoctors_worked] = useState([])
    const [procedures, setProcedures] = useState([])
    const [countryId, setCountryId] = useState('')
    const [stateId, setStateId] = useState('')
    const [statesByCountry, setStatesByCountry] = useState([])
    const [blob, setBlob] = useState(null)
    const [errorModal, setErrorModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState()
    const editor = useRef(null);
    // const hospitalnameRefvalue = useRef()
    // console.log("allDoctors", allDoctors.data)
    useEffect(() => {
        if (countryId) {
            setStatesByCountry(allState.data.filter((current) => current.fk_country === countryId))
        } else {
            setStatesByCountry(allState.data)
        }
    }, [countryId, allState.data])

    useEffect(() => {
        if (editId) {
            HospitalService.getHospitalById(editId).then((res) => {
                console.log("edit data", res)
                setHospitalValues({
                    name: res.name,
                    address: res.address,
                    phone_num: res?.phone_num,
                    description: res.description,
                    email: res?.email,
                    multi_speciality: res.multi_speciality,
                    hospital_img: res.hospital_img
                })
                if (res?.speciality_list) {
                    setspeciality(res?.speciality_list)
                }
                if (res?.doctors_worked_list) {
                    setdoctors_worked(res?.doctors_worked_list)
                }
                setLocationValue({
                    fk_country: res?.location?.fk_country,
                    fk_state: res?.location?.fk_state
                })
                setProcedures(res?.facilities_list)
                setDepartment(res?.department_list)
            })
        }

    }, [])

    // console.log("doctors worked", doctors_worked)
    // console.log("speciality", speciality)


    const handleChange = (e) => {
        setHospitalValues({ ...hospitalValues, [e.target.name]: e.target.value })
    }

    const hospitalImageChange = (e) => {
        setHospitalValues({ ...hospitalValues, [e.target.name]: e.target.files[0] })
        setBlob(URL.createObjectURL(e.target.files[0]))
    }

    const stateHandler = (id, name) => {
        // setCountryId(id)
        setLocationValue({ ...locationValue, fk_country: id })
        console.log("all states", allState?.data)
        setStatesByCountry(allState?.data?.filter((current) => current?.fk_country == id))
    }

    console.log("state by country", statesByCountry)

    console.log("country id", countryId)

    const locationHandler = (stateCode, stateName) => {
        setStateId(stateCode)
        // StateName = stateName
        setLocationValue({ ...locationValue, fk_state: stateCode })
        //    console.log("location")
    }

    // console.log("doctors worked", doctors_worked)

    const resetFileInput = () => {
        inputRef.current.value = null;
        multiselectRef1.current.clearValue()
        multiselectRef2.current.clearValue()
        editor.current.value = null
    };

    const doctorOptions = () => {
        const options = allDoctors?.data?.map((docs) => ({
            label: docs.name,
            value: docs.id
        }))
        // console.log("options", options)
        return options
    }

    const getDefaultCountry = (data) => {
        const defaultValue = data?.filter((current) => current?.id == locationValue?.fk_country)
        console.log("country", defaultValue)
        const valueOption = defaultValue?.map((item) => ({ label: item?.vchr_name, value: item?.id }))

        return valueOption
    }

    const getDefaultState = (data) => {
        const defaultState = data?.filter((current) => current?.id == locationValue?.fk_state)
        const stateValueOption = defaultState?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return stateValueOption
    }

    console.log("value", locationValue)


    const handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('name', hospitalValues?.name)
        formData.append('address', hospitalValues?.address)
        formData.append('phone_num', hospitalValues?.phone_num)
        formData.append('multi_speciality', hospitalValues?.multi_speciality)
        formData.append('description', hospitalValues?.description)
        if (hospitalValues?.email != null && hospitalValues?.email != 'null') {
            formData.append('email', hospitalValues.email)
        }
        formData.append('location', JSON.stringify(locationValue))
        if (hospitalValues?.hospital_img?.name) {
            formData.append('hospital_img', hospitalValues.hospital_img)

        }
        formData.append('country_id', countryId)
        speciality.map((item) => {
            formData.append('speciality', item.id)
        })
        department.map((item) => {
            formData.append('department', item.id)
        })

        doctors_worked.map((doc) => {
            formData.append('doctors_worked', doc?.id)
        })

        // } else {
        //     doctors_worked.map((doc) => {
        //         formData.append('doctors_worked', doc?.value)
        //     })
        // }

        procedures.map((item) => {
            formData.append('facilities', item.id)
        })
        {
            editId ?
                HospitalService.updateHospital(editId, formData).then((res) => {
                    setSuccessModal(true)
                    setEditModal(false)
                    setHospitalValues({
                        name: '',
                        address: '',
                        phone_num: '',
                        description: '',
                        email: '',
                        multi_speciality: false,
                        hospital_img: null,
                    })
                    setIsUpdate(true)
                    setRemoveSelection(true)
                    setDepartment([])
                    setspeciality([])
                    setdoctors_worked([])
                    setProcedures([])
                    resetFileInput()
                    setIsUpdate(true)
                    setBlob(null)
                }).catch((err) => {
                    setErrorModal(true)
                    setErrorMsg(err?.response?.data)
                })
                :
                HospitalService.addHospitalBySlug(formData)
                    .then(res => {
                        // console.log("add data", res);
                        setSuccessModal(true)
                        setHospitalValues({
                            name: '',
                            address: '',
                            phone_num: '',
                            description: '',
                            email: '',
                            multi_speciality: false,
                            hospital_img: null,
                        })
                        setRemoveSelection(true)
                        setDepartment([])
                        setspeciality([])
                        setdoctors_worked([])
                        setProcedures([])
                        resetFileInput()
                        setIsUpdate(true)
                        setBlob(null)
                    }).catch((err) => {
                        setErrorMsg(err?.response?.data)
                        setErrorModal(true)
                    })
        }
    }



    const modalHandler = (event) => {
        setModalShow(true)
        event.preventDefault()
    }

    // const dummy = [87, 88]
    // const getValue = () => {
    //     const doc = doctors_worked.map((data) => data.id)
    //     const data = doctorOptions().filter(({ value }) => dummy.includes(value))
    //     console.log('filt', dummy)
    //     console.log('filtBat', data)
    //     return data
    // }
    // getValue()
    return (
        <div className='add-new-form-main'>
            <form action="" className='add-hos-form'
            // onSubmit={handleSubmit}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>
                                Hospital Name
                            </label>
                            <input type="text"
                                className="form-control"
                                name="name"
                                required
                                spellCheck={true}
                                value={hospitalValues.name}
                                onChange={handleChange}
                            // ref={hospitalnameRefvalue}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>
                                Address
                            </label>
                            <input type="text"
                                className="form-control"
                                name='address'
                                required
                                spellCheck={true}
                                value={hospitalValues.address}
                                onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label className='form-title'>Location</label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='form-title'>Country</label>
                                    {/* <Multiselect
                                        options={allCountry.data}
                                        displayValue={"vchr_name"}
                                        placeholder={"Search Country..."}
                                        avoidHighlightFirstOption={true}
                                        hidePlaceholder={true}
                                        selectionLimit={1}
                                        onRemove={removeHandler}
                                        ref={multiselectRef1}
                                        onSelect={(event) => stateHandler(event[0].id)}
                                    /> */}

                                    {/* {editId && <p className='preselected-value'>{countryName ? countryName : countryName[0]?.vchr_name} </p>} */}

                                    <Select
                                        options={allCountry.data?.map((country) => ({
                                            label: country.vchr_name,
                                            value: country.id
                                        }))}
                                        // defaultValue={locationValue?.fk_country}
                                        value={getDefaultCountry(allCountry?.data)}
                                        ref={multiselectRef1}
                                        onChange={(event) => stateHandler(event?.value)} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='form-title'>State</label>
                                    {/* {editId && <p className='preselected-value'>{StateName ? StateName : StateName[0]?.vchr_name} </p>} */}

                                    <Select
                                        options={statesByCountry?.map((stateItem) => ({
                                            label: stateItem.vchr_name,
                                            value: stateItem.id
                                        }))}
                                        // defaultValue={locationValue?.fk_state}
                                        value={getDefaultState(allState?.data)}
                                        ref={multiselectRef2}
                                        onChange={(event) => locationHandler(event?.value, event?.label)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className='form-title'>
                                Contact No.
                            </label>
                            <input type="tel"
                                className="form-control"
                                name='phone_num'
                                required
                                spellCheck={true}
                                value={hospitalValues?.phone_num != null && hospitalValues.phone_num}
                                onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className='form-title'>
                                Email Address
                            </label>
                            <input type="email"
                                className="form-control"
                                name='email'
                                required
                                spellCheck={true}
                                value={hospitalValues?.email != null ? hospitalValues.email : ""}
                                onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            {/* <div className="btn-group"> */}
                            <label className='form-title'>Is Multi Speciality?</label>
                            <select name="multi_speciality"
                                value={hospitalValues.multi_speciality}
                                className='ml-3'
                                onChange={handleChange}>
                                <option disabled={true} value="">Select...</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                            {/* </div> */}
                        </div>
                    </div>

                    {/* <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Description</label>
                            <input type="textarea"
                                className="form-control"
                                name='description'
                                required
                                spellCheck={true}
                                value={hospitalValues.description}
                                onChange={handleChange} />
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Description</label>
                            <JoditEditor
                                ref={editor}
                                value={hospitalValues.description}
                                config={Config}
                                tabIndex={4} // tabIndex of textarea
                                // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                onChange={(e) => setHospitalValues({ ...hospitalValues, description: e })}
                            />
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Add Doctors working</label>
                            <div className="form-group">
                                <Multiselect
                                    options={allDoctors.data}
                                    className={errorMsg?.doctors_worked && "error-input-box"}
                                    displayValue="name"
                                    placeholder="Select Doctors..."
                                    avoidHighlightFirstOption={true}
                                    selectedValues={doctors_worked}
                                    onRemove={(event) => setdoctors_worked(event)}
                                    onSelect={(event) => setdoctors_worked(event)}
                                />

                            </div>
                            {errorMsg?.doctors_worked && <p className='error-title-p'>This field may not be empty</p>}
                        </div>
                    </div>

                    <div className={editId ? "col-md-12 mt-3" : "col-md-6 mt-3 mb-3"}>
                        <label className='form-title'>Add Departments</label>
                        <div className='d-flex flex-row justify-content-start align-items-baseline'>


                            <Multiselect
                                options={allDpartments.data}
                                displayValue="vchr_name"
                                placeholder='select Department...'
                                avoidHighlightFirstOption={true}
                                className={errorMsg?.department && "error-input-box"}
                                // onRemove={removeSelection}
                                selectedValues={department}
                                onRemove={(event) => setDepartment(event)}
                                onSelect={(event) => setDepartment(event)} />
                        </div>
                        {errorMsg?.department && <p className='error-title-p'>This field may not be empty</p>}

                    </div>

                    <div className={editId ? "col-md-12 mt-3" : "col-md-6 mt-3 mb-3"}>
                        <label className='form-title'>Add Services</label>
                        <div className='d-flex flex-row justify-content-start align-items-baseline'>

                            <Multiselect
                                options={allFacilities.data}
                                displayValue={"facility_name"}
                                placeholder='select Procedures...'
                                selectedValues={procedures}
                                avoidHighlightFirstOption={true}
                                onRemove={(event) => setProcedures(event)}
                                onSelect={(event) => setProcedures(event)}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Add Speciality</label>
                            <div className="form-group">
                                <Multiselect
                                    options={allSpeciality.data}
                                    displayValue="name"
                                    className={errorMsg?.speciality && "error-input-box"}
                                    placeholder="Select Speciality..."
                                    avoidHighlightFirstOption={true}
                                    selectedValues={speciality}
                                    onRemove={(event) => setspeciality(event)}
                                    onSelect={(event) => setspeciality(event)}
                                />
                            </div>
                            {errorMsg?.speciality && <p className='error-title-p'>This field may not be empty</p>}
                        </div>
                    </div>

                    <div className="col-md-10">
                        <label className='form-title'>Add Hospital Image</label>
                        <input type="file"
                            className='form-control'
                            name="hospital_img"
                            accept="image/png, image/jpeg"
                            ref={inputRef}
                            defaultValue={hospitalValues?.hospital_img}
                            onChange={hospitalImageChange} />
                    </div>
                    {
                        (blob || hospitalValues.hospital_img) &&
                        <div className='image-preivew-div'>
                            <img src={blob || hospitalValues.hospital_img} alt="" />
                        </div>
                    }

                    <div className="add-new-submit-btn-div mt-3">
                        <button onClick={handleSubmit} type='submit' className="submit-btn">{editId ? "Save Changes" : "Submit"}</button>
                    </div>
                </div>
            </form>

            {/* Error Notification */}
            <ErrorNotification err={errorMsg} title="Hospital" setErrorModal={setErrorModal} errorModal={errorModal} />

            {/* Succes notification */}
            <SuccessNotification title="Hospital" setSuccess={setSuccessModal} success={successModal} />

            <Modal show={modalShow} onHide={() =>
                setModalShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Doctors List</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <DoctorsListModal />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FormPage