import requests from "./httpServices";

const CountServices = {
    allDoctors() {
        return requests.get('/doctor/count/')
    },
    allHospitals() {
        return requests.get('/hospital/count/')
    },
    allUsers() {
        return requests.get('/enquiry/patient/count/')
    },
    allTreatments() {
        return requests.get('/doctor/count/treatment/')
    },
    allSpecialities() {
        return requests.get('/hospital/count/speciality/')
    }
}

export default CountServices