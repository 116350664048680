import React, { useRef } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import AwardListModal from './ListModalPages/AwardListModal'
import EducationalListModal from './ListModalPages/EducationalListModal'
import ExperienceModal from './ListModalPages/ExperienceModal'
import useAsync from '../../../../hooks/useAsync'
import DoctorService from '../../../../services/DoctorService'
import SpecialityServices from '../../../../services/SpecialityServices'
import Multiselect from 'multiselect-react-dropdown'
import { useEffect } from 'react'
import { useContext } from 'react'
import { UserContext } from '../../../../Context/AllContext'
import FacilityService from '../../../../services/FacilityServices'
import DepartmentService from '../../../../services/DepartmentService'
import LocationService from '../../../../services/LocationService'
import ErrorNotification from '../../Notifications/ErrorNotification'
import SuccessNotification from '../../Notifications/SuccessNotification'
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import Config from '../../../assets/constant/WritingPadConfig'

const DoctorFormPage = ({ editId, setEditModal }) => {
    const inputRef = useRef(null)
    const multiselectRef1 = useRef(null)
    const multiselectRef2 = useRef(null)
    const multiselectRef3 = useRef(null)

    const allSpeciality = useAsync(SpecialityServices.allSpeciality)
    const allFacilities = useAsync(FacilityService.allFacility)
    const allDepartment = useAsync(DepartmentService.allDepartment)
    const allCountry = useAsync(LocationService.allCountry)
    const allState = useAsync(LocationService.allState)
    const history = useHistory()
    const { setIsUpdate } = useContext(UserContext)
    const [awardModal, setAwardModal] = useState(false)
    const [docValues, setDocValues] = useState({
        name: '',
        // designation: '',
        biography: '',
        address: '',
        main_education: '',
        main_experience: '',
        email: '',
        phone_num: '',
        doctor_img: null,
    })
    const [locationValue, setLocationValue] = useState({ fk_country: '', fk_state: '' })
    const [designation, setDesignation] = useState('')
    const [docExperience, setDocExperience] = useState({
        wrked_hosp: '',
        designation: '',
        start_date: '',
        end_date: ''
    })
    const [expArray, setExpArray] = useState([])
    const [expModalShow, setExpModalShow] = useState(false)
    const [docEducation, setDocEducation] = useState({
        collage_name: '',
        course: '',
        start_data: '',
        end_date: ''
    })
    const [educationArray, setEducationArray] = useState([])
    const [eduModalShow, setEduModalShow] = useState(false)
    const [doctor_awards, setdoctor_awards] = useState({
        award_title: '',
        award_detail: '',
        year: ''
    })
    const [awardArray, setAwardArray] = useState([])
    const [speciality, setSpeciality] = useState([])
    const [facilities, setFacilities] = useState([])
    const [countryId, setCountryId] = useState('')
    const [stateId, setStateId] = useState('')
    const [statesByCountry, setStatesByCountry] = useState([])
    const [blob, setBlob] = useState(null)
    const [errorModal, setErrorModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [newAwardArray, setNewAwardArray] = useState([])
    const editor = useRef(null);

    useEffect(() => {
        if (countryId) {
            setStatesByCountry(allState.data.filter((current) => current.fk_country === countryId))
        } else {
            setStatesByCountry(allState.data)
        }
    }, [countryId, allState.data])


    useEffect(() => {
        if (editId) {
            DoctorService.getDoctorById(editId).then((res) => {
                console.log("edit doctor data", res)
                setDocValues({
                    name: res.name,
                    designation: res.department,
                    biography: res.biography,
                    address: res.address,
                    main_education: res.main_education,
                    main_experience: res.main_experience,
                    email: res?.email,
                    phone_num: res.phone_num,
                    doctor_img: res.doctor_img,
                })
                setDesignation(res?.fk_dept)
                setNewAwardArray(res?.doctor_awards)
                setEducationArray(res?.education)
                setExpArray(res?.experience)
                setFacilities(res?.doctor_services)
                setSpeciality(res?.specialisation)
                setLocationValue({
                    fk_country: res?.location?.fk_country,
                    fk_state: res?.location?.fk_state
                })
            })
        }
    }, [])

    const handleChange = (e) => {
        setDocValues({ ...docValues, [e.target.name]: e.target.value })
    }

    const ExperienceChange = (e) => {
        setDocExperience({ ...docExperience, [e.target.name]: e.target.value })
    }

    const addExperienceHandler = () => {
        if (expArray) {
            setExpArray([...expArray, docExperience])
        } else {
            setExpArray([docExperience])
        }
        setDocExperience({ wrked_hosp: '', designation: '', start_date: '', end_date: '' })
    }

    const educationChange = (e) => {
        setDocEducation({ ...docEducation, [e.target.name]: e.target.value })
    }

    const educationSubmit = () => {
        setEducationArray([...educationArray, docEducation])
        setDocEducation({ collage_name: '', course: '', end_date: '', start_data: '' })
    }

    const awardChange = (e) => {
        setdoctor_awards({ ...doctor_awards, [e.target.name]: e.target.value })
    }
    // let dataSet = []
    const awardSubmit = () => {
        if (newAwardArray) {
            setNewAwardArray([...newAwardArray, doctor_awards])
        } else {
            setNewAwardArray([doctor_awards])
        }
        setdoctor_awards({ award_detail: '', award_title: '', year: '' })
    }
    // console.log(newAwardArray)


    const doctorImgChange = (e) => {
        setDocValues({ ...docValues, [e.target.name]: e.target.files[0] })
        setBlob(URL.createObjectURL(e.target.files[0]))
    }

    const designationHandler = (event) => {
        // console.log(event)
        setDesignation(event)
    }
    console.log(designation)

    const stateHandler = (id) => {
        setCountryId(id)
        setLocationValue({ fk_country: countryId })
        setStatesByCountry(allState.data?.filter((current) => current.fk_country === countryId))
    }
    console.log(countryId)

    const locationHandler = (stateCode) => {
        setLocationValue({ fk_country: countryId, fk_state: stateCode })
    }

    const resetFileInput = () => {
        inputRef.current.value = null;
        multiselectRef1.current.clearValue()
        multiselectRef2.current.clearValue()
        multiselectRef3.current.clearValue()
        editor.current.value = null
    };

    console.log('Speciality array', speciality)

    const getDefaultDep = (data) => {
        const defaultDep = data?.filter((current) => current?.id == designation)
        const depOption = defaultDep?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return depOption
    }

    const getDefaultCountry = data => {
        const defaultCountry = data?.filter((current) => current?.id == locationValue?.fk_country)
        const countryOption = defaultCountry?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return countryOption
    }

    const getDefaultState = data => {
        const defaultState = data?.filter((current) => current?.id == locationValue?.fk_state)
        const stateOption = defaultState?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return stateOption
    }

    console.log("designation", designation)

    const handleSubmit = async (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('name', docValues.name)
        formData.append('address', docValues.address)
        // formData.append('designation', item.vchr_name)
        if (designation != null && designation != "") {
            formData.append('fk_dept', designation)
        }
        if (docValues?.email) {
            formData.append('email', docValues?.email)
        }
        formData.append('main_experience', docValues.main_experience)
        formData.append('main_education', docValues.main_education)
        formData.append('phone_num', docValues.phone_num)
        formData.append('biography', docValues.biography)
        if (docValues?.doctor_img?.name) {
            formData.append('doctor_img', docValues.doctor_img)
        }

        formData.append('doctor_awards', JSON.stringify(newAwardArray))

        formData.append('experience', JSON.stringify(expArray))

        formData.append('education', JSON.stringify(educationArray))

        formData.append('doctor_services', JSON.stringify(facilities))

        formData.append('location', JSON.stringify(locationValue))
        formData.append('specialisation', JSON.stringify(speciality))
        {
            editId ?

                DoctorService.updateDoctor(editId, formData).then((res) => {
                    setEditModal(false)
                    setSuccessModal(true)
                    setIsUpdate(true)

                }).catch((err) => {
                    console.log(err)
                    setErrorModal(true)
                    setErrorMsg(err?.response?.data)
                })
                :
                DoctorService.addDoctorBySlug(formData)
                    .then((res) => {
                        console.log("response data", res);
                        setSuccessModal(true)
                        setDocValues({
                            name: '',
                            designation: '',
                            biography: '',
                            address: '',
                            main_education: '',
                            main_experience: '',
                            email: '',
                            phone_num: '',
                            doctor_img: null,
                        })

                        setNewAwardArray([])
                        setEducationArray([])
                        setExpArray([])
                        setFacilities([])
                        setSpeciality([])
                        setBlob(null)
                        resetFileInput()
                    }).catch((err) => {
                        console.log(err)
                        setErrorModal(true)
                        setErrorMsg(err?.response?.data)
                    })
        }

    }

    return (
        <div className="add-doc-form-main">
            <form action="" className='add-hos-form'
            // onSubmit={handleSubmit}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group ">
                            <label className='form-title'>
                                Doctor Name
                            </label>
                            <input
                                type="text"
                                className={errorMsg?.name ? "form-control error-input-box" : "form-control"}
                                name='name'
                                spellCheck={true}
                                required
                                value={docValues.name}
                                onChange={handleChange} />
                            {errorMsg?.name && <p className='error-title-p'>This field may not be empty</p>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>
                                Specification
                            </label>

                            <Select
                                options={allDepartment.data.map((depItem) => ({
                                    label: depItem?.vchr_name,
                                    value: depItem.id
                                }))}
                                ref={multiselectRef1}
                                value={getDefaultDep(allDepartment?.data)}
                                // getOptionLabel={designation}
                                // defaultInputValue={designation}
                                onChange={(event) => setDesignation(event?.value)} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>
                                Education
                            </label>
                            <input type="text"
                                className="form-control"
                                name='main_education'
                                required
                                spellCheck={true}
                                value={docValues.main_education}
                                onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>
                                Experience
                            </label>
                            <input type="text"
                                className="form-control"
                                name='main_experience'
                                required
                                spellCheck={true}
                                value={docValues.main_experience}
                                onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className='form-title'>
                                Contact No.
                            </label>
                            <input
                                type="tel"
                                className="form-control"
                                name='phone_num'
                                required
                                spellCheck={true}
                                value={docValues.phone_num}
                                onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className='form-title'>
                                Email Address
                            </label>
                            <input type="email"
                                className="form-control"
                                name='email'
                                required
                                spellCheck={true}
                                value={docValues.email}
                                onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Address</label>
                            <input type="text"
                                className="form-control"
                                name='address'
                                spellCheck={true}
                                required
                                value={docValues.address}
                                onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label className='form-title'>Location</label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='form-title'>Country</label>

                                    <Select
                                        options={allCountry.data.map((country) => ({
                                            label: country.vchr_name,
                                            value: country.id
                                        }))}
                                        value={getDefaultCountry(allCountry?.data)}
                                        ref={multiselectRef2}
                                        onChange={(event) => stateHandler(event?.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className='form-title'>State</label>

                                    <Select
                                        options={statesByCountry?.map((stateItem) => ({
                                            label: stateItem.vchr_name,
                                            value: stateItem.id
                                        }))}
                                        ref={multiselectRef3}
                                        autoFocus={false}
                                        value={getDefaultState(allState?.data)}
                                        onChange={(event) => locationHandler(event?.value)}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>About</label>
                            <input type="textarea"
                                className="form-control"
                                name='biography'
                                required
                                spellCheck={true}
                                value={docValues.biography}
                                onChange={handleChange} />
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>About</label>
                            <JoditEditor
                                ref={editor}
                                value={docValues?.biography}
                                config={Config}
                                tabIndex={4} // tabIndex of textarea
                                onChange={(e) => setDocValues({ ...docValues, biography: e })}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Education Details</label>
                            <div className="form-group">
                                <label>Collage/School</label>
                                <input type="text"
                                    className='form-control'
                                    name='collage_name'
                                    spellCheck={true}
                                    value={docEducation.collage_name}
                                    onChange={educationChange} />
                            </div>
                            <div className="form-group">
                                <label>Course</label>
                                <input type="text"
                                    className='form-control'
                                    name='course'
                                    spellCheck={true}
                                    value={docEducation.course}
                                    onChange={educationChange} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Year</label>
                            <input type="text"
                                className='form-control'
                                name='start_data'
                                value={docEducation.start_data}
                                onChange={educationChange} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        {/* <div className="form-group">
                            <label>End Year</label>
                            <input type="date"
                                className='form-control'
                                name='end_date'
                                value={docEducation.end_date}
                                onChange={educationChange} />
                        </div> */}
                        <button type="button"
                            className="btn btn-info"
                            onClick={educationSubmit}>Add</button>
                    </div>
                    {
                        educationArray?.length > 0 ?
                            <div className="col-md-6 mt-2">
                                <div className="form-group">
                                    <button type='button' onClick={() => setEduModalShow(true)} className='add-btn mb-3'>View Education Details  <span className='ml-2' style={{ fontWeight: '700' }}>({educationArray?.length})</span></button>
                                </div>
                            </div>
                            : ""
                    }



                    <div className="col-md-12">
                        <div className="form-group">
                            <label className='form-title'>Work Experience Details</label>
                            <div className="form-group">
                                <label>Worked in</label>
                                <input type="text"
                                    className='form-control'
                                    name='wrked_hosp'
                                    spellCheck={true}
                                    value={docExperience.wrked_hosp}
                                    onChange={ExperienceChange} />
                            </div>
                            <div className="form-group">
                                <label>Designation</label>
                                <input type="text"
                                    className='form-control'
                                    name='designation'
                                    spellCheck={true}
                                    value={docExperience.designation}
                                    onChange={ExperienceChange} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Year</label>
                            <input type="text"
                                className='form-control'
                                name='start_date'
                                value={docExperience.start_date}
                                onChange={ExperienceChange} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        {/* <div className="form-group">
                            <label>End Year</label>
                            <input type="date"
                                className='form-control'
                                name='end_date'
                                value={docExperience.end_date}
                                onChange={ExperienceChange} />
                        </div> */}
                        <button type="button"
                            className="btn btn-info"
                            onClick={addExperienceHandler}>Add</button>
                    </div>
                    {
                        expArray?.length > 0 ?
                            <div className="col-md-6 mt-2">
                                <div className="form-group">
                                    <button type='button' onClick={() => setExpModalShow(true)} className='add-btn mb-3'>View Experience Details  <span className='ml-2' style={{ fontWeight: '700' }}>({expArray?.length})</span></button>
                                </div>
                            </div>
                            : ""
                    }


                    <div className="col-md-12">
                        <label className='form-title'>Awards</label>
                        <div className="form-group">
                            <label htmlFor="">Award Title</label>
                            <input type="text"
                                className='form-control'
                                name='award_title'
                                spellCheck={true}
                                value={doctor_awards?.award_title}
                                onChange={awardChange} />
                            <label htmlFor="">Award Details</label>
                            <input type="text"
                                className='form-control'
                                name='award_detail'
                                spellCheck={true}
                                value={doctor_awards?.award_detail}
                                onChange={awardChange} />
                            <label htmlFor="">Year</label>
                            <input type="text"
                                className='form-control'
                                name='year'
                                value={doctor_awards?.year}
                                onChange={awardChange} />
                            <button type="button"
                                className="btn btn-info mt-3"
                                onClick={awardSubmit}>Add</button>
                        </div>
                        {
                            newAwardArray?.length > 0 ?
                                <div className="col-md-6 mt-2">
                                    <div className="form-group">
                                        <button type='button' onClick={() => setAwardModal(true)} className='add-btn mb-3'>View Award Details <span className='ml-2' style={{ fontWeight: '700' }}>({newAwardArray?.length})</span></button>
                                    </div>
                                </div>
                                : ""
                        }

                    </div>

                    <div className={editId ? "col-md-12 mt-3 align-div-doc" : "col-md-6 mt-3 align-div-doc"}>
                        <label className='form-title' htmlFor="">Specilizations</label>
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                            <Multiselect
                                options={allSpeciality.data}
                                displayValue={"name"}
                                placeholder="Select Speciality..."
                                avoidHighlightFirstOption={true}
                                selectedValues={speciality}
                                onRemove={(event) => setSpeciality(event)}
                                onSelect={(event) => setSpeciality(event)} />
                            {/* <Select
                                options={allSpeciality.data?.map((spec) => ({
                                    label: spec.name,
                                    value: spec.id
                                }))}
                                isMulti={true}
                                value={speciality}
                                closeMenuOnSelect={false}
                                onChange={(event) => setSpeciality(event)} /> */}
                        </div>
                    </div>


                    <div className={editId ? "col-md-12 mt-3 align-div-doc" : "col-md-6 mt-3 align-div-doc"}>
                        <label className='form-title' htmlFor="">Services</label>
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                            <Multiselect
                                options={allFacilities.data}
                                displayValue={"facility_name" || "label"}
                                placeholder="Select Service..."
                                selectedValues={facilities}
                                avoidHighlightFirstOption={true}
                                onRemove={(event) => setFacilities(event)}
                                onSelect={(event) => setFacilities(event)} />
                            {/* <Select
                                options={allFacilities.data?.map((fac) => ({
                                    label: fac.facility_name,
                                    value: fac.id
                                }))}
                                isMulti={true}
                                value={facilities}
                                closeMenuOnSelect={false}
                                onChange={(event) => setFacilities(event)} /> */}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className='form-title'>Add Doctor Image</label>
                        <input type="file"
                            className='form-control'
                            name='doctor_img'
                            accept="image/png, image/jpeg"
                            defaultValue={docValues?.doctor_img}
                            ref={inputRef}
                            onChange={doctorImgChange} />
                    </div>
                    {
                        (blob || docValues.doctor_img) &&
                        <div className='image-preivew-div'>
                            <img src={blob || docValues.doctor_img} alt="" />
                        </div>
                    }

                    <div className="add-new-submit-btn-div mt-3">
                        <button type='submit' className="submit-btn" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </form>

            {/* Error notification */}
            <ErrorNotification err={errorMsg} title="Doctor" setErrorModal={setErrorModal} errorModal={errorModal} />

            {/* Success notification */}
            <SuccessNotification title="Doctor" setSuccess={setSuccessModal} success={successModal} />

            {/* Modal section */}
            {/* Educational Modal */}
            {
                educationArray?.length != 0 &&
                <Modal className='edu-modal' show={eduModalShow} onHide={() =>
                    setEduModalShow(false)}>
                    <Modal.Header closeButton >
                        <Modal.Title className='profile-settings-modal-header'>
                            <h4>Educational Details</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-image-main-cont">
                            <EducationalListModal eduArray={educationArray} setEduArray={setEducationArray} />
                        </div>
                    </Modal.Body>
                </Modal>
            }

            {/* Experience list modal */}
            {
                expArray?.length != 0 &&
                <Modal show={expModalShow} onHide={() =>
                    setExpModalShow(false)}>
                    <Modal.Header closeButton >
                        <Modal.Title className='profile-settings-modal-header'>
                            <h4>Experience Details</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-image-main-cont">
                            <ExperienceModal setExperienceArray={setExpArray} experienceArray={expArray} />
                        </div>
                    </Modal.Body>
                </Modal>
            }

            {/* Award Modal */}

            {
                newAwardArray?.length != 0 &&
                <Modal show={awardModal} onHide={() =>
                    setAwardModal(false)}>
                    <Modal.Header closeButton >
                        <Modal.Title className='profile-settings-modal-header'>
                            <h4>Award Details</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-image-main-cont">
                            <AwardListModal setAwrdArray={setNewAwardArray} awardArray={newAwardArray} />
                        </div>
                    </Modal.Body>
                </Modal>
            }


        </div>
    )
}

export default DoctorFormPage