import requests from "./httpServices";

const FacilityService = {
    addFacility(body) {
        return requests.post('/hospital/facility/', body)
    },
    allFacility() {
        return requests.get('/hospital/facility/')
    },
    updateFacility(id, body) {
        return requests.put(`/hospital/facility/${id}/`, body)
    },
    deleteFacility(id, body) {
        return requests.delete(`/hospital/facility/${id}/`, body)
    },
    getFacilityByPage(page) {
        return requests.get(`/hospital/facility/?page=${page}`)
    }
}

export default FacilityService