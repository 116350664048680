import React from 'react'
import SideBarNav from '../sidebar'
import EnquiryTable from './EnquryTable'
import { useLocation } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';

function Enquiry() {
    const location = useLocation();
    const item = location.state

    const [pageId, setPageId] = useState('')

    useEffect(() => {
        if (item?.item) {
            setPageId(item?.item)
        }
    }, [item?.item])


    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="adm-enq-main-div">
                    <div className="container-fluid">
                        {/* <div className="adm-enq-header-div">
                            <button className='btn btn-info'>Add</button>
                        </div> */}
                        <EnquiryTable pageId={pageId} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Enquiry