import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import SpecialityServices from '../../../services/SpecialityServices';


const EditSpecialityModal = ({ specialityValues, setEditData }) => {
    const [editName, setEditName] = useState([{
        id: '',
        name: '',
    }])

    const editDataId = specialityValues.editeddata[0].id
    var defaultValueName = specialityValues.editeddata[0].name

    // console.log('modal value', editDataId)

    const editChange = (e) => {
        setEditName({ id: editDataId, name: e.target.value })

    }
    console.log("state", editName)

    // const editSubmit = async (e) => {
    //     e.preventDefault()
    //     await SpecialityServices.updateSpeciality(editName.id, editName.name).then((res) => console.log('final data', res))
    // }

    // console.log('edited name', editName)

    return (

        <div className="modal-content doctor-profile">
            <div className="modal-body">
                <form action=""
                // onSubmit={editSubmit}
                >
                    <div className="add-wrap">
                        <div className="form-group form-focus">
                            <input type="text"
                                name='name'
                                required
                                // id={editDataId}
                                className="form-control"
                                defaultValue={defaultValueName}
                            // onChange={editChange}
                            />
                            <label className="focus-label">Speciality Name<span className="text-danger">*</span></label>
                        </div>
                        <div className="change-photo-btn">
                            <div><i className=''><FeatherIcon icon="upload" /></i>
                                <p>Upload File</p></div>
                            <input type="file" className="upload" />
                        </div>
                        <div className="submit-section">
                            <button type="submit" className="btn btn-primary btn-save">Add</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditSpecialityModal