import requests from "./httpServices";

const AdminServices = {
    adminLogin(body) {
        return requests.post('/user_login/login/', body)
    },
    addAdmin(body) {
        return requests.post('/user_login/admin/', body)
    },
    updateAdmin(id, body) {
        return requests.put(`/user_login/admin/${id}/`, body)
    },
    getAdminById(id) {
        return requests.get(`/user_login/admin/${id}/`)
    },
    getRegister() {
        return requests.get('user_login/admin/')
    },
    deleteAdmin(id) {
        return requests.delete(`/user_login/admin/${id}/`)
    },
    getAllUserDetails() {
        return requests.get('/enquiry/patient/')
    },
    getUserDetailsById(id) {
        return requests.get(`/enquiry/patient/${id}/`)
    },
    deleteUserDetail(id) {
        return requests.delete(`/enquiry/patient/${id}/`)
    }
}
export default AdminServices