import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Sidebar from '../sidebar';
import FeatherIcon from 'feather-icons-react';
import BannerTable from './BannerTable';
import { Modal } from 'react-bootstrap';
import AddBannerModal from './AddBannerModal';
import AddSliders from './AddSliders';

const BannerPage = () => {
    const [addModal, setAddModal] = useState(false);
    const [addSliderModal, setAddSliderModal] = useState(false)
    const [id, setId] = useState()
    const [sliderEditId, setSliderEditId] = useState('')
    const [bannerValue, setBannerValue] = useState('')

    return (
        <>
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-md-12 d-flex justify-content-end">
                                <div className="doc-badge me-3">
                                    Banners
                                </div>
                                {/* <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#addModal"
                                    className="btn btn-primary btn-add"
                                    onClick={() => setAddModal(true)}
                                >
                                    <i className='me-1'><FeatherIcon icon="plus-square" /></i>
                                    Add New
                                </button> */}

                                <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#addModal"
                                    className="btn btn-secondary btn-add ml-3"
                                    onClick={() => setAddSliderModal(true)}
                                >
                                    <i className='me-1'><FeatherIcon icon="plus-square" /></i>
                                    Add Sliders
                                </button>

                            </div>
                        </div>
                    </div>

                    {/* Banners */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header border-bottom-0">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="card-title">Banners</h5>
                                        </div>
                                        <div className="col-auto d-flex flex-wrap">
                                            <div className="form-custom me-2">
                                                <div id="tableSearch" className="dataTables_wrapper" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <BannerTable setId={setId} id={id} setSliderEditId={setSliderEditId}
                                    setSliderModal={setAddSliderModal} setBannerModal={setAddModal} setBannerValue={setBannerValue} />

                            </div>
                            <div id="tablepagination" className="dataTables_wrapper" />
                        </div>
                    </div>

                </div>
            </div>

            {/* add modal */}
            <Modal show={addModal} onHide={() =>
                setAddModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>{bannerValue ? "Edit" : "Add"} Banner </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <AddBannerModal setModal={setAddModal} bannerValue={bannerValue} setBannerValue={setBannerValue} />
                    </div>
                </Modal.Body>
            </Modal>

            {/* add modal */}
            <Modal show={addSliderModal} onHide={() =>
                setAddSliderModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>{sliderEditId ? "Edit" : "Add"} Home Sliders </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <AddSliders setModal={setAddSliderModal} sliderEditId={sliderEditId} setSliderEditId={setSliderEditId} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BannerPage