import React from 'react'
import SideBarNav from '../sidebar/index'
import FeatherIcon from 'feather-icons-react';
import { avatar02, avatar03, avatar04, avatar05, avatar06, avatar07, avatar08, avatar09, avatar10, avatar11, avatar12, avatar13, calender, cardio, chart, dental, flag01, flag02, flag03, flag04, flag05, neurology, ortho, urology } from '../imagepath';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DashTopCards from './DashTopCards';
import TodayAppointment from '../dashboard/TodayAppointment/TodayAppointment';
import RecentActivities from '../dashboard/RecentActivities/RecentActivities';
import TopDoctors from './TopDoctors/TopDoctors';
import Consultation from '../dashboard/Consultation/Consultation';
import TopHospitals from './TopDoctors/TopHospitals';
import TopPackages from './TopDoctors/TopPackages';

const NewDashBoard = () => {

    const settings = {
        loop: false,
        margin: 15,
        autoWidth: true,
        dots: false,
        nav: true,
        navContainer: '.slide-nav-3',
        // navText: ['<i class="fas fa-chevron-left custom-arrow"></i>', '<i class="fas fa-chevron-right custom-arrow"></i>'],
        responsive: {
            0: {
                items: 1
            },
            500: {
                items: 1
            },
            768: {
                items: 2
            },
            1350: {
                items: 4
            }
        }
    }

    return (
        <>
            <div className="main-wrapper">
                <SideBarNav />
                <div className="page-wrapper">
                    <div className="content container-fluid pb-0">
                        <h4 className="mb-3">Overview</h4>

                        {/* First container */}
                        <OwlCarousel
                            className="owl-carousel appointment-slider owl-theme"
                            {...settings}>
                            <DashTopCards />
                        </OwlCarousel>

                        {/* Second container */}

                        <div className="row">
                            <TodayAppointment />
                        </div>
                        <div className="row">
                            <TopDoctors title="Doctors" />
                            <TopHospitals title="Hospitals" />
                            <TopPackages title="Packages" />
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default NewDashBoard