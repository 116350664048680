import React, { useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { Media } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from '../../../hooks/useAsync'
import FaqService from '../../../services/FaqService'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'
import { useState } from 'react'
import DeleteModal from '../Modal/DeleteModal'
import { ComponentToPrint } from '../../assets/constant/ReactPdf'
import { useReactToPrint } from 'react-to-print';
import { FcPrint, FcDownload } from 'react-icons/fc'
import { Modal } from 'react-bootstrap'
import CheckModal from '../../assets/constant/CheckModal'

export const CommonFaq = ({ setEditId, setFormPage }) => {
    const { data } = useAsync(FaqService.allCommonFaq)
    const { setIsUpdate } = useContext(UserContext)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()
    const [isPrint, setIsPrint] = useState(false)
    const [checkModal, setCheckModal] = useState(false)
    const componentRef = useRef();

    const afterPrint = () => {
        setIsPrint(false)
        setCheckModal(false)
    }

    const printData = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Faq Details",
        onAfterPrint: () => afterPrint(),


    })

    const deleteModalShow = (id, name) => {
        setDeleteModal(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const deleteHandler = () => {
        FaqService.deleteCommonFaq(deleteId).then((res) => {
            alert("Deleted Successfully")
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    const updateModal = (id) => {
        setEditId(id)
        setFormPage("addCommonFaq")

    }

    const checkHandler = () => {
        setIsPrint(true)
        setCheckModal(true)
    }

    const columns = [
        {
            name: 'Id',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },

        {
            name: 'User Name',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar">
                <Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#doctorlist" data-bs-toggle="modal">
                    {/* <img className="avatar avatar-img" src={row.user_image} alt="" /> */}
                </Link></h2>
                <NavLink to="#"
                    data-bs-target="#doctorlist"
                    data-bs-toggle="modal"
                >
                </NavLink><span className="user-name">{row.dct_faq_qst}</span></Media.Body></Media>,
            width: "450px",

        },

        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                {/* <Link
                   
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    // onClick={() => ListModalShow(row.id, row.name)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link> */}
                {
                    !isPrint &&

                    <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        style={{ color: 'green' }}
                        onClick={() => updateModal(row.id)}
                    >
                        <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                    </Link>
                }
                {
                    !isPrint &&

                    <Link
                        className="text-danger"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        onClick={() => deleteModalShow(row.id, row.dct_faq_qst)}

                    >
                        <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                    </Link>
                }
            </div>,
            width: "250px",

        },

    ];

    const tableData = {
        columns,
        data,

    };

    return (
        <div className="card-body p-0" style={{ width: '100%' }} ref={componentRef}>
            <div className="table-btn-header-div">
                <button className='print-btn'
                    data-toggle="tooltip" data-placement="top" title="Print"
                    onClick={checkHandler}><FcPrint /></button>
                {/* <button className='dwnld-btn'
                    data-toggle="tooltip" data-placement="top" title="Download"
                    onClick={printData}><FcDownload /></button> */}
            </div>
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable

                        noHeader
                        actions={<button className='btn btn-info'>Print</button>}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* Delete modal */}

            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName} />

            {/* Print Modal */}

            <Modal show={checkModal} onHide={() =>
                setCheckModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Confirm Print </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CheckModal handler={printData} setModal={setCheckModal} />
                </Modal.Body>
            </Modal>

        </div>
    )
}
