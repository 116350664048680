import React, { useEffect, useState } from 'react';
import SidebarNav from "../sidebar";
import { Link } from "react-router-dom";
import { avatar02, avatar03, avatar04, avatar05, avatar07, avatar08, avatar10, blog01, blog02, blog03, blog04, blog06, blog07, blog08, blog09, eyeIcon } from '../imagepath';
import FeatherIcon from 'feather-icons-react';
import BlogList from './BlogList';
import EditBlog from './editblog';
import { Modal } from 'react-bootstrap';
import BlogTable from './BlogTable';

const Blog = () => {
  const [blogForm, setBlogForm] = useState(false)
  const [editId, setEditId] = useState('')
  const pageHandler = () => {
    setBlogForm(!blogForm)
  }

  const modalHandler = () => {
    setEditId('')
    setBlogForm(true)
  }

  console.log("glog form", blogForm)
  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="package-header-div">
            <button type="button"
              className="btn btn-info"
              onClick={modalHandler}>Add Blog</button>
          </div>
          {/* <BlogList /> */}
          <div className="adm-package-body-div">
            <BlogTable setEditId={setEditId} setBlogForm={setBlogForm} />
          </div>

        </div>
      </div>
      {/* Blog add */}
      <EditBlog setBlogForm={setBlogForm} blogForm={blogForm} editId={editId} />

    </>

  )
}

export default Blog