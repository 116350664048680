import React from 'react'

const CheckModal = ({ setModal, handler }) => {
    return (
        <div className="modal-image-main-cont">
            <div className='onfirm-delete-main-div'>
                <p>Do you want to Print <span style={{ color: '#98181e', fontWeight: 'bold' }}></span> ? </p>
                <div className="confirm-delete-btn-div">
                    <button
                        type="button" className="btn btn-success"
                        onClick={handler}>Print</button>
                    <button
                        type="button" className="btn btn-secondary ml-3"
                        onClick={() => setModal(false)}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CheckModal