import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SidebarNav from '../sidebar'
import FeatherIcon from 'feather-icons-react';
import useAsync from '../../../hooks/useAsync';
import { sort } from '../imagepath'
import FacilitiesTable from './FacilitiesTable';
import FacilityMainModal from './FacilityMainModal';
import FacilityService from '../../../services/FacilityServices';

const Facilities = () => {
    const { data } = useAsync(FacilityService.allFacility)
    const [show1, setShow1] = useState(false);
    const [id, setId] = useState()
    const toggleFilterMenu1 = () => {
        // console.log(show1)
        // setShow1(!show1)
    }

    const handleShow = () => {
        setShow1(true)
    }
    return (
        <>
            <SidebarNav />
            <div className='page-wrapper'>

                <div className="content container-fluid">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col-md-12 d-flex justify-content-end">
                                <div className="doc-badge me-3">
                                    Facilities <span className="ms-1">{data.length}</span>
                                </div>
                                <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addModal"
                                    className="btn btn-primary btn-add"
                                    onClick={handleShow}
                                >
                                    <i className='me-1'><FeatherIcon icon="plus-square" /></i>
                                    Add New
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    {/* Specialities */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header border-bottom-0">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="card-title">Facilities</h5>
                                        </div>
                                        <div className="col-auto d-flex flex-wrap">
                                            <div className="form-custom me-2">
                                                <div id="tableSearch" className="dataTables_wrapper" />
                                            </div>
                                            <div className="SortBy">
                                                <div className="selectBoxes order-by">
                                                    <p className="mb-0"
                                                    // onClick={(defaultValue) => toggleFilterMenu1()}
                                                    >
                                                        <img
                                                            src={sort}
                                                            className="me-2"
                                                            alt="icon"
                                                        />{" "}
                                                        Order by{" "}
                                                    </p>
                                                    <span className="down-icon">
                                                        <i className=''><FeatherIcon icon="chevron-down" /></i>

                                                    </span>
                                                </div>
                                                <div id="checkBox">
                                                    <form action="/admin/specialities">
                                                        <p className="lab-title">Order By </p>
                                                        <label className="custom_radio w-100">
                                                            <input type="radio" name="order" />
                                                            <span className="checkmark" /> ID
                                                        </label>
                                                        <label className="custom_radio w-100 mb-4">
                                                            <input type="radio" name="order" />
                                                            <span className="checkmark" /> Date Modified
                                                        </label>
                                                        <p className="lab-title">Sort By</p>
                                                        <label className="custom_radio w-100">
                                                            <input type="radio" name="sort" />
                                                            <span className="checkmark" /> Ascending
                                                        </label>
                                                        <label className="custom_radio w-100 mb-4">
                                                            <input type="radio" name="sort" />
                                                            <span className="checkmark" /> Descending
                                                        </label>
                                                        <button type="submit" className="btn w-100 btn-primary">
                                                            Apply
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FacilitiesTable setId={setId} id={id} />

                            </div>
                            <div id="tablepagination" className="dataTables_wrapper" />

                        </div>
                    </div>
                    {/* /Specialities */}
                </div>
                {/* Modal */}
                <FacilityMainModal setShow1={setShow1} show1={show1} title='Facility' />
            </div>
        </>
    )
}

export default Facilities