import requests from "./httpServices";

const FaqService = {
    addCommonFaq(body) {
        return requests.filePost('/user_login/faq/', body)
    },
    allCommonFaq() {
        return requests.get('/user_login/faq/')
    },
    updateCommonFaq(id, body) {
        return requests.filePut(`/user_login/faq/${id}/`, body)
    },
    deleteCommonFaq(id) {
        return requests.delete(`/user_login/faq/${id}/`)
    },
    getCommonFaqById(id) {
        return requests.get(`/user_login/faq/${id}/`)
    },
    addHospitalFaq(body) {
        return requests.filePost('/hospital/faq/', body)
    },
    allHospitalFaq() {
        return requests.get('/hospital/faq/')
    },
    getHospitalFaqById(id) {
        return requests.get(`/hospital/faq/${id}/`)
    },
    updateHospitalFaq(id, body) {
        return requests.filePut(`/hospital/faq/${id}/`, body)
    },
    deleteHospitalFaq(id) {
        return requests.delete(`/hospital/faq/${id}/`)
    },
    addDoctorFaq(body) {
        return requests.filePost('/doctor/faq/', body)
    },
    allDoctorFaq() {
        return requests.get('/doctor/faq/')
    },
    getDoctorFaqById(id) {
        return requests.get(`/doctor/faq/${id}/`)
    },
    updateDoctorFaq(id, body) {
        return requests.filePut(`/doctor/faq/${id}/`, body)
    },
    deleteDoctorFaq(id) {
        return requests.delete(`/doctor/faq/${id}/`)
    }
}

export default FaqService