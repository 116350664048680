import React, { useContext, useEffect, useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react';
import useAsync from '../../../hooks/useAsync';
import SpecialityServices from '../../../services/SpecialityServices';
import { UserContext } from '../../../Context/AllContext';
import axios from 'axios';
import FileInput from '../Common/FileInput/FileInput';
import ImageCropper from '../Common/Cropper/ImageCropper';
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from 'jodit-react';
import Config from '../../assets/constant/WritingPadConfig'
import Select from 'react-select'
import DepartmentService from '../../../services/DepartmentService';

const AddSpecialityModal = ({ setShow1, id, editItemName }) => {
    // const { data, error, loading } = useAsync(SpecialityServices.addSpeciality)
    const { isUpdate, setIsUpdate } = useContext(UserContext)
    const allDepartments = useAsync(DepartmentService.allDepartment)
    const [specialityValues, setSpecialityValues] = useState({
        name: '',
        image: null,
        department: '',
    })
    const [editorBody, setEditorBody] = useState('')
    const [blob, setBlob] = useState(null)
    const editor = useRef(null);
    const depRef = useRef(null)

    console.log("speciality values", specialityValues)

    useEffect(() => {
        if (id) {
            SpecialityServices.getSpecialityById(id)
                .then((res) => {
                    console.log('specId', res)
                    setSpecialityValues({
                        name: res.name,
                        image: res.image,
                        department: res?.department?.id
                    })
                    setEditorBody(res.body)
                })
                .catch((err) => {
                    console.log('specErr', err)
                })
        }
    }, [id])

    const getDefaultDep = (data) => {
        const defaultDep = data?.filter((current) => current?.id == specialityValues.department?.id || current?.id == specialityValues.department)
        console.log("default department", defaultDep)
        const depOption = defaultDep?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return depOption
    }

    console.log("DEPARTMENT", specialityValues.department)

    const resetFileInput = () => {
        depRef.current.clearValue()
    };

    console.log("department......!", specialityValues.department)

    const specialitySubmit = (e) => {
        e.preventDefault()
        let formData = new FormData();
        // if (specialityValues?.image?.name) {
        //     console.log('img', specialityValues.image)
        //     formData.append('image', specialityValues.image)
        // }

        formData.append('name', specialityValues.name)
        // if (id && specialityValues?.department != []) {
        //     formData.append('department', specialityValues.department?.id)
        // } else if (specialityValues?.department != '') {

        //     formData.append('department', specialityValues.department)
        // }

        if (specialityValues?.department) {
            formData.append('department', specialityValues.department)
        }


        // formData.append('body', JSON.stringify(editorBody))
        formData.append('body', editorBody)

        {
            id ?
                SpecialityServices.updateSpeciality(id, formData).then((res) => {
                    console.log('edit data res', res)
                    setShow1(false)
                    alert(`${res.name} Updated Successfully`)
                    setIsUpdate(true)
                    resetFileInput()
                }).catch((err) => console.log('err', err))
                :

                SpecialityServices.addSpeciality(formData)
                    .then(res => {
                        console.log(res);
                        setShow1(false)
                        alert("successfully")
                        resetFileInput()
                        setIsUpdate(true)
                    })
                    .catch(err => console.log(err))
        }
    }
    console.log("name :", blob)


    return (

        <div className="modal-content doctor-profile">
            <div className="modal-body">
                <form action=""
                    onSubmit={specialitySubmit}
                // onSubmit={handleSubmit}
                >
                    <div className="add-wrap">
                        <div className="form-group form-focus">
                            <input type="text"
                                name='name'
                                required
                                className="form-control"
                                value={specialityValues.name}
                                onChange={(e) => setSpecialityValues({ ...specialityValues, [e.target.name]: e.target.value })}
                            />
                            <label className="focus-label">Speciality Name<span className="text-danger">*</span></label>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className='form-title'>Select Department</label>
                                <Select
                                    options={allDepartments.data.map((depItem) => ({
                                        label: depItem?.vchr_name,
                                        value: depItem.id
                                    }))}
                                    name="department"
                                    ref={depRef}
                                    value={getDefaultDep(allDepartments?.data)}
                                    onChange={(event) => setSpecialityValues({ ...specialityValues, department: event?.value })} />
                            </div>
                        </div>


                        <div className="col-md-12">
                            <div className="form-group ">
                                <label className='form-title'>
                                    OverView
                                </label>
                                <JoditEditor
                                    ref={editor}
                                    value={editorBody}
                                    config={Config}
                                    tabIndex={4}
                                    onChange={(e) => setEditorBody(e)}
                                />
                            </div>
                        </div>

                        <div className="submit-section">
                            <button type="submit" className="btn btn-primary btn-save mt-5">
                                {id ? "Submit" : "Add"}
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default AddSpecialityModal