import requests from "./httpServices";

const LocationService = {
    allCountry() {
        return requests.get('/user_login/country/')
    },
    addCountry(body) {
        return requests.filePost('/user_login/country/', body)
    },
    allState() {
        return requests.get('/user_login/state/')
    },
    addState(body) {
        return requests.filePost('/user_login/state/', body)
    }
}

export default LocationService