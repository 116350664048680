import React from 'react'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import BookingService from '../../../services/BookingService'
import PackageService from '../../../services/PackageService'

function BookingModal({ viewBook, setViewBook, viewId, isPackage=false }) {
    const [bookDetails, setBookDetails] = useState()

    useEffect(() => {
        if (viewId) {
            if(isPackage){
                PackageService.getPackageBookingById(viewId).then((res)=>{
                    setBookDetails(res.results)
                })
            }else{

                BookingService.getBookingById(viewId).then((res) => {
                    setBookDetails(res)
                })
            }
        }
    }, [viewId])

    console.log("view booking details", bookDetails)


    return (
        <Modal show={viewBook} onHide={() =>
            setViewBook(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>Booking Details </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <div className='container'>
                        <div className="row">
                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Name:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{isPackage? bookDetails?.name : bookDetails?.patient_name}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Email:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{bookDetails?.email}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Phone:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{isPackage ? bookDetails?.phone_number : bookDetails?.phone_no}</h6>
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Booking {isPackage ? "Package" : (bookDetails?.doctor ? "Doctor" : "Hospital")}:</h6>
                                </div>
                                <div className="col-md-9">
                                    {
                                        isPackage ? bookDetails?.HospitalProcedure_fk?.package__name : (
                                            bookDetails?.hospital ?
                                                <h6>{bookDetails?.hospital.name}</h6>
                                                :
                                                <h6>{bookDetails?.doctor.name}</h6> )

                                    }
                                </div>
                            </div>

                            <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Symptoms:</h6>
                                </div>
                                <div className="col-md-9">
                                    <h6>{bookDetails?.symptoms || 'Nil'}</h6>
                                </div>
                            </div>

                            {/* <div className="row col-md-12">
                                <div className="col-md-3">
                                    <h6>Files:</h6>
                                </div>
                                <div className="col-md-9">
                                    <div className='enq-view-img-div'>

                                        <img src={image_list[0]} alt="" />
                                    </div>

                                </div>
                            </div> */}



                        </div>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BookingModal