import React, { useEffect, useState } from 'react';
import SidebarNav from "../sidebar";
import { Link } from "react-router-dom";
import { avatar05 } from '../imagepath';
import { Modal } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import SelectField from '../commoncomponent/selectfield';
import useAsync from '../../../hooks/useAsync';
import SpecialityServices from '../../../services/SpecialityServices';
import { faDAndDBeyond } from '@fortawesome/free-brands-svg-icons';
import BlogServices from '../../../services/BlogServices';
import DepartmentService from '../../../services/DepartmentService';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';
import { useRef } from 'react';
import JoditEditor from 'jodit-react';
import Config from '../../assets/constant/WritingPadConfig';
import Select from 'react-select'
import ErrorNotification from '../Notifications/ErrorNotification';

const EditBlog = ({ setBlogForm, blogForm, editId }) => {
  console.log("edit id in edit blof", editId)
  const allDepartment = useAsync(DepartmentService.allDepartment)
  const { setIsUpdate } = useContext(UserContext)

  const blogType = [
    { label: "Select Blog Type", value: 'Blog Type' },
    { label: "Standard Blog", value: 'Standard' },
    { label: "Video Blog", value: 'Video' },
  ];
  const [blogValues, setBlogValues] = useState({
    title: '',
    blog_type: '',
    blog_answer: '',
    fk_dept: 'Select any Department...',
    image: ''
  })

  const [blob, setBlob] = useState(null)
  const [errorModal, setErrorModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const imageRef = useRef(null)
  const editor = useRef(null)
  // let errorMsg

  useEffect(() => {
    if (editId != "") {
      BlogServices.getBlogById(editId).then((res) => {
        console.log("edit blog data", res.results)
        setBlogValues({
          title: res?.results?.title,
          blog_type: res?.results?.blog_type,
          blog_answer: res?.results?.blog_answer,
          fk_dept: res?.results?.fk_dept,
          image: res?.results?.image
        })

      })
    }
    //  else {
    //   setBlogValues({
    //     title: '',
    //     blog_type: '',
    //     blog_answer: '',
    //     fk_dept: 'Select any Department...',
    //     image: ''
    //   })
    // }
  }, [editId])


  const valueChange = (e) => {
    setBlogValues({ ...blogValues, [e.target.name]: e.target.value })
  }

  const blogImageChange = (e) => {
    // console.log('eee',e.target.files[0])
    setBlogValues({ ...blogValues, image: e.target.files[0] })
    setBlob(URL.createObjectURL(e.target.files[0]))
  }

  // console.log("blog values :", blogValues)

  const getDefaultDep = (data) => {
    const defaultDepa = data?.filter((current) => current?.id == blogValues?.fk_dept)
    const depOption = defaultDepa?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))
    return depOption
  }

  const blogSubmit = (e) => {
    e.preventDefault()
    if(!blogValues.blog_type){
      setErrorMsg({message:'Blog type'})
      // console.log('ERRmeg',errorMsg)
      setErrorModal(true)
      return
    }
    let formData = new FormData()
    formData.append('title', blogValues.title)
    formData.append('blog_answer', blogValues.blog_answer)
    formData.append('blog_type', blogValues.blog_type)
    formData.append('fk_dept', blogValues.fk_dept)
    if(blob){
      formData.append("image", blogValues?.image);
    }

    {
      editId ?
        BlogServices.updateBlog(editId, formData).then((res) => {
          // console.log("response", res)
          alert("Blog updated Successfully")
          setIsUpdate(true)
          setBlogForm(false)
          setBlogValues({
            title: '',
            blog_type: '',
            blog_answer: '',
            fk_dept: 'Select any Department...',
            image: ''
          })
        })
        :

        BlogServices.addBlog(formData).then((res) => {
          // console.log("response", res)
          alert("Blog added Successfully")
          setIsUpdate(true)
          setBlogForm(false)
          setBlogValues({
            title: '',
            blog_type: '',
            blog_answer: '',
            fk_dept: 'Select any Department...',
            image: ''
          })
        })
    }

  }
  const handleClose = () => {
    setBlogForm(false)
    setBlogValues({
      title: '',
      blog_type: '',
      blog_answer: '',
      fk_dept: 'Select any Department...',
      image: ''
    })
  }

  return (
<>
    <Modal show={blogForm} onHide={handleClose}>
      <Modal.Header closeButton >
        <Modal.Title className='profile-settings-modal-header'>
          <h4>{editId ? "Edit" : "Add"} Blog</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <form action="" onSubmit={blogSubmit}>
            <div className="add-wrap">
              <div className="form-group form-focus">
                <input type="text"
                  name='title'
                  defaultValue={editId && blogValues.title}
                  className="form-control"
                  onChange={valueChange}
                />
                <label className="focus-label">Blog Title<span className="text-danger">*</span></label>
              </div>

              <div className="col-md-12">
                <div className="form-group ">
                  <label className='form-title'>
                    Blog Content
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={blogValues.blog_answer}
                    config={Config}
                    tabIndex={4}
                    onChange={(e) => setBlogValues({ ...blogValues, blog_answer: e })}
                  />
                </div>
              </div>


              <div className="form-group">
                <select
                  name='blog_type'
                  className='form-control'
                  value={editId && blogValues.blog_type}
                  onChange={valueChange}
                >
                  {
                    blogType.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>{item.label}</option>
                      )
                    })
                  }
                </select>
                {/* <label className="focus-label">Blog Type<span className="text-danger">*</span></label> */}
              </div>

              <div className="form-group form-focus">
                {/* <select name="fk_dept"
                  className='form-control'
                  defaultValue={editId && blogValues.fk_dept}
                  onChange={specilisationChange}  >
                  {
                    allDepartment?.data?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.vchr_name}</option>
                      )
                    })
                  }
                </select> */}

                <Select
                  options={allDepartment?.data?.map((dep) => ({
                    label: dep?.vchr_name,
                    value: dep?.id
                  }))}
                  value={getDefaultDep(allDepartment?.data)}
                  onChange={(event) => setBlogValues({ ...blogValues, fk_dept: event?.value })}
                />
              </div>



              <div className="form-group multi-preview">
                {(blob || blogValues?.image) && <img src={blob || blogValues?.image} width={50} height={150} />}
              </div>

              <div className="change-photo-btn">
                <div><i className=''><FeatherIcon icon="upload" /></i>
                  <p>Upload File</p></div>
                <input type="file"
                  className="upload"
                  name='image'
                  accept="image/png, image/jpeg"
                  ref={imageRef}
                  // defaultValue={editId ? images : ""}
                  onChange={blogImageChange}
                />

              </div>
              <div className="submit-section">
                <button type="submit" className="btn btn-primary btn-save">
                  {/* {id ? "Edit" : "Add"} */}
                  {editId ? "Save Changes" : "Add"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
    <ErrorNotification err={errorMsg} title="Blog" setErrorModal={setErrorModal} errorModal={errorModal} />
    </>
  )
}

export default EditBlog