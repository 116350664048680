import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { MdDesignServices } from "react-icons/md";
import { UserContext } from "../../../Context/AllContext";
import useAsync from "../../../hooks/useAsync";
import DepartmentService from "../../../services/DepartmentService";
import TreatmentService from "../../../services/TreatmentService";
import Select from 'react-select'
import { BsPlusLg } from 'react-icons/bs'
import { useRef } from "react";
import JoditEditor from 'jodit-react';
import Config from '../../assets/constant/WritingPadConfig'
import DoctorService from "../../../services/DoctorService";
import LoadingSpinner from "../Loading/LoadingSpinner";
import Notify from "../Notifications/Notify";

const TreatmentModal = ({ formModal, setFormModal, mainId }) => {
    const { setIsUpdate } = useContext(UserContext)
    const allDesignations = useAsync(DepartmentService.allDepartment)
    const allDoctors = useAsync(DoctorService.allDoctorList)
    const [values, setValues] = useState({
        title: '',
        subtitle: '',
        icon_image: '',
        image: '',
        department_id: '',
        overView: '',
        body: ''
    })
    const [docs, setDocs] = useState([])
    const [blob, setBlob] = useState('')
    const [blob2, setBlob2] = useState('')
    const [counter, setCounter] = useState(1);
    const departmentRef = useRef(null)
    const docRef = useRef(null)
    const image1Ref = useRef(null)
    const image2Ref = useRef(null)
    const [loading, setLoading] = useState(false)
    const [editId, setEditId] = useState('')
    const [showNotify, setShowNotify] = useState(false)
    const [notifyErr, setNotifyErr] = useState(false)
    const [notifyMsg, setNotifyMsg] = useState('')

    console.log("main id..", mainId)
    console.log("blob.....", blob)
    console.log("blob222.....", blob2)


    useEffect(() => {
        console.log('before useeffect', mainId)
        if (mainId) {
            console.log("mainnnn", mainId)
            setValues({
                title: mainId?.title,
                subtitle: mainId?.subtitle,
                overView: mainId?.overview,
                ...mainId?.department_id && {department_id: mainId?.department_id[0]?.id},
                body: mainId?.body
            })
            setDocs(mainId?.doctor_id)
            setEditId(mainId?.id)
            setBlob(mainId?.image)
            setBlob2(mainId?.icon_image)
            setDocs(mainId?.doctor_id)
        }
        else {
            setValues({
                title: '',
                subtitle: '',
                icon_image: '',
                image: '',
                doctor_id: '',
                department_id: '',
                overView: '',
                body: ''
            })
            setDocs([])
            setBlob("")
            setBlob2("")
        }



    }, [mainId])


    const editor = useRef(null)

    console.log("values....", values)
    const valueChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const depChange = (event) => {
        console.log('dep values', event)
        setValues({ ...values, subtitle: event.label, department_id: event.value })
    }

    const docChange = (event) => {
        event?.map((item) => {

            setDocs([...docs, item])
        })

    }

    console.log("docs", docs)

    const image1Change = (e) => {
        setValues({ ...values, image: e.target.files[0] })
        setBlob(URL.createObjectURL(e.target.files[0]))
    }

    const image2Change = (e) => {
        setValues({ ...values, icon_image: e.target.files[0] })
        setBlob2(URL.createObjectURL(e.target.files[0]))
    }
    console.log(values.subtitle)


    const getDefaultDep = (data) => {
        const defaultDep = data?.filter((current) => current?.id == values?.department_id)
        const depOption = defaultDep?.map((opt) => ({ label: opt?.vchr_name, value: opt?.id }))

        return depOption
    }

    let docOption = []

    const valuesSubmit = (e) => {
        e.preventDefault()
        if(docs.length == 0){
            setShowNotify(true)
            setNotifyErr(true)
            setNotifyMsg('Doctor is required')
            return
        }
        let formData = new FormData()
        if (values?.title) {
            formData.append('title', values.title)
        }
        formData.append('subtitle', values.subtitle)
        if (values?.image) {
            formData.append('image', values.image)
        }
        if (values?.icon_image) {
            formData.append('icon_image', values.icon_image)
        }
        if (values?.department_id) {
            formData.append('department_id', values.department_id)
        }

        for (const doc of docs) {
            formData.append("doctor_id", doc?.id);

        }

        formData.append('overview', values.overView)
        formData.append('body', values.body)
        {
            mainId ?
                TreatmentService.updateTreatment(editId, formData).then((res) => {
                    console.log(res)
                    alert("Updated successfully")
                    setIsUpdate(true)
                    setFormModal(false)
                    setValues({
                        title: '',
                        subtitle: '',
                        department_id: '',
                        doctor_id: '',
                        overView: ''
                    })
                    setListArray([])
                    setListObj({ heading: '', subHeading: '' })
                    setCounter(1)
                    setDocs([])
                    setId('')
                }).catch((err)=>{
                    console.log('err treat',err)
                }) :
                TreatmentService.addTreatment(formData).then((res) => {
                    console.log("treat response", res)
                    alert("Submitted Successfully")
                    setIsUpdate(true)
                    setFormModal(false)
                    setDocs([])
                    setValues({
                        title: '',
                        subtitle: '',
                        department_id: '',
                        doctor_id: '',
                        overView: ''
                    })
                    setId('')
                })
        }


    }

    return (
        <>
        <Modal show={formModal} onHide={() => {
            setValues("")
            setFormModal(false)
        }}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>{mainId ? "Edit Department" : "Add Department"}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">

                    <form action="" onSubmit={valuesSubmit}>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label >Treatment</label>
                                <input type="text"
                                    className="form-control"
                                    name="title"
                                    defaultValue={values.title}
                                    onChange={valueChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label >Subtitle</label>
                                <input type="text"
                                    className="form-control"
                                    name="subtitle"
                                    defaultValue={values.subtitle}
                                    onChange={valueChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className='form-title'>Department</label>
                                <Select
                                    options={allDesignations.data.map((des) => ({
                                        label: des.vchr_name,
                                        value: des.id
                                    }))}
                                    value={getDefaultDep(allDesignations?.data)}
                                    ref={departmentRef}
                                    onChange={(event) => depChange(event)}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className='form-title'>Doctor Working</label>
                                {/* <Select
                                    options={allDoctors.data.map((doc) => ({
                                        label: doc.name,
                                        value: doc.id
                                    }))}
                                    isMulti={true}
                                    value={getDefaultDoc(allDoctors?.data)}
                                    ref={docRef}
                                    onChange={(event) => docChange(event)}
                                /> */}
                                <Multiselect
                                    options={allDoctors?.data}
                                    displayValue="name"
                                    selectedValues={docs}
                                    onRemove={(event) => setDocs(event)}
                                    onSelect={(event) => docChange(event)} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label >OverView</label>
                                <input type="text"
                                    className="form-control"
                                    name="overView"
                                    defaultValue={mainId ? values.overView : ""}
                                    onChange={valueChange} />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className='form-title'>About</label>
                                <JoditEditor
                                    ref={editor}
                                    value={values?.body}
                                    config={Config}
                                    tabIndex={4} // tabIndex of textarea
                                    onChange={(e) => setValues({ ...values, body: e })}
                                />
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" >Add Image</label>
                                <input type="file"
                                    className="form-control"
                                    name="image"
                                    style={{ height: '100%', width: '100%' }}
                                    accept="image/png, image/jpeg"
                                    // defaultValue={mainId ? values.image : ""}
                                    ref={image1Ref}
                                    onChange={image1Change} />
                                {(blob || values?.image) && <img src={blob || values?.image} width={50} height={150} className='ml-2 mt-2' />}

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" >Add Icon Image<span style={{ color: 'darkred' }}>(Only .png)</span></label>
                                <input type="file"
                                    className="form-control"
                                    style={{ height: '100%', width: '100%' }}
                                    // defaultValue={mainId ? values.icon_image : ""}
                                    name="icon_image"
                                    accept="image/png"
                                    ref={image2Ref}
                                    onChange={image2Change} />
                                {(blob2 || values?.icon_image) && <img src={blob2 || values?.icon_image} width={50} height={150} className='ml-2 mt-2' />}

                            </div>
                        </div>
                        <div className="adm-treat-submit-btn-div">
                            <button type="submit" className="btn btn-success">{mainId ? "Save Changes" : "Add"}</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
        <Notify err={notifyErr} message={notifyMsg} show={showNotify} setShow={setShowNotify} />
        </>
    )
}

export default TreatmentModal