import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { avatar02, avatar05, avatar06, avatar07, avatar08, } from "../../imagepath";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FaArrowCircleRight } from 'react-icons/fa'
import { BookingData } from "./BookingData";
import { PageItem } from "react-bootstrap";
import useAsync from "../../../../hooks/useAsync";
import BookingService from "../../../../services/BookingService";

const TodayAppointment = (props) => {
  const allBookings = useAsync(BookingService.allBookings)
  const [latestBooks, setLatestBooks] = useState()

  let today = new Date()
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${year}-${month}-${day}`;
  console.log("today...", currentDate); // "17-6-2022"


  useEffect(() => {
    if (allBookings?.data) {
      setLatestBooks(allBookings?.data?.filter((current) => current?.appointment_date >= currentDate))
    }
  }, [allBookings?.data])


  console.log("booking data", latestBooks)



  const settings = {
    loop: false,
    margin: 15,
    dots: false,
    nav: true,
    navContainer: '.slide-nav-3',
    navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right custom-arrow"></i>'],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 1
      },
      768: {
        items: 2
      },
      1350: {
        items: 3
      }
    }
  }

  return (
    <>
      <div className="col-md-6">
        <div className="section-heading">
          <h5 className="mb-0">
            Upcoming Bookings <span className="num-circle">{latestBooks?.length}</span>
          </h5>
        </div>
      </div>
      <div className="col-md-6 text-end">
        <div className="owl-nav slide-nav-3 text-end" />
      </div>

      <div className="col-md-12">
        <hr className="mt-0" />

        <OwlCarousel
          className="owl-carousel appointment-slider owl-theme" {...settings}>
          {
            latestBooks?.map((item) => {
              return (
                <div className="item" key={item.id}>
                  <div className="appointment-item">
                    <div className="app-head">
                      {/* <p>Consultation ID : {item.consult_id} </p> */}
                      <div className="con-time"><FaArrowCircleRight /></div>
                    </div>
                    <div className="app-user">
                      <div className="row">
                        <div className="col-sm-6">
                          <p>User</p>
                          <div className="app-img">
                            <img src={avatar02} alt="" className="img-fluid" />
                            <div className="app-name">
                              <h6>{item?.patient_name}</h6>
                              {/* <p>{item}</p> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <p>{item?.doctor_name ? "Doctor" : "Hospital"}</p>
                          <div className="app-img justify-content-sm-end">
                            <img src={item?.doctor_image} alt="" className="img-fluid" />
                            <div className="app-name">
                              <h6>{item?.doctor_name || item?.hospital?.name}</h6>
                              {/* <p>{item.doc_designation}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="app-info">
                      <div>
                        <p>{item?.email}</p>
                        <h6>{item?.phone_no}</h6>
                      </div>
                      <div>
                        <p>Booked on</p>
                        <h6 className="fw-normal">{item?.appointment_date}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }


        </OwlCarousel>
      </div>
      {/* </div> */}
    </>
  );
};

export default TodayAppointment;

