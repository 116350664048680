import React from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';

function PrivateRoute({ children, isAuth, ...rest }) {
console.log('Check',isAuth)
  return (
    <Route
      {...rest}
      render={
        ({ location }) => (
          isAuth
            ? (
              children
            ) : (
              <Redirect
                to='/login'
              />
            ))
      }
    />
  );
}

export default PrivateRoute;