import React from 'react'
import { Link } from 'react-router-dom'
import SidebarNav from "../sidebar";
import { blogBanner } from '../imagepath';
import { BiEdit } from 'react-icons/bi'

const BlogBannerEdit = () => {
    return (
        <>
            <SidebarNav />
            <div className="edit-blog-ban-div">
                <div className='edit-blog-ban-subdiv'>
                    <h3>Edit Blog Banner</h3>
                    <div className="edit-banner-div">
                        <img src={blogBanner} alt="" />
                    </div>
                    <div className='banner-edit-icon-div'>
                        <div className="change-photo-btn">
                            <i>
                                <BiEdit size="2em" />
                                <input type="file" className='upload' />
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogBannerEdit