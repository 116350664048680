import React, { useEffect, useState, useRef, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { UserContext } from '../../../Context/AllContext'
import SeoService from '../../../services/SeoService'
import { SEO_BLOG, SEO_DOCTOR, SEO_HOSPITAL, SEO_PACKAGES, SEO_TESTIMONIAL, SEO_TREATMENT } from './SeoConstants'

const SeoModal = ({ modal, setModal, value, editId }) => {
    const { setIsUpdate } = useContext(UserContext)
    let modalTitle = value === SEO_HOSPITAL ? "Hospital"
        : value === SEO_DOCTOR ? "Doctor"
            : value === SEO_BLOG ? "Blog"
                : value === SEO_PACKAGES ? "Package"
                    : value === SEO_TESTIMONIAL ? "Testimonial"
                        : value === SEO_TREATMENT ? "Treatment"
                            : ""


    useEffect(() => {
        if (editId && value) {
            if (value === SEO_DOCTOR) {
                SeoService.getDoctorSeoById(editId).then((res) => {
                    setFormValues({
                        title: res?.title,
                        description: res?.description
                    })
                })
            } else if (value === SEO_HOSPITAL) {
                SeoService.getHospitalSeoById(editId).then((res) => {
                    setFormValues({
                        title: res?.title,
                        description: res?.description
                    })
                })
            } else if (value === SEO_PACKAGES) {
                SeoService.getPackageSeoById(editId).then((res) => {
                    setFormValues({
                        title: res?.title,
                        description: res?.description
                    })
                })
            } else if (value === SEO_BLOG) {
                SeoService.getBlogSeoById(editId).then((res) => {
                    setFormValues({
                        title: res?.title,
                        description: res?.description
                    })
                })
            } else if (value === SEO_TREATMENT) {
                SeoService.getTreatmentSeoById(editId).then((res) => {
                    setFormValues({
                        title: res?.title,
                        description: res?.description
                    })
                })
            } else if (value === SEO_TESTIMONIAL) {
                SeoService.getTestimonialSeoById(editId).then((res) => {
                    setFormValues({
                        title: res?.title,
                        description: res?.description
                    })
                })
            } else {
                setFormValues({
                    title: '',
                    description: ''
                })
            }
        }
    }, [editId, value])


    const [formValues, setFormValues] = useState({
        title: '',
        description: ''
    })

    const titleRef = useRef(null)
    const descriptionRef = useRef(null)

    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    const resetValues = () => {
        titleRef.current.value = null
        descriptionRef.current.value = null
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append('title', formValues?.title)
        formData.append('description', formValues?.description)

        if (value === SEO_DOCTOR && !editId) {
            SeoService.addDoctorSeo(formData).then((res) => {
                alert("Added Doctor Seo Successfully")
                setFormValues({
                    title: '',
                    description: ''
                })
                resetValues()
                setModal(false)
                setIsUpdate(true)
            })
        } else if (value === SEO_DOCTOR && editId != "") {
            SeoService.updateDoctorSeo(editId, formData).then((res) => {
                alert("Updated Doctor Seo Successfully")
                setIsUpdate(true)
                setModal(false)
                resetValues()
            })
        } else if (value === SEO_HOSPITAL && !editId) {
            SeoService.addHospitalSeo(formData).then((res) => {
                alert("Added Hospital Seo Successfully")
                setFormValues({
                    title: '',
                    description: ''
                })
                resetValues()
                setModal(false)
                setIsUpdate(true)
            })
        } else if (value === SEO_HOSPITAL && editId != "") {
            SeoService.updateHospitalSeo(editId, formData).then((res) => {
                alert("Updated Hospital Seo Successfully")
                setIsUpdate(true)
                setModal(false)
                resetValues()
            })
        } else if (value === SEO_PACKAGES && !editId) {
            SeoService.addPackageSeo(formData).then((res) => {
                alert("Added Package Seo Successfully")
                setFormValues({
                    title: '',
                    description: ''
                })
                resetValues()
                setModal(false)
                setIsUpdate(true)
            })
        } else if (value === SEO_PACKAGES && editId != "") {
            SeoService.updatePackageSeo(editId, formData).then((res) => {
                alert("Updated Package Seo Successfully")
                setIsUpdate(true)
                setModal(false)
                resetValues()
            })
        } else if (value === SEO_TREATMENT && !editId) {
            SeoService.addTreatmentSeo(formData).then((res) => {
                alert("Added Treatment Seo Successfully")
                setFormValues({
                    title: '',
                    description: ''
                })
                resetValues()
                setModal(false)
                setIsUpdate(true)
            })
        } else if (value === SEO_TREATMENT && editId != "") {
            SeoService.updateTreatmentSeo(editId, formData).then((res) => {
                alert("Updated Treatment Seo Successfully")
                setIsUpdate(true)
                setModal(false)
                resetValues()
            })
        } else if (value === SEO_BLOG && !editId) {
            SeoService.addBlogSeo(formData).then((res) => {
                alert("Added Blog Seo Successfully")
                setFormValues({
                    title: '',
                    description: ''
                })
                resetValues()
                setModal(false)
                setIsUpdate(true)
            })
        } else if (value === SEO_BLOG && editId != "") {
            SeoService.updateBlogSeo(editId, formData).then((res) => {
                alert("Updated Blog Seo Successfully")
                setIsUpdate(true)
                setModal(false)
                resetValues()
            })
        } else if (value === SEO_TESTIMONIAL && !editId) {
            SeoService.addTestimonialSeo(formData).then((res) => {
                alert("Added Testimonial Seo Successfully")
                setFormValues({
                    title: '',
                    description: ''
                })
                resetValues()
                setModal(false)
                setIsUpdate(true)
            })
        } else if (value === SEO_TESTIMONIAL && editId != "") {
            SeoService.updateTestimonialSeo(editId, formData).then((res) => {
                alert("Updated Testimonial Seo Successfully")
                setIsUpdate(true)
                setModal(false)
                resetValues()
            })
        }
    }


    return (
        <Modal show={modal} onHide={() =>
            setModal(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>{editId ? "Edit" : "Add"} {modalTitle} Seo </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <form action="" onSubmit={handleSubmit} >
                        <div className="row">

                            <div className="col-md-12">
                                <div className="form-group ">
                                    <label className='form-title'>Meta Title</label>
                                    <input type="text"
                                        className="form-control"
                                        name="title"
                                        ref={titleRef}
                                        defaultValue={formValues?.title}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group ">
                                    <label className='form-title'>Meta Description</label>
                                    <input type="text"
                                        className="form-control"
                                        name="description"
                                        ref={descriptionRef}
                                        defaultValue={formValues?.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='seo-btn-div'>
                            <button type='submit' className="btn btn-success">{editId ? "Save Changes" : "Add"}</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SeoModal