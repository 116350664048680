import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { cardio, dental, neurology, ortho, urology } from '../imagepath';
import useAsync from '../../../hooks/useAsync';
import SpecialityServices from '../../../services/SpecialityServices';
import { UserContext } from '../../../Context/AllContext';
import { Modal, Button } from 'react-bootstrap';
import AddSpecialityModal from './AddSpecialityModal';
import EditSpecialityModal from './EditSpecialityModal';
import MainModal from '../Modal/MainModal';
import ConfirmDeletePage from './ConfirmDeletePage';
import DeleteModal from '../Modal/DeleteModal';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

const SpecialitiesTable = ({ id, setId }) => {

	const allSpeciality = useAsync(SpecialityServices.allSpeciality)
	const { isUpdate, setIsUpdate } = useContext(UserContext)
	const [editModalShow, setEditModalShow] = useState(false)
	const [editData, setEditData] = useState({})
	const [pageNo, setPageNo] = useState(1)
	const [data, setData] = useState()
	const [editItemName, setEditItemName] = useState()
	const [deleteModal, setDeleteModal] = useState(false)
	const [deleteItemId, setDeleteItemId] = useState()
	const [deleteName, setDeleteName] = useState()

	useEffect(() => {
		if (allSpeciality) {
			setData(allSpeciality.data)
		}
	}, [allSpeciality.data])

	// const pageUpHandler = () => {
	// 	setPageNo(pageNo + 1)
	// 	SpecialityServices.specialityByPage(pageNo + 1).then((res) => {
	// 		setData(res.results)
	// 	})
	// }

	// const pageDownHandler = () => {
	// 	setPageNo(pageNo - 1)
	// 	SpecialityServices.specialityByPage(pageNo - 1).then((res) => {
	// 		setData(res.results)
	// 	})
	// }


	const updateHandler = (id, name) => {
		setId(id)
		setEditModalShow(true)
		setEditItemName(name)
	}

	const deleteModalShow = (id, name) => {
		setDeleteModal(true)
		setDeleteItemId(id)
		setDeleteName(name)
	}

	const deleteHandler = () => {
		SpecialityServices.deleteSpeciality(deleteItemId).then((res) => {
			setIsUpdate(true)
			setDeleteModal(false)
		})
	}

	const columns = [
		{
			name: 'SId',
			// selector: row => row.id,
			cell: (row, index) => index + 1,
			sortable: true,
			width: "100px",
		},
		{
			name: 'Speciality',
			sortable: true,
			grow: 2,
			cell: row => <Media ><Media.Body className="d-flex">
				{/* <Link to="#" className="spl-img" >
				<img src={row.image} className="img-fluid" />
			</Link> */}
				<span>{row.name}</span>
			</Media.Body></Media>,
			width: "450px",

		},
		{
			name: 'Action',
			selector: row => row.action,
			sortable: true,

			cell: row => <div className="actions">
				<Link
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#editModal"
					style={{ color: 'green' }}
					onClick={() => updateHandler(row.id, row.name)}
				>
					<i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
				</Link>
				<Link
					className="text-danger"
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					onClick={() => deleteModalShow(row.id, row.name)}
				>
					<i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
				</Link>
			</div>,
			width: "auto",

		},
	];

	const tableData = {
		columns,
		data,
	};
	return (
		<div className="card-body p-0">
			<div className="table-responsive">
				<DataTableExtensions
					{...tableData}
				>
					<DataTable

						noHeader

						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
					/>
				</DataTableExtensions>
			</div>
			{/* <!-- Modal --> */}
			<MainModal title="Speciality" show1={editModalShow} setShow1={setEditModalShow} id={id} >
				<AddSpecialityModal setShow1={setEditModalShow} id={id} />
			</MainModal>

			{/* Delete modal */}
			<DeleteModal deleteModal={deleteModal}
				setDeleteModal={setDeleteModal}
				deleteHandler={deleteHandler}
				deleteName={deleteName}
			/>

			{/* <div className="pagination-hosp-btns-div">
				{
					pageNo > 1 &&
					<button type="button" className='btn btn-info pagination-left-btn'
						onClick={() => pageDownHandler()}
					>
						<i className='pagination-icon'><FiChevronLeft /></i>
						Back
					</button>
				}

				{
					data?.length == 15 &&
					<button type="button" className="btn btn-info pagination-right-btn"
						onClick={() => pageUpHandler()}
					>
						Next
						<i className='pagination-icon'><FiChevronRight /></i>
					</button>
				}
			</div> */}

			{/* <Modal show={deleteModal} onHide={() =>
				setDeleteModal(false)}>
				<Modal.Header closeButton >
					<Modal.Title className='profile-settings-modal-header'>
						<h4>Confirm Delete </h4>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-image-main-cont">
						<div className='onfirm-delete-main-div'>
							<p>Do you want to delete <span style={{ color: '#98181e', fontWeight: 'bold' }}>{deleteName}</span> ? </p>
							<div className="confirm-delete-btn-div">
								<button
									type="button" className="btn btn-danger"
									onClick={deleteHandler}>Delete</button>
								<button
									type="button" className="btn btn-secondary ml-3"
									onClick={() => setDeleteModal(false)}>Cancel</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal> */}

		</div>
	)
}

export default SpecialitiesTable;