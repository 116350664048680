import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { UserContext } from "../../../Context/AllContext";
import DepartmentService from "../../../services/DepartmentService";
import FeatherIcon from 'feather-icons-react';
import { useRef } from "react";


const FormDepartment = ({ formModal, setFormModal, mainId }) => {
    const { setIsUpdate } = useContext(UserContext)
    const imageRef = useRef(null)
    console.log("department id....", mainId)
    const [depValues, setDepValues] = useState({
        vchr_name: '',
        int_status: '1',
        department_image: null
    })
    const [editData, setEditData] = useState([])
    const [blob, setBlob] = useState(null)
    const [isImageChange, setIsImageChange] = useState(false)

    useEffect(() => {
        console.log('before useeffect', mainId)
        if (mainId != "") {
            DepartmentService.getDepartmentById(mainId).then((res) => {
                console.log("edit data res", res)
                setDepValues({
                    vchr_name: res.results.vchr_name,
                    int_status: res.results.int_status,
                    department_image: res.results.department_image
                })
                setBlob(res.results.department_image)
            })
        } else {
            setDepValues({
                vchr_name: "",
                int_status: '1',
                department_image: ""
            })
        }

    }, [mainId])

    const valueChange = (e) => {
        setDepValues({ ...depValues, [e.target.name]: e.target.value })
    }

    const depImageChange = (e) => {
        setIsImageChange(true)
        console.log("image", e.target)
        setDepValues({ ...depValues, department_image: e.target.files[0] })
        setBlob(URL.createObjectURL(e.target.files[0]))
    }

    console.log("dep values image", depValues?.department_image)
    console.log("blob values image", blob)


    const depSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('vchr_name', depValues.vchr_name)
        formData.append('int_status', depValues.int_status)
        if (depValues?.department_image?.name) {
            console.log('img', depValues.department_image)
            formData.append('department_image', depValues.department_image)
        }
        {
            mainId ?
                DepartmentService.updateDepartment(mainId, formData).then((res) => {
                    console.log(res)
                    alert("Updated Successfully")
                    setFormModal(false)
                    setIsUpdate(true)
                })
                :
                DepartmentService.addDepartment(formData).then((res) => {
                    console.log(res)
                    setFormModal(false)
                    setIsUpdate(true)
                    alert("Successfully")
                })
        }
    }



    return (
        <Modal show={formModal} onHide={() =>
            setFormModal(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>{mainId ? "Edit Department" : "Add Department"}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <div className="modal-content doctor-profile">
                        <div className="modal-body">
                            <form action=""
                                onSubmit={depSubmit}
                            >
                                <div className="add-wrap">
                                    <div className="form-group form-focus">
                                        <input type="text"
                                            name='vchr_name'
                                            required
                                            className="form-control"
                                            defaultValue={mainId ? depValues.vchr_name : ""}
                                            onChange={valueChange}
                                        />
                                        <label className="focus-label">Department Name<span className="text-danger">*</span></label>
                                    </div>

                                    <div className="change-photo-btn">
                                        <div><i className=''><FeatherIcon icon="upload" /></i>
                                            <p>Upload File</p></div>
                                        <input type="file"
                                            className="upload"
                                            style={{ height: '100%', width: '100%' }}
                                            name='department_image'
                                            accept="image/png, image/jpeg"
                                            ref={imageRef}
                                            // defaultValue={mainId && depValues.department_image}
                                            onChange={depImageChange}
                                        />
                                        {
                                            (blob || depValues?.department_image) && <img src={blob || depValues?.department_image} width={50} height={50} className='ml-2' />
                                        }

                                    </div>

                                    <div className="submit-section">
                                        <button type="submit" className="btn btn-primary btn-save">
                                            {mainId ? "Save Changes" : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FormDepartment