import React from 'react'
import { useState } from 'react'
import FeatherIcon from 'feather-icons-react';
import BannerService from '../../../services/BannerServices';
import { useEffect } from 'react';
import useAsync from '../../../hooks/useAsync';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';

const AddBannerModal = ({ setModal, bannerValue, setBannerValue }) => {
    const { data } = useAsync(BannerService.getBanner)
    const { setIsUpdate } = useContext(UserContext)
    const [images, setImages] = useState({
        package_banner: '',
        treatment_banner: '',
        blog_banner: '',
        add_hospital_banner: '',
        consult_online_banner: ''
    })

    const [blob, setBlob] = useState(null)
    const [blob2, setBlob2] = useState(null)
    const [blob3, setBlob3] = useState(null)
    const [blob4, setBlob4] = useState(null)
    const [blob5, setBlob5] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState()

    console.log("banner values", bannerValue)
    console.log("data", data[0])
    useEffect(() => {
        if (data != []) {
            setIsEdit(true)
            setEditId(data[0]?.id)
        }
        if (bannerValue) {
            setIsEdit(true)
            setImages({
                package_banner: bannerValue?.package_banner,
                blog_banner: bannerValue?.blog_banner,
                treatment_banner: bannerValue?.treatment_banner,
                add_hospital_banner: bannerValue?.add_hospital_banner,
                consult_online_banner: bannerValue?.consult_online_banner
            })
        }
    }, [data])

    console.log("flag", isEdit)
    console.log("id", editId)

    const handleSubmit = e => {
        e.preventDefault()

        let formData = new FormData()
        if (images?.package_banner?.name) {
            console.log('img', images.package_banner)
            formData.append('package_banner', images?.package_banner)
        }
        if (images?.treatment_banner?.name) {
            console.log('img', images.treatment_banner)
            formData.append('treatment_banner', images?.treatment_banner)
        }
        if (images?.blog_banner?.name) {
            console.log('img', images.blog_banner)
            formData.append('blog_banner', images?.blog_banner)
        }
        if (images?.add_hospital_banner?.name) {
            console.log('img', images.add_hospital_banner)
            formData.append('add_hospital_banner', images?.add_hospital_banner)
        }
        if (images?.consult_online_banner?.name) {
            console.log('img', images.consult_online_banner)
            formData.append('consult_online_banner', images?.consult_online_banner)
        }

        {
            isEdit ?
                BannerService.updateBanner(2, formData).then((res) => {
                    console.log(res)
                    alert("Updated Banners Successfully")
                    setIsUpdate(true)
                    setModal(false)
                })
                :
                BannerService.addBanner(formData).then((res) => {
                    console.log(res)
                    alert("Updated Banners Successfully")
                    setIsUpdate(true)
                    setModal(false)
                })
        }



    }
    return (
        <div className='add-banner-main'>

            <div className="add-banner-body">

                <div className="add-banner-list-body">
                    <div className="add-banner-header-div">
                        <h5>Package Banner</h5>
                    </div>
                    <div className="add-banner-img-input">
                        <div className="col-md-6">
                            <div className="change-photo-btn">
                                <div><i className=''><FeatherIcon icon="upload" /></i>
                                    <p>Upload File</p></div>
                                <input type="file"
                                    className="upload"
                                    style={{ height: '100%', width: '100%' }}
                                    name='package_banner'
                                    accept="image/png, image/jpeg"
                                    defaultValue={images.package_banner}
                                    onChange={(e) => {
                                        setImages({ ...images, [e.target.name]: e.target.files[0] })
                                        setBlob(URL.createObjectURL(e.target.files[0]))
                                    }}
                                />
                            </div>

                        </div>
                        <div className="add-banner-img-div">
                            {(blob || images.package_banner) && <img src={blob || images.package_banner} width={50} height={50} className='ml-2' />}
                        </div>
                    </div>
                </div>

                <div className="add-banner-list-body">
                    <div className="add-banner-header-div">
                        <h5>Treatment Banner</h5>
                    </div>
                    <div className="add-banner-img-input">
                        <div className="col-md-6">
                            <div className="change-photo-btn">
                                <div><i className=''><FeatherIcon icon="upload" /></i>
                                    <p>Upload File</p></div>
                                <input type="file"
                                    className="upload"
                                    style={{ height: '100%', width: '100%' }}
                                    name='treatment_banner'
                                    accept="image/png, image/jpeg"
                                    defaultValue={images.treatment_banner}
                                    onChange={(e) => {
                                        setImages({ ...images, [e.target.name]: e.target.files[0] })
                                        setBlob2(URL.createObjectURL(e.target.files[0]))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="add-banner-img-div">
                            {(blob2 || images.treatment_banner) && <img src={blob2 || images.treatment_banner} width={50} height={50} className='ml-2' />}
                        </div>
                    </div>
                </div>

                <div className="add-banner-list-body">
                    <div className="add-banner-header-div">
                        <h5>Blog Banner</h5>
                    </div>
                    <div className="add-banner-img-input">
                        <div className="col-md-6">
                            <div className="change-photo-btn">
                                <div><i className=''><FeatherIcon icon="upload" /></i>
                                    <p>Upload File</p></div>
                                <input type="file"
                                    className="upload"
                                    style={{ height: '100%', width: '100%' }}
                                    name='blog_banner'
                                    accept="image/png, image/jpeg"
                                    defaultValue={images.blog_banner}
                                    onChange={(e) => {
                                        setImages({ ...images, [e.target.name]: e.target.files[0] })
                                        setBlob3(URL.createObjectURL(e.target.files[0]))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="add-banner-img-div">
                            {(blob3 || images.blog_banner) && <img src={blob3 || images.blog_banner} width={50} height={50} className='ml-2' />}
                        </div>
                    </div>
                </div>

                <div className="add-banner-list-body">
                    <div className="add-banner-header-div">
                        <h5>Add Hospital Banner</h5>
                    </div>
                    <div className="add-banner-img-input">
                        <div className="col-md-6">
                            <div className="change-photo-btn">
                                <div><i className=''><FeatherIcon icon="upload" /></i>
                                    <p>Upload File</p></div>
                                <input type="file"
                                    className="upload"
                                    style={{ height: '100%', width: '100%' }}
                                    name='add_hospital_banner'
                                    accept="image/png, image/jpeg"
                                    defaultValue={images.add_hospital_banner}
                                    onChange={(e) => {
                                        setImages({ ...images, [e.target.name]: e.target.files[0] })
                                        setBlob4(URL.createObjectURL(e.target.files[0]))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="add-banner-img-div">
                            {(blob4 || images.add_hospital_banner) && <img src={blob4 || images.add_hospital_banner} width={50} height={50} className='ml-2' />}
                        </div>
                    </div>
                </div>

                <div className="add-banner-list-body">
                    <div className="add-banner-header-div">
                        <h5>Consult Online Banner</h5>
                    </div>
                    <div className="add-banner-img-input">
                        <div className="col-md-6">
                            <div className="change-photo-btn">
                                <div><i className=''><FeatherIcon icon="upload" /></i>
                                    <p>Upload File</p></div>
                                <input type="file"
                                    className="upload"
                                    style={{ height: '100%', width: '100%' }}
                                    name='consult_online_banner'
                                    accept="image/png, image/jpeg"
                                    defaultValue={images.consult_online_banner}
                                    onChange={(e) => {
                                        setImages({ ...images, [e.target.name]: e.target.files[0] })
                                        setBlob5(URL.createObjectURL(e.target.files[0]))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="add-banner-img-div">
                            {(blob5 || images.consult_online_banner) && <img src={blob5 || images.consult_online_banner} width={50} height={50} className='ml-2' />}
                        </div>
                    </div>
                </div>

                <div className="submit-section">
                    <button type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-save mt-5">
                        Add
                    </button>
                </div>

            </div>

        </div>
    )
}

export default AddBannerModal