import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SideBarNav from '../sidebar/index'
import { AddCommonFaq } from './AddCommonFaq'
import AddDoctorFaq from './AddDoctorFaq'
import AddHospitalFaq from './AddHospitalFaq'
import { CommonFaq } from './CommonFaq'
import DoctorFaq from './DoctorFaq'
import HospitalFaq from './HospitalFaq'

const FaqPage = () => {
    const [isFaqPage, setIsFaqPage] = useState("commonFaq")
    const [editId, setEditId] = useState()

    const pageHandler = (e) => {
        setIsFaqPage(e.target.value)
    }

    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="adm-testi-header-div mt-3">

                        <button type="button"
                            className='btn btn-success mr-3'
                            value="commonFaq"
                            disabled={isFaqPage === "commonFaq" && "addCommonFaq" ? true : false}
                            onClick={pageHandler}>Common FAQ</button>

                        <button type="button"
                            className='btn btn-success'
                            value="hospFaq"
                            disabled={isFaqPage === "hospFaq" && "addHospFaq" ? true : false}
                            onClick={pageHandler}>Hospital FAQ</button>

                        <button type="button"
                            className='btn btn-success ml-3'
                            value="docFaq"
                            disabled={isFaqPage === "docFaq" && "addDocFaq" ? true : false}
                            onClick={pageHandler}>Doctor FAQ</button>
                    </div>
                    <div className="adm-testi-body-div">
                        <div className="adm-testi-header-div">
                            <h6>{isFaqPage === "hospFaq" ? "Hospital FAQ" : isFaqPage === "docFaq" ? "Doctor FAQ" : isFaqPage === "commonFaq" ? "Common FAQ" : ""}</h6>
                            {
                                isFaqPage === "hospFaq" ?
                                    <button className="btn btn-info ml-5"
                                        value="addHospFaq"
                                        onClick={pageHandler}
                                    >Add</button>
                                    :
                                    isFaqPage === "docFaq" ?
                                        <button className="btn btn-info ml-5"
                                            value="addDocFaq"
                                            onClick={pageHandler}>Add</button>
                                        :
                                        isFaqPage === "commonFaq" ?
                                            <button className="btn btn-info ml-5"
                                                value="addCommonFaq"
                                                onClick={pageHandler}
                                            >Add</button>
                                            :
                                            ""
                            }




                        </div>
                        {
                            isFaqPage === "commonFaq" ?
                                <CommonFaq editId={editId} setEditId={setEditId} formPage={isFaqPage} setFormPage={setIsFaqPage} />
                                :
                                isFaqPage === "addCommonFaq" ?
                                    <AddCommonFaq editId={editId} setEditId={setEditId} formPage={isFaqPage} setFormPage={setIsFaqPage} />
                                    :
                                    isFaqPage === "hospFaq" ?
                                        <HospitalFaq editId={editId} setEditId={setEditId} formPage={isFaqPage} setFormPage={setIsFaqPage} />
                                        :
                                        isFaqPage === "docFaq" ?
                                            <DoctorFaq editId={editId} setEditId={setEditId} formPage={isFaqPage} setFormPage={setIsFaqPage} />
                                            :
                                            isFaqPage === "addHospFaq" ?
                                                <AddHospitalFaq editId={editId} setEditId={setEditId} formPage={isFaqPage} setFormPage={setIsFaqPage} />
                                                :
                                                <AddDoctorFaq editId={editId} setEditId={setEditId} formPage={isFaqPage} setFormPage={setIsFaqPage} />
                        }
                    </div>

                </div>
            </div>

        </>
    )
}

export default FaqPage