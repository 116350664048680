import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../sidebar'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import SeoModal from './SeoModal'
import useAsync from '../../../hooks/useAsync'
import SeoService from '../../../services/SeoService'
import { IoMdAdd } from 'react-icons/io'
import DeleteModal from '../Modal/DeleteModal'
import { UserContext } from '../../../Context/AllContext'

const SeoPage = () => {
    const { setIsUpdate } = useContext(UserContext)

    const DoctorSeo = useAsync(SeoService.getAllDoctorSeo)
    const HospitalSeo = useAsync(SeoService.getAllHospitalSeo)
    const PackageSeo = useAsync(SeoService.getAllPackageSeo)
    const TreatmentSeo = useAsync(SeoService.getAllTreatmentSeo)
    const BlogSeo = useAsync(SeoService.getAllBlogSeo)
    const TestimonialSeo = useAsync(SeoService.getAllTestimonialSeo)

    const [doctor, setDoctor] = useState()
    const [hospital, setHospital] = useState()
    const [packages, setPackages] = useState()
    const [treatment, setTreatment] = useState()
    const [blog, setBlog] = useState()
    const [testimonial, setTestimonial] = useState()

    const [modalValue, setModalValue] = useState('')
    const [modalShow, setModalShow] = useState(false)
    const [editId, setEditId] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteItemName, setDeleteItemName] = useState('')

    useEffect(() => {
        if (DoctorSeo?.data) {
            setDoctor(DoctorSeo?.data)
        }
        if (HospitalSeo?.data) {
            setHospital(HospitalSeo?.data)
        }
        if (PackageSeo?.data) {
            setPackages(PackageSeo?.data)
        }
        if (TreatmentSeo?.data) {
            setTreatment(TreatmentSeo?.data)
        }
        if (BlogSeo?.data) {
            setBlog(BlogSeo?.data)
        }
        if (TestimonialSeo?.data) {
            setTestimonial(TestimonialSeo?.data)
        }
    }, [DoctorSeo?.data, HospitalSeo?.data, PackageSeo?.data, TreatmentSeo?.data, BlogSeo?.data, TestimonialSeo?.data])

    console.log("doctor seo", doctor)

    const addSeoHandler = (value) => {
        setModalValue(value)
        setModalShow(true)
    }



    const updateHandler = (value, id) => {
        setModalValue(value)
        setEditId(id)
        setModalShow(true)
    }

    const deleteModalShow = (name, id) => {
        setDeleteItemName(name)
        setDeleteId(id)
        setDeleteModal(true)
    }
    const deleteHandler = () => {
        if (deleteItemName === "doctor") {
            SeoService.deleteDoctorSeo(deleteId).then((res) => {
                setIsUpdate(true)
                setDeleteModal(false)
            })
        } else if (deleteItemName === "hospital") {
            SeoService.deleteHospitalSeo(deleteId).then((res) => {
                setIsUpdate(true)
                setDeleteModal(false)
            })
        } else if (deleteItemName === "packages") {
            SeoService.deletePackageSeo(deleteId).then((res) => {
                setIsUpdate(true)
                setDeleteModal(false)
            })
        } else if (deleteItemName === "treatment") {
            SeoService.deleteTreatmentSeo(deleteId).then((res) => {
                setIsUpdate(true)
                setDeleteModal(false)
            })
        } else if (deleteItemName === "blog") {
            SeoService.deleteBlogSeo(deleteId).then((res) => {
                setIsUpdate(true)
                setDeleteModal(false)
            })
        } else {
            SeoService.deleteTestimonialSeo(deleteId).then((res) => {
                setIsUpdate(true)
                setDeleteModal(false)
            })
        }
    }

    return (
        <>
            <Sidebar />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className='seo-header-div'>
                        <h3>SEO</h3>
                    </div>
                    <div className='seo-page-body'>

                        {/* Hospital seo */}
                        <div className="seo-section-div">
                            <div className='seo-title-header-div'>
                                <h5>Hospital page</h5>
                                {
                                    hospital?.length > 0 ?
                                        <i className='seo-edit-btn' onClick={() => updateHandler("hospital", hospital[0]?.id)}><AiFillEdit /></i>
                                        :
                                        <i className='seo-add-btn' onClick={() => addSeoHandler("hospital")}><IoMdAdd /></i>
                                }
                                <i className='seo-delete-btn' onClick={() => deleteModalShow("hospital", hospital[0]?.id)}><AiFillDelete /></i>
                            </div>

                            <div className="seo-title-div">
                                <div className="seo-title-head-div">
                                    <h6>Title </h6>

                                </div>

                                {
                                    hospital?.length > 0 ?
                                        <p>{hospital[0]?.title}</p>
                                        : ""
                                }

                            </div>
                            <div className="seo-content-div">
                                <div className="seo-content-head-div">
                                    <h6>Description</h6>
                                </div>

                                {
                                    hospital?.length > 0 ?
                                        <p>{hospital[0]?.description}</p>
                                        : ""
                                }

                            </div>
                        </div>

                        {/* Doctor Seo */}
                        <div className="seo-section-div">
                            <div className='seo-title-header-div'>
                                <h5>Doctor page</h5>
                                {
                                    doctor?.length > 0 ?
                                        <i className='seo-edit-btn' onClick={() => updateHandler("doctor", doctor[0]?.id)}><AiFillEdit /></i>
                                        :
                                        <i className='seo-add-btn' onClick={() => addSeoHandler("doctor")}><IoMdAdd /></i>
                                }
                                <i className='seo-delete-btn' onClick={() => deleteModalShow("doctor", doctor[0]?.id)}><AiFillDelete /></i>
                            </div>

                            <div className="seo-title-div">
                                <div className="seo-title-head-div">
                                    <h6>Title </h6>

                                </div>

                                {
                                    doctor?.length > 0 ?
                                        <p>{doctor[0]?.title}</p>
                                        : ""
                                }

                            </div>
                            <div className="seo-content-div">
                                <div className="seo-content-head-div">
                                    <h6>Description</h6>
                                </div>

                                {
                                    doctor?.length > 0 ?
                                        <p>{doctor[0]?.description}</p>
                                        : ""
                                }

                            </div>
                        </div>

                        {/* Package Seo */}
                        <div className="seo-section-div">
                            <div className='seo-title-header-div'>
                                <h5>Package page</h5>
                                {
                                    packages?.length > 0 ?
                                        <i className='seo-edit-btn' onClick={() => updateHandler("packages", packages[0]?.id)}><AiFillEdit /></i>
                                        :
                                        <i className='seo-add-btn' onClick={() => addSeoHandler("packages")}><IoMdAdd /></i>
                                }
                                <i className='seo-delete-btn' onClick={() => deleteModalShow("packages", packages[0]?.id)}><AiFillDelete /></i>
                            </div>

                            <div className="seo-title-div">
                                <div className="seo-title-head-div">
                                    <h6>Title </h6>

                                </div>

                                {
                                    packages?.length > 0 ?
                                        <p>{packages[0]?.title}</p>
                                        : ""
                                }

                            </div>
                            <div className="seo-content-div">
                                <div className="seo-content-head-div">
                                    <h6>Description</h6>
                                </div>

                                {
                                    packages?.length > 0 ?
                                        <p>{packages[0]?.description}</p>
                                        : ""
                                }

                            </div>
                        </div>

                        {/* Treatment seo */}
                        <div className="seo-section-div">
                            <div className='seo-title-header-div'>
                                <h5>Treatment page</h5>
                                {
                                    treatment?.length > 0 ?
                                        <i className='seo-edit-btn' onClick={() => updateHandler("treatment", treatment[0]?.id)}><AiFillEdit /></i>
                                        :
                                        <i className='seo-add-btn' onClick={() => addSeoHandler("treatment")}><IoMdAdd /></i>
                                }
                                <i className='seo-delete-btn' onClick={() => deleteModalShow("treatment", treatment[0]?.id)}><AiFillDelete /></i>
                            </div>

                            <div className="seo-title-div">
                                <div className="seo-title-head-div">
                                    <h6>Title </h6>

                                </div>

                                {
                                    treatment?.length > 0 ?
                                        <p>{treatment[0]?.title}</p>
                                        : ""
                                }

                            </div>
                            <div className="seo-content-div">
                                <div className="seo-content-head-div">
                                    <h6>Description</h6>
                                </div>

                                {
                                    treatment?.length > 0 ?
                                        <p>{treatment[0]?.description}</p>
                                        : ""
                                }

                            </div>
                        </div>

                        {/* Blog seo */}
                        <div className="seo-section-div">
                            <div className='seo-title-header-div'>
                                <h5>Blog page</h5>
                                {
                                    blog?.length > 0 ?
                                        <i className='seo-edit-btn' onClick={() => updateHandler("blog", blog[0]?.id)}><AiFillEdit /></i>
                                        :
                                        <i className='seo-add-btn' onClick={() => addSeoHandler("blog")}><IoMdAdd /></i>
                                }
                                <i className='seo-delete-btn' onClick={() => deleteModalShow("blog", blog[0]?.id)}><AiFillDelete /></i>
                            </div>

                            <div className="seo-title-div">
                                <div className="seo-title-head-div">
                                    <h6>Title </h6>

                                </div>

                                {
                                    blog?.length > 0 ?
                                        <p>{blog[0]?.title}</p>
                                        : ""
                                }

                            </div>
                            <div className="seo-content-div">
                                <div className="seo-content-head-div">
                                    <h6>Description</h6>
                                </div>

                                {
                                    blog?.length > 0 ?
                                        <p>{blog[0]?.description}</p>
                                        : ""
                                }

                            </div>
                        </div>

                        {/* Testimonial seo */}
                        <div className="seo-section-div">
                            <div className='seo-title-header-div'>
                                <h5>Testimonial page</h5>
                                {
                                    testimonial?.length > 0 ?
                                        <i className='seo-edit-btn' onClick={() => updateHandler("testimonial", testimonial[0]?.id)}><AiFillEdit /></i>
                                        :
                                        <i className='seo-add-btn' onClick={() => addSeoHandler("testimonial")}><IoMdAdd /></i>
                                }
                                <i className='seo-delete-btn' onClick={() => deleteModalShow("testimonial", testimonial[0]?.id)}><AiFillDelete /></i>
                            </div>

                            <div className="seo-title-div">
                                <div className="seo-title-head-div">
                                    <h6>Title </h6>

                                </div>

                                {
                                    testimonial?.length > 0 ?
                                        <p>{testimonial[0]?.title}</p>
                                        : ""
                                }

                            </div>
                            <div className="seo-content-div">
                                <div className="seo-content-head-div">
                                    <h6>Description</h6>
                                </div>

                                {
                                    testimonial?.length > 0 ?
                                        <p>{testimonial[0]?.description}</p>
                                        : ""
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <SeoModal value={modalValue} modal={modalShow} setModal={setModalShow} editId={editId} />

            <DeleteModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteItemName} />
        </>
    )
}

export default SeoPage