import requests from "./httpServices";

const SeoService = {
    addDoctorSeo(body) {
        return requests.post('/doctor/seo/', body)
    },
    getDoctorSeoById(id) {
        return requests.get(`/doctor/seo/${id}/`)
    },
    updateDoctorSeo(id, body) {
        return requests.put(`/doctor/seo/${id}/`, body)
    },
    deleteDoctorSeo(id) {
        return requests.delete(`/doctor/seo/${id}/`)
    },
    getAllDoctorSeo() {
        return requests.get('/doctor/seo/')
    },
    addHospitalSeo(body) {
        return requests.post("/hospital/seo/", body)
    },
    getHospitalSeoById(id) {
        return requests.get(`/hospital/seo/${id}/`)
    },
    updateHospitalSeo(id, body) {
        return requests.put(`/hospital/seo/${id}/`, body)
    },
    deleteHospitalSeo(id) {
        return requests.delete(`/hospital/seo/${id}/`)
    },
    getAllHospitalSeo() {
        return requests.get('/hospital/seo/')
    },
    addPackageSeo(body) {
        return requests.post("/procedure/seo/", body)
    },
    getPackageSeoById(id) {
        return requests.get(`/procedure/seo/${id}/`)
    },
    updatePackageSeo(id, body) {
        return requests.put(`/procedure/seo/${id}/`, body)
    },
    deletePackageSeo(id) {
        return requests.delete(`/procedure/seo/${id}/`)
    },
    getAllPackageSeo() {
        return requests.get('/procedure/seo/')
    },
    addTreatmentSeo(body) {
        return requests.post("/doctor/treat/seo/", body)
    },
    getTreatmentSeoById(id) {
        return requests.get(`/doctor/treat/seo/${id}/`)
    },
    updateTreatmentSeo(id, body) {
        return requests.put(`/doctor/treat/seo/${id}/`, body)
    },
    deleteTreatmentSeo(id) {
        return requests.delete(`/doctor/treat/seo/${id}/`)
    },
    getAllTreatmentSeo() {
        return requests.get('/doctor/treat/seo/')
    },
    addBlogSeo(body) {
        return requests.post("/blogs/seo/", body)
    },
    getBlogSeoById(id) {
        return requests.get(`/blogs/seo/${id}/`)
    },
    updateBlogSeo(id, body) {
        return requests.put(`/blogs/seo/${id}/`, body)
    },
    deleteBlogSeo(id) {
        return requests.delete(`/blogs/seo/${id}/`)
    },
    getAllBlogSeo() {
        return requests.get('/blogs/seo/')
    },
    addTestimonialSeo(body) {
        return requests.post("/hospital/testi/seo/", body)
    },
    getTestimonialSeoById(id) {
        return requests.get(`/hospital/testi/seo/${id}/`)
    },
    updateTestimonialSeo(id, body) {
        return requests.put(`/hospital/testi/seo/${id}/`, body)
    },
    deleteTestimonialSeo(id) {
        return requests.delete(`/hospital/testi/seo/${id}/`)
    },
    getAllTestimonialSeo() {
        return requests.get('/hospital/testi/seo/')
    },

}

export default SeoService