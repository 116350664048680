import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SideBarNav from '../sidebar/index'
import AdminsTable from './AdminsTable';
import { Modal } from 'react-bootstrap';
import RegisterModal from './RegisterModal';
import MainModal from '../Modal/MainModal';

const AdminPage = () => {
    const [registerModal, setRegisterModal] = useState(false)
    const [id, setId] = useState("")

    const addAdminHandler = () => {
        setId("")
        setRegisterModal(true)
    }

    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="adm-page-main-div">
                        <div className="adm-page-container">
                            <div className="adm-page-header-div mt-5">
                                <button type="button"
                                    className="btn btn-info"
                                    onClick={addAdminHandler}>Add new admin</button>
                            </div>
                            <div className="adm-page-body-div">
                                <AdminsTable setRegisterModal={setRegisterModal} setId={setId} />
                            </div>
                        </div>
                    </div>

                    {/* Register modal */}
                    {/* <Modal show={registerModal} onHide={() =>
                setRegisterModal(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>Register Admin </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont"> */}
                    {/* <ViewDoctorModal docId={docId} /> */}
                    <MainModal setShow1={setRegisterModal} show1={registerModal} title="Admin" id={id}>
                        <RegisterModal setRegisterModal={setRegisterModal} id={id} />
                    </MainModal>
                    {/* </div>
                </Modal.Body>
            </Modal> */}
                </div>
            </div>
        </>
    )
}

export default AdminPage