import React, { useEffect, useState, useContext } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import FeatherIcon from 'feather-icons-react';
import { Appcontext } from '../../../approuter'
import { TbBuildingHospital } from 'react-icons/tb'
import { TbDiscount2 } from 'react-icons/tb'
import { BsFillMenuAppFill, BsQuestionSquare } from 'react-icons/bs'
import { GiMedicalPackAlt, GiVerticalBanner } from 'react-icons/gi'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { CgMenuBoxed } from 'react-icons/cg'
import { BsCaretDownFill, BsCaretUpFill, BsFillUnlockFill } from 'react-icons/bs'
import { GrGallery } from 'react-icons/gr'
import { MdOutlineEngineering } from 'react-icons/md'

const SidebarNav = (props) => {
	let pathname = props.location.pathname;
	const { isAuth, setIsAuth } = useContext(Appcontext);
	const [isSideMenu, setSideMenu] = useState("");
	const [isSideMenuNew, setSideMenuNew] = useState("");
	const [enqOpen, setEnqOpen] = useState(false)
	const [enquiryTitle, setEnquiryTitle] = useState('')

	const toggleSidebar = (value) => {
		console.log(value);
		setSideMenu(value);
	};

	const toggleSidebarNew = (value) => {
		console.log(value);
		setSideMenuNew(value);

	};

	const enquiryHandler = (title) => {
		console.log("enquiry title", title)
	}


	return (
		<>
			{/* <!-- Sidebar --> */}
			<div className="sidebar" id="sidebar">
				<Scrollbars
					autoHide
					autoHideTimeout={1000}
					autoHideDuration={200}
					autoHeight
					autoHeightMin={0}
					autoHeightMax="95vh"
					thumbMinSize={30}
					universal={false}
					hideTracksWhenNotNeeded={true}
				>
					<div className="sidebar-inner slimscroll">
						<div id="sidebar-menu" className="sidebar-menu">
							<ul>
								<li className="menu-title"><span>Main</span></li>
								<li className={pathname === '/' ? "active" : ""}>
									<Link to="/"><i><FeatherIcon icon="grid" /></i><span>Dashboard</span></Link>
								</li>
								<li className={pathname.includes('bookings') ? "active" : ""}>
									<Link to="/bookings"><i><FeatherIcon icon="calendar" /></i> <span>Bookings</span></Link>
								</li>
								<li className={pathname.includes('specialities') ? "active" : ""}>
									<Link to="/specialities"><i><FeatherIcon icon="shopping-bag" /></i> <span>Specialities</span></Link>
								</li>
								<li className={pathname.includes('facilities') ? "active" : ""}>
									<Link to="/facilities"><i><FeatherIcon icon="package" /></i> <span>Facilities</span></Link>
								</li>
								<li className={pathname.includes('department') ? "active" : ""}>
									<Link to="/department"><i><BsFillMenuAppFill /></i> <span>Department</span></Link>
								</li>
								<li className={pathname.includes('treatment') ? "active" : ""}>
									<Link to="/treatment"><i><GiMedicalPackAlt /></i> <span>Treatment</span></Link>
								</li>
								<li className={pathname.includes('hopitals') ? "active" : ""}>
									<Link to="/hopitals"><i><TbBuildingHospital /></i> <span>Hospital</span></Link>
								</li>
								<li className={pathname.includes('/doctor-list', '/add-doctor') ? "active" : ""}>
									<Link to="/doctor-list"><i><FeatherIcon icon="user-plus" /></i> <span>Doctors</span></Link>
								</li>
								<li className={pathname.includes('patient-list') ? "active" : ""}>
									<Link to="/patient-list"><i><FeatherIcon icon="users" /></i> <span>Patients</span></Link>
								</li>
								<li className={pathname.includes('seo') ? "active" : ""}>
									<Link to="/seo"><i><MdOutlineEngineering /></i> <span>SEO</span></Link>
								</li>
								<li className={pathname.includes('banners') ? "active" : ""}>
									<Link to="/banners"><i><GiVerticalBanner /></i> <span>Banners</span></Link>
								</li>
								{/* <li className={pathname.includes('reviews') ? "active" : ""}>
									<Link to="/reviews"><i><FeatherIcon icon="star" /></i> <span>Reviews</span></Link>
								</li> */}
								{/* <li className={pathname.includes('transactions-list') ? "active" : ""}>
									<Link to="/transactions-list"><i><FeatherIcon icon="credit-card" /></i> <span>Transactions</span></Link>
								</li> */}
								<li className={pathname.includes('admin-page') ? "active" : ""}>
									<Link to="/admin-page"><i><FeatherIcon icon="lock" /></i> <span> Admin</span></Link>
								</li>
								<li className={pathname.includes('packages') ? "active" : ""}>
									<Link to="/packages"><i><TbDiscount2 /></i> <span> Packages</span></Link>
								</li>
								<li className={pathname.includes('testimonial') ? "active" : ""}>
									<Link to="/testimonial"><i><FeatherIcon icon="message-square" /></i> <span> Testimonial</span></Link>
								</li>
								<li className={pathname.includes('gallery') ? "active" : ""}>
									<Link to="/gallery"><i><GrGallery /></i> <span> Gallery</span></Link>
								</li>
								<li className={pathname.includes('faqPage') ? "active" : ""}>
									<Link to="/faqPage"><i><FaRegQuestionCircle /></i> <span> FAQ</span></Link>
								</li>
								<li className={pathname.includes('enquiry') ? "active" : ""}>
									<Link
										to="#"
										onClick={() => setEnqOpen(!enqOpen)}
									><i><FeatherIcon icon="sliders" /></i>
										<span> Enquiries</span>
										<i>{!enqOpen ? <BsCaretDownFill /> : <BsCaretUpFill />}</i>
									</Link>
								</li>
								{
									enqOpen &&
									<div className="enq-list-titles">
										<div className='enq-titles-item-div'>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "all" }
												}}
											>
												<i className='mr-1'><CgMenuBoxed /></i>All Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "hospital" }
												}}
											><i className='mr-1'><CgMenuBoxed /></i>Hospital Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "doctor" }
												}}
											><i><CgMenuBoxed /></i>Doctor Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "treatment" }
												}}
											><i className='mr-1'><CgMenuBoxed /></i>Treatment Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "add-hospital" }
												}}
											><i className='mr-1'><CgMenuBoxed /></i>Hospital Add Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "package" }
												}}
											><i className='mr-1'><CgMenuBoxed /></i>Package Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "common" }
												}}
											><i className='mr-1'><CgMenuBoxed /></i>Common Enquiries</Link>
											<Link className='enq-titles-list-items'
												to={{
													pathname: "/enquiry",
													state: { item: "contact-us" }
												}}
											><i className='mr-1'><CgMenuBoxed /></i>Contact us Enquiries</Link>

										</div>
									</div>
								}


								{/* <li className={pathname.includes('settings') ? "active" : ""}>
									<Link to="/settings"><i><FeatherIcon icon="sliders" /></i> <span> Settings</span></Link>
								</li> */}
								<li className={pathname.includes('blog') ? "active" : ""}>
									<Link to="/blog"><i><FeatherIcon icon="grid" /></i> <span> Blog</span></Link>
								</li>
								{/* <li className="submenu">
									<Link to="#" className={isSideMenu == "reports" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "reports" ? "" : "reports")}><i><FeatherIcon icon="file-text" /></i><span> Reports</span> <span className="menu-arrow"></span></Link>
									{isSideMenu == "reports" ?
										<ul style={{ display: isSideMenu == "reports" ? "block" : "none" }}>
											<li><Link to="/appointmentrepot" className={pathname.includes('appointmentrepot') ? "active" : ""}>Appointment Report</Link></li>
											<li><Link to="/incomerepot" className={pathname.includes('incomerepot') ? "active" : ""}>Income Report</Link></li>
											<li><Link to="/invoicerepot" className={pathname.includes('invoicerepot') ? "active" : ""}>Invoice Report</Link></li>
											<li><Link to="/userrepots" className={pathname.includes('userrepots') ? "active" : ""}>Users Report</Link></li>
										</ul>
										: ""
									}
								</li> */}
								{/* <li className="menu-title"> 
								<span>Pharmacy</span>
							</li>	 */}
								{/* <li className="submenu ">
								<Link to="#" className= {isSideMenu == "pharmacies" ? "subdrop" : ""} onClick={()=>toggleSidebar(isSideMenu=="pharmacies" ? "" : "pharmacies")}><i><FeatherIcon icon="file-plus" /></i> <span> Pharmacies</span> <span className="menu-arrow"></span></Link>
								<ul style={{ display: isSideMenu== "pharmacies"? "block" : "none" }}>
									<li><Link to="/pharmacy-list" className={pathname.includes('pharmacy-list') ?"active" :""}>All Pharmacies</Link></li>
									<li><Link to="/pharmacy-category" className={pathname.includes('pharmacy-category') ?"active" :""}>Categories</Link></li>
								</ul>
							</li>
							<li className="submenu">
								<Link to="#" className= {isSideMenu == "productlist" ? "subdrop" : ""} onClick={()=>toggleSidebar(isSideMenu=="productlist" ? "" : "productlist")}><i><FeatherIcon icon="shopping-cart" /></i> <span> Product List</span> <span className="menu-arrow"></span></Link>
								<ul style={{ display: isSideMenu== "productlist"? "block" : "none" }}>
									<li><Link to="/product-list" className={pathname.includes('product-list') ?"active" :""}>All Products</Link></li>
									<li><Link to="/product-category" className={pathname.includes('product-category') ?"active" :""}>Categories</Link></li>
								</ul>
						</li>	*/}
								{/* <li className="menu-title">
									<span>Blog</span>
								</li>
								<li className="submenu">
									<Link to="#" className={isSideMenu == "blog" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "blog" ? "" : "blog")}><i><FeatherIcon icon="grid" /></i> <span> Blog</span> <span className="menu-arrow"></span></Link>
									<ul style={{ display: isSideMenu == "blog" ? "block" : "none" }}>
										<li><Link to="/blog" className={pathname.includes('blog') ? "active" : ""}>Blogs</Link></li>
										<li><Link to="/blog-details" className={pathname.includes('blog-details') ? "active" : ""}>Blog Details</Link></li>
										<li><Link to="/add-blog" className={pathname.includes('add-blog') ? "active" : ""}>Add Blog</Link></li>
										<li><Link to="/edit-blog" className={pathname.includes('edit-blog') ? "active" : ""}>Edit Blog</Link></li>
									</ul>
								</li> */}
								{/* <li className="menu-title">
									<span>Pages</span>
								</li> */}


								{/* <li className={pathname.includes('addprofile') ? "active" : ""}>
									<Link to="/addprofile"><i><FeatherIcon icon="user" /></i> <span>Profile</span></Link>
								</li> */}

								<li className={pathname.includes('admin/changepassword') ? "active" : ""}>
									<Link to="/admin/changepassword"><i><BsFillUnlockFill /></i> <span>Change Password</span></Link>
								</li>


								{/* <li className="submenu">
									<Link to="#" className={isSideMenu == "authentication" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "authentication" ? "" : "authentication")}><i><FeatherIcon icon="lock" /></i> <span> Authentication </span> <span className="menu-arrow"></span></Link>
									<ul style={{ display: isSideMenu == "authentication" ? "block" : "none" }}>
										<li><Link to="/login" className={pathname.includes('login') ? "active" : ""} onClick={() => setIsAuth('admin')}> Login </Link></li>
										<li><Link to="/register" className={pathname.includes('register') ? "active" : ""} onClick={() => setIsAuth('admin')}> Register </Link></li>
										<li><Link to="/forgotpassword" className={pathname.includes('forgotpassword') ? "active" : ""} onClick={() => setIsAuth('admin')}> Forgot Password </Link></li>
										<li><Link to="/lockscreen" className={pathname.includes('lockscreen') ? "active" : ""} onClick={() => setIsAuth('admin')}> Lock Screen </Link></li>
									</ul>
								</li>
								<li className="submenu">
									<Link to="#" className={isSideMenu == "errorpages" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "errorpages" ? "" : "errorpages")}><i><FeatherIcon icon="book-open" /></i> <span> Error Pages </span> <span className="menu-arrow"></span></Link>
									<ul style={{ display: isSideMenu == "errorpages" ? "block" : "none" }}>
										<li><Link to="/404" className={pathname.includes('404') ? "active" : ""} onClick={() => setIsAuth('admin')}>404 Error </Link></li>
										<li><Link to="/500" className={pathname.includes('500') ? "active" : ""} onClick={() => setIsAuth('admin')}>500 Error </Link></li>
									</ul>
								</li>
								<li className={pathname.includes('blank-page') ? "active" : ""}>
									<Link to="/blank-page" ><i><FeatherIcon icon="clipboard" /></i> <span>Blank Page</span></Link>
								</li>
								<li className="menu-title">
									<span>UI Interface</span>
								</li>
								<li className={pathname.includes('components') ? "active" : ""}>
									<Link to="/components"><i><FeatherIcon icon="pocket" /></i> <span>Components</span></Link>
								</li>
								<li className="submenu">
									<Link to="#" className={isSideMenu == "forms" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "forms" ? "" : "forms")}><i><FeatherIcon icon="file-minus" /></i> <span> Forms </span> <span className="menu-arrow"></span></Link>
									<ul style={{ display: isSideMenu == "forms" ? "block" : "none" }}>
										<li><Link to="/basic-input" className={pathname.includes('basic-input') ? "active" : ""}>Basic Inputs </Link></li>
										<li><Link to="/form-input-group" className={pathname.includes('form-input-group') ? "active" : ""}>Input Groups </Link></li>
										<li><Link to="/form-horizontal" className={pathname.includes('form-horizontal') ? "active" : ""}>Horizontal Form </Link></li>
										<li><Link to="/form-vertical" className={pathname.includes('form-vertical') ? "active" : ""}> Vertical Form </Link></li>
										<li><Link to="/form-mask" className={pathname.includes('form-mask') ? "active" : ""}>Form Mask </Link></li>
										<li><Link to="/form-validation" className={pathname.includes('form-validation') ? "active" : ""}>Form Validation </Link></li>
									</ul>
								</li>
								<li className="submenu">
									<Link to="#" className={isSideMenu == "tables" ? "subdrop" : ""} onClick={() => toggleSidebar(isSideMenu == "tables" ? "" : "tables")}><i><FeatherIcon icon="align-justify" /></i> <span> Tables </span> <span className="menu-arrow"></span></Link>
									<ul style={{ display: isSideMenu == "tables" ? "block" : "none" }}>
										<li><Link to="/tables-basic" className={pathname.includes('tables-basic') ? "active" : ""}>Basic Tables </Link></li>
										<li><Link to="/data-tables" className={pathname.includes('data-tables') ? "active" : ""}>Data Table </Link></li>
									</ul>
								</li> */}
								{/* <li className="submenu">
									<Link to="#"><i><FeatherIcon icon="life-buoy" /></i> <span>Multi Level</span> <span className="menu-arrow"></span></Link>
									<ul style={{ display: 'none' }}>
										<li className="submenu">
											<Link to="#"> <span>Level 1</span> <span className="menu-arrow"></span></Link>
											<ul style={{ display: 'none' }}>
												<li><Link to="#"><span>Level 2</span></Link></li>
												<li className="submenu">
													<Link to="#"> <span> Level 2</span> <span className="menu-arrow"></span></Link>
													<ul style={{ display: 'none' }}>
														<li><Link to="#">Level 3</Link></li>
														<li><Link to="#">Level 3</Link></li>
													</ul>
												</li>
												<li><Link to="#"> <span>Level 2</span></Link></li>
											</ul>
										</li>
										<li>
											<Link to="#"> <span>Level 1</span></Link>
										</li>
									</ul>
								</li> */}
							</ul>
						</div>
					</div>
				</Scrollbars>
			</div>
			{/* <!-- /Sidebar --> */}
		</>
	);
}


export default withRouter(SidebarNav);
