import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import { avatar01, avatar02, avatar03, avatar05, avatar06, logo, logoSmall, logoNew } from '../imagepath'
import { Appcontext } from '../../../approuter'
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

const Header = (props) => {

  let pathname = props.location.pathname;
  const { isAuth, setIsAuth } = useContext(Appcontext);
  const [dropdown, setdropdown] = useState(false);
  const history = useHistory()


  const handlesidebar = () => {
    document.body.classList.toggle('mini-sidebar');
  }
  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle('slide-nav');
  }


  // if(props.location.pathname.split("/")[1] === 'admin') {
  //   require('../../assets/css/feathericon.min.css')
  //   require('../../assets/js/feather.min.js')
  //   require('../../assets/plugins/fontawesome/css/fontawesome.min.css')
  //   require('../../assets/plugins/fontawesome/css/all.min.css')
  //   require('../../assets/plugins/fontawesome/js/fontawesome.min.js')
  //   require('../../assets/css/font-awesome.min.css')
  //   require('../../assets/css/style.css')
  // }

  const logoutHandler = () => {
    Cookies.remove('adminInfo')
    setIsAuth(false)
    history.push("/login")
    window.location.reload();
    console.log("auth", isAuth)
  }

  console.log("auth..", isAuth)



  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <Link to="/" className="logo">
            <img src={logoNew} alt="Logo" />
          </Link>
          <Link to="/" className="logo logo-small">
            <img
              src={logoSmall}
              alt="Logo"
              width={30}
              height={30}
            />
          </Link>
          <Link to="#" id="toggle_btn" onClick={handlesidebar}>
            <FeatherIcon icon="chevrons-left" />
          </Link>
        </div>
        {/* /Logo */}
        {/* Search */}
        <div className="top-nav-search">
          <div className="main">
            {/* <form className="search" method="post" action="/admin">
              <div className="s-icon">
                <i className="fas fa-search" />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Start typing your Search..."
              />
              <ul className="results">
                <li>
                  <h6>
                    <i className="me-1"> <FeatherIcon icon="calendar" /></i>
                    Appointments
                  </h6>
                  <p>
                    No matched Appointment found.{" "}
                    <Link to="/admin/appointment-list">
                      <span>View all</span>
                    </Link>
                  </p>
                </li>
                <li>
                  <h6>
                    <i className="me-1"> <FeatherIcon icon="calendar" /></i> Specialities
                  </h6>
                  <p>
                    No matched Appointment found.{" "}
                    <Link to="/admin/specialities">
                      <span>View all</span>
                    </Link>
                  </p>
                </li>
                <li>
                  <h6>
                    <i className="me-1"> <FeatherIcon icon="user" /></i> Doctors
                  </h6>
                  <p>
                    No matched Appointment found.{" "}
                    <Link to="/admin/doctor-list">
                      <span>View all</span>
                    </Link>
                  </p>
                </li>
                <li>
                  <h6>
                    <i className="me-1"> <FeatherIcon icon="user" /></i>Patients
                  </h6>
                  <p>
                    No matched Appointment found.{" "}
                    <Link to="/admin/patient-list">
                      <span>View all</span>
                    </Link>
                  </p>
                </li>
              </ul>
            </form> */}
          </div>
        </div>
        {/* /Search */}
        {/* Mobile Menu Toggle */}
        <Link to="#" className="mobile_btn" id="mobile_btn" onClick={() => handlesidebarmobilemenu()}>
          <i className="fas fa-bars" />
        </Link>
        {/* /Mobile Menu Toggle */}
        {/* Header Menu */}
        <ul className="nav nav-tabs user-menu">

          <button className='btn btn-danger btn-sm mt-3'
            onClick={logoutHandler}>LOGOUT</button>

        </ul>
        {/* /Header Menu */}
      </div>
      {/* /Header */}
    </>
  );
}


export default Header;
