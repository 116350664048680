import React from 'react'
import { useState } from 'react'
import SideBarNav from '../sidebar'
import DepartmentTable from './DepartmentTable'
import FormDepartment from './FormDepartment'

function Department() {
    const [mainId, setMainId] = useState("")

    return (
        <>
            <SideBarNav />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="adm-dep-body-div">
                        <DepartmentTable mainId={mainId} setMainId={setMainId} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Department