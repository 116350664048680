import React, { useState } from 'react'
import { useEffect } from 'react'
import useAsync from '../../../hooks/useAsync'
import GalleryService from '../../../services/GalleryService'
import AddImgModal from './AddImgModal'
import AddUrl from './AddUrl'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import DeleteModal from '../Modal/DeleteModal'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'

const GalleryTable = () => {
    const urls = useAsync(GalleryService.allGallery)
    const { setIsUpdate } = useContext(UserContext)
    const [urlModal, setUrlModal] = useState(false)
    const [imgModal, setImgModal] = useState(false)
    const [urlData, setUrlData] = useState([])
    const [imgData, setImgData] = useState([])
    const [urlHover, setUrlHover] = useState(false)
    const [imgHover, setImgHover] = useState(false)
    const [imgId, setImgId] = useState('')
    const [urlId, setUrlId] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        if (urls?.data) {
            setUrlData(urls.data?.filter((current) => current?.url != null))
        }
        if (urls?.data) {
            setImgData(urls?.data.filter((current) => current?.images != null))
        }
    }, [urls?.data])

    const hoverOnImg = (id) => {
        setImgId(id)
        setImgHover(true)
    }

    const hoverOnUrl = (id) => {
        setUrlId(id)
        setUrlHover(true)
    }

    const leaveImg = () => {
        setImgId('')
        setImgHover(false)
    }

    const leaveUrl = () => {
        setUrlId('')
        setUrlHover(false)
    }

    const deleteModalHandler = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }

    const deleteHandler = () => {
        GalleryService.deleteGallery(deleteId).then((res) => {
            console.log(res)
            alert('Deleted Successfully')
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    return (
        <>
            <div className='gallery-add-btn-div'>
                <button className='btn btn-primary btn-sm' onClick={() => setUrlModal(true)}>Add URL</button>
                <button className='btn btn-success btn-sm' onClick={() => setImgModal(true)}>Add Image</button>
            </div>
            <div className="gallery-container">
                <div className="gallery-main-div">
                    <h5>Youtube URLs</h5>
                    <div className="gallery-in-div">
                        <div className="gallery-card">
                            {
                                urlData?.map((item) => {
                                    return (
                                        <div className="gallery-img-div" key={item.id}
                                            onMouseEnter={() => hoverOnUrl(item?.id)}
                                            onMouseLeave={leaveUrl}>

                                            <iframe
                                                width="200"
                                                height="100"
                                                src={item.url}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                            {
                                                urlHover && urlId == item?.id ?
                                                    <div className="inside-img-div">
                                                        {/* <button className='btn btn-success btn-sm'><AiOutlineEdit /></button> */}
                                                        <button className='btn btn-danger btn-sm ml-4'
                                                            onClick={() => deleteModalHandler(item?.id)}><AiOutlineDelete /> Delete</button>
                                                    </div>
                                                    : ""

                                            }
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="gallery-main-div">
                    <h5>Gallery</h5>
                    <div className="gallery-in-div">
                        <div className="gallery-card">
                            {
                                imgData?.map((item) => {
                                    return (
                                        <div className="gallery-img-div" key={item.id}
                                            onMouseEnter={() => hoverOnImg(item?.id)}
                                            onMouseLeave={leaveImg}>
                                            <img src={item?.images} alt="" />
                                            {
                                                imgHover && imgId == item?.id ?

                                                    <div className="inside-img-div">
                                                        {/* <button className='btn btn-success btn-sm'><AiOutlineEdit /></button> */}
                                                        <button className='btn btn-danger btn-sm ml-4'
                                                            onClick={() => deleteModalHandler(item?.id)}><AiOutlineDelete /> Delete</button>
                                                    </div>
                                                    : ""
                                            }
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <AddUrl modal={urlModal} setModal={setUrlModal} />
            <AddImgModal imgModal={imgModal} setImgModal={setImgModal} />

            <DeleteModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
            />
        </>
    )
}

export default GalleryTable