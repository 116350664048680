import React from "react";
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from "../../../hooks/useAsync";
import TreatmentService from "../../../services/TreatmentService";
import DeleteModal from "../Modal/DeleteModal";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../../Context/AllContext";
import TreatmentModal from "./TreatmentModal";

const TreatmentTable = () => {
    const allTreatments = useAsync(TreatmentService.allTreatments)
    const { setIsUpdate } = useContext(UserContext)
    const { data } = useAsync(TreatmentService.allTreatments)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()
    const [formModal, setFormModal] = useState(false)
    const [mainId, setMainId] = useState()

    const deleteModalShow = (id, name) => {
        setDeleteModal(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const deleteHandler = () => {
        TreatmentService.deleteTreatment(deleteId).then((res) => {
            console.log(res)
            setDeleteModal(false)
            setIsUpdate(true)
        })
    }

    const updateHandler = (id) => {
        setMainId(id)
        setFormModal(true)
    }

    const modalHandler = () => {
        setMainId("")
        setFormModal(true)
    }

    const columns = [
        {
            name: 'SId',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Product',
            sortable: true,
            cell: row =>
                <Media >
                    <Media.Body className="d-flex"><Link to="#" className="spl-img" >
                        <img src={row.image} className="img-fluid" />
                    </Link><span>{row.title}</span>
                    </Media.Body></Media>,
            width: "300px",

        },
        {
            name: 'Sub Title',
            selector: row => row.subtitle,
            sortable: true,
            width: "300px",
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateHandler(row)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row.id, row.title)}
                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "250px",

        },
    ];

    const tableData = {
        columns,
        data,
    };
    return (
        <div className="card-body p-0">

            <div className="page-header">
                <div className="row align-items-center">
                    <div className="col-md-12 d-flex justify-content-end">
                        <div className="doc-badge me-3">
                            Treatments <span className="ms-1">{allTreatments.data.length}</span>
                        </div>
                        <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#addModal"
                            className="btn btn-primary btn-add"
                            onClick={modalHandler}
                        >
                            <i className='me-1'><FeatherIcon icon="plus-square" /></i>
                            Add New
                        </Link>
                    </div>
                </div>
            </div>

            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* Form modal */}
            <TreatmentModal
                formModal={formModal}
                setFormModal={setFormModal}
                mainId={mainId}
            />

            {/* Delete Modal */}
            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName}
            />
        </div>
    )
}

export default TreatmentTable