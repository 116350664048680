import React from "react";
import { Modal } from "react-bootstrap";
import AddPackageForm from "./AddPackageForm";

const PackageModal = ({ packageModal, setPackageModal, editId, setEditId, assignPackageHandler }) => {
    return (
        <Modal
            show={packageModal} onHide={() =>{
                setPackageModal(false)
                setEditId(null)
            }
            }
        >
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>{editId ? "Edit" : "Add"} Package </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <AddPackageForm setPackageModal={setPackageModal} editId={editId} setEditId={setEditId} assignPackageHandler={assignPackageHandler} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PackageModal