import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { Media } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13 } from '../imagepath';
import useAsync from '../../../hooks/useAsync'
import AdminServices from '../../../services/AdminServices'
import DeleteModal from '../Modal/DeleteModal'

import { UserContext } from '../../../Context/AllContext'


const AdminsTable = (props) => {
    const { data } = useAsync(AdminServices.getRegister)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()
    const { setIsUpdate } = useContext(UserContext)
    // console.log('Data',data)


    const deleteModalShow = (id, name) => {
        setDeleteModal(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const deleteHandler = () => {
        AdminServices.deleteAdmin(deleteId)
            .then((res) => {
                console.log('delete', res)
                setDeleteModal(false)
                setIsUpdate(true)
            })
            .catch((err) => console.log('deleteErr', err))
    }
    const columns = [
        {
            name: 'User Name',
            sortable: true,
            selector: row => row.username,
            width: "250px",
        },

        {
            name: 'Name',
            selector: row => row.vchr_name,
            sortable: true,
            width: "250px",
        },
        {
            name: 'Phone',
            selector: row => row.int_mobile,
            sortable: true,
            width: "150px",
        },

        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: "250px",
        },

        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">
                {/* <Link
                    // className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    // onClick={() => ListModalShow(row.id, row.name)}
                    style={{ color: '#219ebc' }}

                >
                    <i className='me-1'><FeatherIcon icon="eye" /></i> View
                </Link> */}
                <button
                    // to="#"
                    // data-bs-toggle="modal"
                    // data-bs-target="#editModal"
                    className='btn'
                    style={{ color: 'green' }}
                    onClick={() => {
                        props.setId(row.id)
                        props.setRegisterModal(true)
                    }}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </button>
                <button
                    // to="#"
                    // data-bs-toggle="modal"
                    // data-bs-target="#deleteModal"
                    className="btn"
                    onClick={() => deleteModalShow(row.id, row.username)}

                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </button>
            </div>,
            width: "250px",

        },

    ];

    const tableData = {
        columns,
        data,

    };
    return (
        <div className="card-body p-0">
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable

                        noHeader

                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* Delete modal */}

            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName} />

            {/* view modal */}
            {/* <Modal show={docModalShow} onHide={() =>
                setDocModalShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>{docName} </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <ViewDoctorModal docId={docId} />
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default AdminsTable