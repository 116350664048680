import requests from "./httpServices";

const DepartmentService = {
    addDepartment(body) {
        return requests.filePost('/user_login/department/', body)
    },
    allDepartment() {
        return requests.get('/user_login/department/')
    },
    updateDepartment(id, body) {
        return requests.filePut(`/user_login/department/${id}/`, body)
    },
    deleteDepartment(id) {
        return requests.delete(`/user_login/department/${id}/`)
    },
    getDepartmentById(id) {
        return requests.get(`/user_login/department/${id}/`)
    },
    getDepartmentByPage(page) {
        return requests.get(`/user_login/department/?page=${page}`)
    }
}

export default DepartmentService