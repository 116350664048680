import React from 'react'
import FeatherIcon from 'feather-icons-react';
import { useState, useContext } from 'react';
import FacilityService from '../../../services/FacilityServices';
import { UserContext } from '../../../Context/AllContext';

function SubFacilityModal({ setShow1, id, editName }) {
    const [facilityValues, setFacilityValues] = useState({
        facility_name: '',
    })
    const { isUpdate, setIsUpdate } = useContext(UserContext)

    const facilityChange = (e) => {
        setFacilityValues({ ...facilityValues, [e.target.name]: e.target.value })
    }

    const facilitySubmit = (e) => {
        e.preventDefault()
        {
            id ?
                FacilityService.updateFacility(id, facilityValues).then((res) => {
                    alert(`${res.facility_name} Updated successfully`)
                    setIsUpdate(true)
                    setShow1(false)
                }).catch((err) => console.log('err', err))
                :
                FacilityService.addFacility(facilityValues)
                    .then((res) => {
                        alert(`${res.facility_name} Added successfully`)
                        setIsUpdate(true)
                        setShow1(false)
                    }).catch((err) => console.log('err', err))
        }

    }

    return (
        <div className="modal-content doctor-profile">
            <div className="modal-body">
                <form action=""
                    onSubmit={facilitySubmit}
                >
                    <div className="add-wrap">
                        <div className="form-group form-focus">
                            <input type="text"
                                name='facility_name'
                                required
                                className="form-control"
                                defaultValue={id ? editName : facilityValues.facility_name}
                                onChange={facilityChange}
                            />
                            <label className="focus-label">Facility Name<span className="text-danger">*</span></label>
                        </div>

                        <div className="submit-section">
                            <button type="submit" className="btn btn-primary btn-save">
                                {id ? "Edit" : "Add"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SubFacilityModal