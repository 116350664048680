import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { forgotPassword, alshifaLogoNew } from '../imagepath'

const ForgotPassword = () => {
  const history = useHistory()
  return (
    <div className="forgot-pass-main-wrapper">
      <div className="forgot-pass-container">
        <div className="forgot-pass-main-div">

          <div className="forgot-pass-left-div">
            <div className="forgot-pass-left-img-div">
              <img src={forgotPassword} alt="" />
            </div>
          </div>

          <div className="forgot-pass-right-div">
            <div className="forgot-pass-right-logo-div">
              <img src={alshifaLogoNew} alt="" />
            </div>
            <h3>Reset Password</h3>
            <p className="account-subtitle">
              Enter your email to get a password reset link
            </p>
            <div className="forgot-pass-right-input-div">
              <form action="/admin/conform-email">
                <div className="form-group form-focus">
                  <input type="email" className="form-control floating" />
                  <label className="focus-label">Enter Email</label>
                </div>
              </form>
            </div>
            <div className="fogot-pass-submit-btn-div">
              <Link className='login-submit-btn forgot-pass-submit-btn' to="/admin/conform-email">
                Reset Password
              </Link>
            </div>
            <div className="forgot-pass-last-div">
              <p>Remember your password? <Link className='forgot-pass-login-link' to="/admin/login">Login</Link></p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ForgotPassword