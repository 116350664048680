import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { loginBanner, logoNew, alshifaLogoNew } from '../imagepath'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { alphaNumericPattern, emailrgx } from '../../assets/constant'
import { Appcontext } from '../../../approuter'
import AdminServices from '../../../services/AdminServices'
import Cookies from 'js-cookie'


const schema = yup
  .object({

    username: yup
      .string().required('Username is required'),
    password: yup.string().min(6)
      .required('Password is required')
      .trim(),
  })

const Login = (props) => {

  const { isAuth, setIsAuth } = useContext(Appcontext);
  const [eye, seteye] = useState(true);
  const [emailerror, setEmailError] = useState("");
  const [nameerror, setNameError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [formgroup, setFormGroup] = useState("");
  const [inputValues, setInputValues] = useState({
    email: "admin@dreamguys.co.in",
    password: "123456",
  });
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  // useEffect(() => {
  //   // console.log('useffect')
  //   let admin = Cookies.get('adminInfo')
  //   if (admin == 'true') {
  //     props.history.push('/')
  //     console.log('ADMIN', admin)
  //   }
  //   // setAuth('true')
  // }, [])

  const onSubmit = async (data) => {
    console.log("data", data)
    await AdminServices.adminLogin(data)
      .then((res) => {
        console.log('Res', res)
        if (res.is_admin) {
          console.log('ifres', res)
          Cookies.set('adminInfo', JSON.stringify(res))
          clearErrors('password')
          setIsAuth(true)
          props.history.push('/')
          window.location.reload();
        }else{
          console.log('Err invalid admin')
        }
      })
      .catch((err) => console.log('err', err))

    // if (data.password != "123456") {
    //   setError('password', {
    //     message: 'password is mismatch',
    //   })
    // } else {
    //   clearErrors('password')
    //   setIsAuth(true)
    //   props.history.push('/')
    // }
  }
  const onEyeClick = () => {
    seteye(!eye)
  }
  return (
    <div className="admin-log-main-wrapper">

      <div className="adm-log-main-div">
        <div className="adm-log-section">
          <div className="adm-log-left-div">
            <div className="adm-log-img-div">
              <img src={loginBanner} alt="" />
            </div>
          </div>
          <div className="adm-log-right-div">
            <div className="adm-log-right-section">
              <div className="adm-log-logo-div">
                <img src={alshifaLogoNew} alt="" />
              </div>
              <h3>Al Shifa Admin Login</h3>
              <div className="adm-log-right-input-div">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group form-focus">

                    <Controller
                      name="username"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input className={`form-control floating input-admin-style  ${errors?.username ? "error-input" : ""}`} type="text" value={value} onChange={onChange} autoComplete="false" />

                      )}
                      defaultValue="admin"
                    />

                    <small>{errors?.username?.message}</small>
                  </div>
                  <div className="form-group form-focus">

                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="pass-group">
                          <input type={eye ? "password" : "text"} className={`form-control floating input-admin-style ${errors?.password ? "error-input" : ""}`} value={value} onChange={onChange} autoComplete="false" />
                          <span onClick={onEyeClick} className={`fa toggle-password" ${eye ? "fa-eye-slash" : "fa-eye"}`} />
                        </div>
                      )}
                      defaultValue="123456"
                    />

                    <small>{errors?.password?.message}</small>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-6">
                        {/* <label className="custom_check mr-2 mb-0 d-inline-flex">
                          Remember me
                          <input type="checkbox" name="radio" />
                          <span className="checkmark" />
                        </label> */}
                      </div>
                      {/* <div className="col-6 text-end">
                        <Link className="forgot-link" to="/admin/forgotpassword">
                          Forgot Password ?
                        </Link>
                      </div> */}
                    </div>
                  </div>
                  <div className="d-grid">
                    {/* <Link to="/" className="theme-btn" type="submit">
                      Login
                    </Link> */}
                    {/* <Link to="/"> */}
                    <button className="
                theme-btn" type="submit">
                      Login
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
              {/* <div className="adm-log-center-div">
                <div className="adm-log-center-left-div">
               
                </div>
                <Link to="/admin/changepassword" className="adm-log-center-right-div">
                  <h6>Change Password?</h6>
                </Link>
              </div> */}
              {/* <div className="adm-log-btn-div">
                <Link to="/" className="login-submit-btn" type="submit">
                  Login
                </Link>
              </div> */}
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Login