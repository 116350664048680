import React from 'react'
import { useContext } from 'react'
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { UserContext } from '../../../../../Context/AllContext'



const AwardListModal = ({ awardArray, setAwrdArray }) => {
    const { setIsUpdate } = useContext(UserContext)

    const deleteHandler = (name) => {
        let del = awardArray.splice(name, 1)
        setAwrdArray(awardArray)
        setIsUpdate(true)
    }

    return (
        <div className="edu-list-modal-div">
            {
                awardArray?.map((item, index) => {
                    return (
                        <div className="modal-list-card-div" key={index}>
                            <div className="modal-list-card-header">
                                {item?.award_title && <h5>{`${index + 1}`}. {item.award_title}</h5>}

                            </div>
                            <div className="edu-modal-details-div">
                                {item?.award_detail && <h6>{item.award_detail}</h6>}
                                {item?.year && <h6 style={{ color: 'darkred' }}>{item.year}</h6>}
                            </div>
                            <div className="modal-icon-header-div">
                                {/* <i style={{ color: 'green' }}><AiOutlineEdit /></i> */}
                                <i onClick={() => deleteHandler(index)} style={{ color: 'red' }}><AiFillDelete /></i>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AwardListModal