import React from 'react'
import { Modal } from 'react-bootstrap'

function DeleteModal({ deleteModal, setDeleteModal, deleteHandler, deleteName }) {


    return (
        <Modal show={deleteModal} onHide={() =>
            setDeleteModal(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>Confirm Delete </h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                    <div className='onfirm-delete-main-div'>
                        <p>Do you want to delete <span style={{ color: '#98181e', fontWeight: 'bold' }}>{deleteName}</span> ? </p>
                        <div className="confirm-delete-btn-div">
                            <button
                                type="button" className="btn btn-danger"
                                onClick={deleteHandler}>Delete</button>
                            <button
                                type="button" className="btn btn-secondary ml-3"
                                onClick={() => setDeleteModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteModal