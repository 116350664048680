import requests from "./httpServices";

const BannerService = {

    addBanner(body) {
        return requests.filePost('/banner/banner/', body)
    },
    getBanner() {
        return requests.get('/banner/banner/')
    },
    updateBanner(id, body) {
        return requests.filePut(`/banner/banner/${id}/`, body)
    },
    deleteBanner(id,key) {
        return requests.delete(`/banner/banner/${id}/?key=${key}`)
    },
    getHomeSliders() {
        return requests.get('/banner/homepage/')
    },
    addHomeSliders(body) {
        return requests.filePost('/banner/homepage/', body)
    },
    updateHomeSlider(id, body) {
        return requests.filePut(`/banner/homepage/${id}/`, body)
    },
    deleteHomeSlider(id) {
        return requests.delete(`/banner/homepage/${id}/`)
    },
    getHomeSliderById(id) {
        return requests.filePut(`/banner/homepage/${id}/`)
    }
}

export default BannerService