import requests from "./httpServices";

const EnquiryService = {
    allEnquiries() {
        return requests.get('/enquiry/enquiry/')
    },
    getEnquiryById(id) {
        return requests.get(`/enquiry/enquiry/${id}/`)
    },
    deleteEnquiry(id) {
        return requests.delete(`/enquiry/enquiry/${id}/`)
    }
}

export default EnquiryService