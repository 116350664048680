import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, Media } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { avatar02, avatar04, avatar05, avatar06, avatar07, avatar10, avatar11, avatar12, avatar13 } from '../imagepath';
import AdminServices from '../../../services/AdminServices';
import useAsync from '../../../hooks/useAsync';
import DeleteModal from '../Modal/DeleteModal';
import { useContext } from 'react';
import { UserContext } from '../../../Context/AllContext';
import Modal from 'react-bootstrap';
import PatientViewModal from './PatientViewModal';

const PatientsTable = () => {
	const { data } = useAsync(AdminServices.getAllUserDetails)
	const [deleteId, setDeleteId] = useState('')
	const [deleteModal, setDeleteModal] = useState(false)
	const { setIsUpdate } = useContext(UserContext)
	const [viewModal, setViewModal] = useState(false)
	const [viewId, setViewId] = useState('')

	const deleteModalShow = (id) => {
		setDeleteId(id)
		setDeleteModal(true)
	}

	const deleteHandler = () => {
		AdminServices.deleteUserDetail(deleteId).then((res) => {
			alert('Deleted Successfully')
			setIsUpdate(true)
			setDeleteModal(false)
		})
	}

	const ViewModalShow = (id) => {
		setViewId(id)
		setViewModal(true)
	}

	const columns = [
		{
			name: 'No.',
			cell: (row, index) => index + 1,
			sortable: true,
			width: "100px",
		},

		{
			name: 'Patient',
			sortable: true,
			cell: row => <Media ><Media.Body className="d-flex">
				{/* <h2 className="table-avatar">
				<Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#patientlist" data-bs-toggle="modal">
				<img className="avatar avatar-img" src={row.patient_image} alt="User Image" />
				</Link></h2> */}
				<Link to="#"
					data-bs-target="#Patientlist"
					data-bs-toggle="modal"
				><span className="user-name">{row.username}</span><br /><span className="d-block text-muted">{row.age}</span>
				</Link></Media.Body></Media>,
			width: "300px",

		},

		{
			name: 'Email',
			selector: row => row.vchr_name,
			sortable: true,
			width: "300px",
		},


		// {
		// 	name: 'TotalIncome',
		// 	selector: row => row.TotalIncome,
		// 	sortable: true,
		// 	width: "300px",
		// },
		// {
		// 	name: 'Account status',
		// 	selector: row => row.status,
		// 	sortable: true,
		// 	cell: row => <label className="toggle-switch" >
		// 		<input
		// 			type="checkbox"
		// 			className="toggle-switch-input"
		// 			//   id={row.status}
		// 			defaultChecked=""
		// 		/>
		// 		<span className="toggle-switch-label">
		// 			<span className="toggle-switch-indicator" />
		// 		</span>
		// 	</label>,

		// 	width: "300px",
		// },
		{
			name: 'Account status',
			selector: row => row.status,
			sortable: true,
			cell: row => <div className="actions">
				<Link
					// className="text-danger"
					to={{
						pathname: "/profile-user",
						state: { item: row?.id }
					}}
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					// onClick={() => ViewModalShow(row?.id)}
					style={{ color: '#219ebc' }}

				>
					<i className='me-1'><FeatherIcon icon="eye" /></i> View
				</Link>
				{/* <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    // onClick={() => updateHandler(row.id, row.name)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link> */}
				<Link
					className="text-danger"
					to="#"
					data-bs-toggle="modal"
					data-bs-target="#deleteModal"
					onClick={() => deleteModalShow(row.id)}
				>
					<i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
				</Link>
			</div>,
			width: "300px",
		},
	];

	const tableData = {
		columns,
		data,

	};
	return (
		<div className="card-body p-0">
			<div className="table-responsive">
				<DataTableExtensions
					{...tableData}
				>
					<DataTable className="datatable table table-borderless hover-table"
						id="data-table"

						noHeader

						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
					/>
				</DataTableExtensions>
			</div>

			{/* delete modal */}
			<DeleteModal deleteModal={deleteModal}
				setDeleteModal={setDeleteModal}
				deleteHandler={deleteHandler}
			/>

			{/* View modal */}

			<PatientViewModal viewModal={viewModal} setViewModal={setViewModal} patientId={viewId} />


		</div>
	)
}

export default PatientsTable;