import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';

const Notify = ({ err=false, message, show, setShow }) => {
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000);
    }, [])

    return (
        <>
            <Modal show={show} onHide={() =>
                setShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4 style={{color:err?'#dc3545':'#198754'}}>{err?'Error':'Success'} </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <div className='onfirm-delete-main-div'>
                            <p> {message} </p>
                            {/* <p>{err}</p> */}
                            <div className="confirm-delete-btn-div">
                                {/* <button
                                type="button" className="btn btn-danger"
                                onClick={deleteHandler}>Delete</button> */}
                                <button
                                    type="button" className="btn btn-secondary ml-3"
                                    onClick={() => setShow(false)}>Ok</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Notify