import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import { Media } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import useAsync from '../../../hooks/useAsync'
import FaqService from '../../../services/FaqService'
import { useContext } from 'react'
import { UserContext } from '../../../Context/AllContext'
import DeleteModal from '../Modal/DeleteModal'
import { useEffect } from 'react'

function HospitalFaq({ setEditId, setFormPage }) {
    const allHospitalFaq = useAsync(FaqService.allHospitalFaq)
    const { setIsUpdate } = useContext(UserContext)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [deleteName, setDeleteName] = useState()
    const [data, setData] = useState([])

    useEffect(() => {
        if (allHospitalFaq?.data) {
            setData(allHospitalFaq?.data?.filter((current) => current != null))
        }
    }, [allHospitalFaq?.data])


    const deleteModalShow = (id, name) => {
        setDeleteModal(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const deleteHandler = () => {
        FaqService.deleteHospitalFaq(deleteId).then((res) => {
            console.log(res)
            alert("Deleted Successfully")
            setIsUpdate(true)
            setDeleteModal(false)
        })
    }

    const updateModal = (id) => {
        setEditId(id)
        setFormPage("addHospFaq")
    }

    const columns = [
        {
            name: 'Id',
            // selector: row => row.id,
            cell: (row, index) => index + 1,
            sortable: true,
            width: "100px",
        },

        {
            name: 'User Name',
            sortable: true,
            cell: row => <Media ><Media.Body className="d-flex"><h2 className="table-avatar">
                <Link className="avatar avatar-sm me-2 user-dt" to="#" data-bs-target="#doctorlist" data-bs-toggle="modal">
                    {/* <img className="avatar avatar-img" src={row.user_image} alt="" /> */}
                </Link></h2>
                <NavLink to="#"
                    data-bs-target="#doctorlist"
                    data-bs-toggle="modal"
                >
                </NavLink><span className="user-name">{row?.faq_qst}</span></Media.Body></Media>,
            width: "450px",

        },


        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <div className="actions">

                <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#editModal"
                    style={{ color: 'green' }}
                    onClick={() => updateModal(row?.id)}
                >
                    <i className='me-1' style={{ color: 'green' }}><FeatherIcon icon="edit-3" /></i> Edit
                </Link>
                <Link
                    className="text-danger"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    onClick={() => deleteModalShow(row?.id, row.faq_qst)}

                >
                    <i className='me-1'><FeatherIcon icon="trash-2" /></i> Delete
                </Link>
            </div>,
            width: "250px",

        },

    ];

    const tableData = {
        columns,
        data,

    };

    return (
        <div className="card-body p-0" style={{ width: '100%' }}>
            <div className="table-responsive">
                <DataTableExtensions
                    {...tableData}
                >
                    <DataTable

                        noHeader

                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                    />
                </DataTableExtensions>
            </div>

            {/* Delete modal */}

            <DeleteModal deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteHandler={deleteHandler}
                deleteName={deleteName} />

            {/* view modal */}
            {/* <Modal show={docModalShow} onHide={() =>
                setDocModalShow(false)}>
                <Modal.Header closeButton >
                    <Modal.Title className='profile-settings-modal-header'>
                        <h4>{docName} </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-image-main-cont">
                        <ViewDoctorModal docId={docId} />
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default HospitalFaq