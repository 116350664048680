import React from 'react'
import { Modal } from 'react-bootstrap'
import AddSpecialityModal from '../specialities/AddSpecialityModal'

const MainModal = ({ show1, setShow1, title, id, children}) => {

    // console.log("main modal id", id)
    return (
        <Modal show={show1} onHide={() =>
            setShow1(false)}>
            <Modal.Header closeButton >
                <Modal.Title className='profile-settings-modal-header'>
                    <h4>{id ? 'Edit' : 'Add'} {title}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-image-main-cont">
                   {children}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MainModal