import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { alphaNumericPattern, emailrgx } from '../../assets/constant'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { facebook, google, logo, twitter, phone } from '../imagepath';
import AdminServices from "../../../services/AdminServices";
import { UserContext } from "../../../Context/AllContext";


const schema = yup
    .object({
        name: yup.string().required('Name is required'),
        phone: yup.string().required('Phone is required'),
        email: yup
            .string()
            .matches(emailrgx, 'Email is required')
            .required('Email is required')
            .trim()

        // confirmPassword: yup.string().min(6).max(6).required('ConfirmPassword is required').trim(),
    })
    .required()
const addOnSchema = yup
    .object({
        username: yup.string().required('UserName is required'),
        password: yup.string().min(6)
            .required('Password is required')
            .trim(),

        // confirmPassword: yup.string().min(6).max(6).required('ConfirmPassword is required').trim(),
    })
    .required()

const RegisterModal = ({ id, setRegisterModal }) => {
    const [eye, seteye] = useState(true);
    const [eye2, setEye2] = useState(true)
    const [emailerror, setEmailError] = useState("");
    const { isUpdate, setIsUpdate } = useContext(UserContext)

    const [passworderror, setPasswordError] = useState("");
    const [formgroup, setFormGroup] = useState("");
    const [inputValues, setInputValues] = useState({
        email: "admin@alshifa.co.in",
        password: "123456",
    });

    console.log("user id", id)


    // console.log('prop',props)
    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(id ? schema : schema.concat(addOnSchema)),
    })

    const onSubmit = (data) => {
        console.log("data", data)

        const value = {
            vchr_name: data.name,
            email: data.email,
            int_mobile: data.phone,
        }
        if (!id) {
            value.username = data.username,
                value.password = data.password
        }
        if (id) {
            AdminServices.updateAdmin(id, value)
                .then((res) => {
                    console.log('sign', res)
                    setIsUpdate(true)
                    setRegisterModal(false)
                })
                .catch((err) => console.log('err', err))
                .catch((err) => console.log('err', err))
                .catch((err) => console.log('err', err))
        } else {
            AdminServices.addAdmin(value)
                .then((res) => {
                    console.log('sign', res)
                    setIsUpdate(true)
                    setRegisterModal(false)
                })
                .catch((err) => console.log('err', err))
        }
        // if (data.password != "123456") {
        //     setError('password', {
        //         message: 'password is mismatch',
        //     })
        // } else {
        //     clearErrors('password')
        //     props.history.push('/admin/login')
        // }
    }
    const onEyeClick = () => {
        seteye(!eye)
    }

    const onEyeClick2 = () => {
        setEye2(!eye2)
    }
    return (
        <div className="register-form-div">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group form-focus">
                    <label className="focus-label">Admin Name</label>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <input className={`form-control floating  ${errors?.name ? "error-input" : ""}`} type="text" value={value} onChange={onChange} autoComplete="false" />

                        )}
                    // defaultValue="Admin name"
                    />

                    <small>{errors?.name?.message}</small>
                </div>
                <div className="form-group form-focus">
                    <label className="focus-label">Email</label>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <input className={`form-control floating  ${errors?.email ? "error-input" : ""}`} type="text" value={value} onChange={onChange} autoComplete="false" />

                        )}
                    // defaultValue="admin@alshifa.co.in"
                    />

                    <small>{errors?.email?.message}</small>
                </div>
                <div className="form-group form-focus">
                    <label className="focus-label">Phone Number</label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <input className={`form-control floating  ${errors?.phone ? "error-input" : ""}`} type="text" value={value} onChange={onChange} autoComplete="false" />

                        )}
                    // defaultValue="Phone Number"
                    />

                    <small>{errors?.phone?.message}</small>
                </div>
                {!id && <>
                    <div className="form-group form-focus">
                        <label className="focus-label">User Name</label>
                        <Controller
                            name="username"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <input className={`form-control floating  ${errors?.username ? "error-input" : ""}`} type="text" value={value} onChange={onChange} autoComplete="false" />

                            )}
                        // defaultValue="User Name"
                        />

                        <small>{errors?.username?.message}</small>
                    </div>
                    <div className="form-group form-focus">
                        <label className="focus-label">Choose a password</label>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <div className="pass-group">
                                    <input type={eye ? "password" : "text"} className={`form-control floating  ${errors?.password ? "error-input" : ""}`} value={value} onChange={onChange} autoComplete="false" />
                                    <span onClick={onEyeClick} className={`fa toggle-password" ${eye ? "fa-eye-slash" : "fa-eye"}`} />
                                </div>
                            )}
                        // defaultValue="123456"
                        />

                        <small>{errors?.password?.message}</small>
                    </div>
                </>}
                {/* <div className="form-group form-focus">
                    <label className="focus-label">Confirm Password</label>
                    <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <div className="pass-group">
                                <input className={`form-control floating  ${errors?.confirmPassword ? "error-input" : ""}`} type={eye2 ? "password" : "text"} value={value} onChange={onChange} autoComplete="false" />
                                <span onClick={onEyeClick2} className={`fa toggle-password" ${eye ? "fa-eye-slash" : "fa-eye"}`} />
                            </div>
                        )}
                        defaultValue=""
                    />

                    <small>{errors?.confirmPassword?.message}</small>
                </div> */}
                {/* <div className="form-group">
                    <div className="row">
                        <div className="col-12">
                            <label className="custom_check mr-2 mb-0">
                                <span><input type="checkbox" /></span>
                                {" "}
                                I agree to the{" "}
                                <Link to="#" className="theme-text">
                                    terms of service
                                </Link>{" "}
                                and{" "}
                                <Link to="#" className="theme-text">
                                    privacy policy
                                </Link>
                                <input type="checkbox" name="radio" />
                                <span className="checkmark" />
                            </label>
                        </div>
                    </div>
                </div> */}
                <div className="d-grid">
                    <button className="theme-btn" type="submit">
                        Register
                    </button>
                </div>
                {/* <div className="dont-have mt-3">
                    Already have an account? <Link to="/admin/login">Login</Link>
                </div>
                <div className="login-or">
                    <span className="or-line" />
                    <p className="span-or">or login with </p>
                </div>
                // Social Login
                <div className="social-login">
                    <Link to="#">
                        <img
                            src={phone}
                            className="img-fluid phone-png-img"
                            alt="Logo"
                        />
                    </Link>
                    <Link to="#">
                        <img
                            src={facebook}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </Link>
                    <Link to="#">
                        <img
                            src={google}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </Link>
                </div> */}
                {/* /Social Login */}
            </form>
        </div>
    )
}

export default RegisterModal